import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useParams, useLocation, useHistory } from "react-router-dom";

const userObject = {
  avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
  email: "george.bluth@reqres.in",
  first_name: "George",
  id: 1,
  last_name: "Bluth",
};

export default function FormEditGuides(props) {
  const location = useLocation();
  const { id } = useParams();
  const styles = useStyles();
  const [userData] = useState(
    location?.state?.data ? location.state.data : userObject
  );
  const [referencesData] = useState(
    userData?.referencesData ? userData.referencesData : []
  );
  const fullName = userData.fullName ? userData.fullName : "--";

  let history = useHistory();
  useEffect(() => {
    console.log(id);
    console.log(userData);
  }, []);

  const handleChange = (e, value) => {
    props.selectedLevel(e.target.value);

    //   console.log(e.target.value)
    //call parent function
  };

  const guideInfoBox = (
    <Grid item container direction="row" justify="center" spacing={1}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        container
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Paper className={styles.dropdownContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              LEVEL
            </Typography>
            <FormControl variant="filled" className={styles.formControl}>
              <InputLabel htmlFor="filled-age-native-simple">Level</InputLabel>
              <Select
                native
                value={0}
                onChange={handleChange}
                inputProps={{
                  name: "age",
                  id: "filled-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={styles.bioContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              BIO
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              placerat orci nulla pellentesque dignissim enim. Ac turpis egestas
              sed tempus urna et. Egestas purus viverra accumsan in nisl nisi
              scelerisque. Nunc sed blandit libero volutpat sed cras ornare. Est
              placerat in egestas erat. Diam quam nulla porttitor massa id
              neque. Tellus cras adipiscing enim eu turpis egestas. Potenti
              nullam ac tortor vitae purus faucibus ornare suspendisse. Et netus
              et malesuada fames ac turpis egestas sed tempus. Augue interdum
              velit euismod in pellentesque massa placerat. Dictum at tempor
              commodo ullamcorper a lacus. Lacus luctus accumsan tortor posuere
              ac ut consequat semper viverra. Feugiat nibh sed pulvinar proin.
              Nisl pretium fusce id velit ut tortor pretium viverra suspendisse.
              Adipiscing at in tellus integer feugiat scelerisque varius morbi
              enim. Id diam maecenas ultricies mi eget mauris pharetra.
            </Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={styles.additionalInfoContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              ADDITIONAL INFORMATION
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              placerat orci nulla pellentesque dignissim enim. Ac turpis egestas
              sed tempus urna et. Egestas purus viverra accumsan in nisl nisi
              scelerisque. Nunc sed blandit libero volutpat sed cras ornare. Est
              placerat in egestas erat. Diam quam nulla porttitor massa id
              neque. Tellus cras adipiscing enim eu turpis egestas. Potenti
              nullam ac tortor vitae purus faucibus ornare suspendisse. Et netus
              et malesuada fames ac turpis egestas sed tempus. Augue interdum
              velit euismod in pellentesque massa placerat. Dictum at tempor
              commodo ullamcorper a lacus. Lacus luctus accumsan tortor posuere
              ac ut consequat semper viverra. Feugiat nibh sed pulvinar proin.
              Nisl pretium fusce id velit ut tortor pretium viverra suspendisse.
              Adipiscing at in tellus integer feugiat scelerisque varius morbi
              enim. Id diam maecenas ultricies mi eget mauris pharetra.
            </Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={styles.subjectsToTeachContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              SUBJECTS TO TEACH
            </Typography>
            Math
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={styles.reviewersContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              REVIEWERS
            </Typography>
            Jenna Angala
          </Paper>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={6}
        lg={6}
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Paper className={styles.referencesContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              REFERENCES
            </Typography>
            <Typography variant="body2" gutterBottom>
              {referencesData.length
                ? referencesData.map((ref) => (
                    <Paper className={styles.referencesList}>
                      <Typography variant="body2" gutterBottom>
                        {ref.name}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {ref.emailAddress}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {ref.contactNumber}
                      </Typography>
                    </Paper>
                  ))
                : null}
            </Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={styles.applicantFilesContainer}>
            <Typography variant="h6" display="block" gutterBottom>
              APPLICANT FILES
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              placerat orci nulla pellentesque dignissim enim. Ac turpis egestas
              sed tempus urna et. Egestas purus viverra accumsan in nisl nisi
              scelerisque. Nunc sed blandit libero volutpat sed cras ornare. Est
              placerat in egestas erat. Diam quam nulla porttitor massa id
              neque. Tellus cras adipiscing enim eu turpis egestas. Potenti
              nullam ac tortor vitae purus faucibus ornare suspendisse. Et netus
              et malesuada fames ac turpis egestas sed tempus. Augue interdum
              velit euismod in pellentesque massa placerat. Dictum at tempor
              commodo ullamcorper a lacus. Lacus luctus accumsan tortor posuere
              ac ut consequat semper viverra. Feugiat nibh sed pulvinar proin.
              Nisl pretium fusce id velit ut tortor pretium viverra suspendisse.
              Adipiscing at in tellus integer feugiat scelerisque varius morbi
              enim. Id diam maecenas ultricies mi eget mauris pharetra.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div className={styles.singleRoot}>
      <Grid container direction="column" className={styles.gridMainContainer}>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          className={styles.informationContainer}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={1}
            container
            justify="center"
            alignItems="center"
          >
            <Avatar
              alt={fullName}
              src={
                "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg"
              }
              className={styles.avatar}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            sm={12}
            md={4}
            lg={5}
            className={styles.informationContainer2}
          >
            <Typography variant="caption" display="block">
              {fullName}
            </Typography>

            <Typography variant="h5" display="block">
              {fullName}
            </Typography>

            <Typography variant="overline" display="block">
              {fullName}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            container
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={styles.buttonContainer}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => console.log("Save")}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {guideInfoBox}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      margin: "15px",
      width: "auto",
      height: "auto",
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    labelHeaders: {
      color: "green",
      fontWeight: 650,
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    gridMainContainer: {
      marginTop: "0.25%",
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      borderRadius: "5px",

      backgroundColor: "White",
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "84.5vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "85.5vw",
      },
      [theme.breakpoints.up("lg")]: {
        width: "86.5vw",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      height: "150px",
    },
    bioContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    referencesList: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      //     margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
