import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import useWindowDimensions from "../utils/useWindowDimensions";
import swal from "sweetalert";

import { Formik } from "formik";
import * as Yup from "yup";
import firebase from "../components/firebase/FirebaseHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function FormAddUser(props) {
  const [adminLevel] = useState("");
  const [fullName] = useState("");
  const [email] = useState("");
  const [password] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const handleClickShowPassword = () => {
    showPassword === false ? setShowPassword(true) : setShowPassword(false);
  };

  const { width } = useWindowDimensions();
  const styles = useStyles();

  useEffect(() => { }, []);

  const submitData = async (values, { resetForm }) => {
    // firebase.functions().useEmulator("localhost", 5001)
    setIsLoading(true);
    // const uid = firebase.auth().currentUser.uid;
    const memberInfo = {
      fullName: values.name,
      email: values.email,
      adminLevel: values.adminType,
      phoneNumber: values.mobile,
      password: values.password,

    };
    if (values.adminType === "Referrer Partner") {
      memberInfo["referralCode"] = randomCode(6);
      memberInfo["referrals"] = 0;
    }

    const createUserNewRef = firebase
      .functions()
      .httpsCallable("verify-createAdminUser");
    const result = await createUserNewRef(memberInfo);

    if (result.data.success) {
      swal(
        "Yazi Admin",
        result.data.message,
        "success"
      );
    } else {
      swal(
        "Yazi Admin",
        result.data.message,
        "error"
      );
    }
    setIsLoading(false);
    resetForm();
    console.log("this is the result", JSON.stringify(result.data));

  };
  return (
    <Formik
      initialValues={{
        name: fullName,
        email: email,
        password: password,
        mobile: "",
        adminType: adminLevel,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please input full name."),
        email: Yup.string().email().required("Please Re-type email."),
        password: Yup.string()
          .required("Please input a strong password")
          .min(6, "Too Short"),
        mobile: Yup.string().required("Please input a your mobile number"),
        adminType: Yup.string().required("Select a role."),
      })}
      onSubmit={submitData}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className={styles.root}>
          {isLoading ? (
            <div className={styles.modalLoading}>
              {
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    maxWidth: "80%",
                    height: "100%",
                    left: "15%",
                    right: "10%",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <Paper className={styles.loadingContainer}>
                    <div style={{ display: "flex", flex: 1, height: "20%" }}>
                      <Typography
                        display="block"
                        className={styles.secondLabels}
                        gutterBottom
                      >
                        Please Wait
                      </Typography>
                    </div>
                  </Paper>
                </div>
              }
            </div>
          ) : null}
          <Grid container className={styles.parentGrid}>
            <Grid container item direction={"column"} xs={12} sm={6}>
              <Grid item>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                  gutterBottom
                >
                  Add a User
                </Typography>
              </Grid>
              <Paper style={{ marginBottom: 16 }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                  className={styles.headerAddUser}
                >
                  <Grid item xs={12}>
                    <FormControl className={styles.dropdownContainer}>
                      <Select
                        value={values.adminType}
                        variant="outlined"
                        //  onChange={(e) => setAdminLevel(e.target.value)}
                        name="adminType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        displayEmpty
                        disableUnderline
                        className={styles.dropdownStyle}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>Select a User Role</em>
                        </MenuItem>
                        <MenuItem value={"Administrator"}>
                          Administrator
                        </MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                        <MenuItem value={"Reviewer"}>Reviewer</MenuItem>
                        <MenuItem value={"Content/Technology Partner"}>
                          Technology/Content Partner
                        </MenuItem>
                        <MenuItem value={"Referrer Partner"}>
                          Referrer Partner
                        </MenuItem>
                        <MenuItem value={"Grant Partner"}>
                          Grant Partner
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        error={
                          errors.adminType && touched.adminType ? true : false
                        }
                      >
                        {errors.adminType && touched.adminType
                          ? errors.adminType
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <Paper style={{ paddingTop: 12 }}>
                <Typography
                  variant="H7"
                  display="block"
                  className={styles.secondLabels}
                  gutterBottom
                >
                  FULL NAME
                </Typography>
                <Grid container item direction="row" alignItems="center">
                  <Grid container item direction="column">
                    <Grid item>
                      <TextField
                        placeholder="John Doe Smith"
                        variant="outlined"
                        className={styles.secondInputFields}
                        InputLabelProps={{ shrink: true }}
                        name="name"
                        //   onChange={(e) => inputCheck(e, "name")}
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* {
                                        width > 800 ?
                                            <Grid item xs={12} lg={1} className={styles.gridItems}>
                                                <Button variant="contained" onClick={handleSubmit}  >
                                                    Add user
                                                </Button>
                                            </Grid> : null
                                    } */}
                </Grid>
                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      MOBILE NUMBER
                    </Typography>

                    {/* <Typography variant="subtitle1" display="block" gutterBottom>
                                            (+15555550003)
                                        </Typography> */}
                  </div>

                  <TextField
                    placeholder="+2330244111666"
                    variant="outlined"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="mobile"
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.mobile && touched.mobile ? true : false}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item>
                  <Typography
                    variant="H7"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                    style={{ paddingTop: 16 }}
                  >
                    EMAIL
                  </Typography>
                  <TextField
                    placeholder="example@yaziacademy.com"
                    type="email"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="email"
                    value={values.email}
                    //   onChange={(e) => inputCheck(e, "email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item style={{ paddingBottom: 16 }}>
                  <Typography
                    variant="h"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                    style={{ paddingTop: 16 }}
                  >
                    PASSWORD
                  </Typography>
                  <Grid direction="row" container>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Enter Password"
                        autoComplete="off"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                        //  error={}
                        //     helperText="Incorrect entry."
                        className={styles.secondInputFields}
                        name="password"
                        value={values.password}
                        //  onChange={(e) => inputCheck(e, "password")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {/* <Grid item className={styles.fieldItems}>
                                <Typography variant="H7" display="block" className={styles.secondLabels} gutterBottom>
                                    Add User
                                </Typography>
                                <TextField  placeholder="Type Name Here"
                                    className={styles.secondInputFields}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: "calc(12px + 2.5vmin)"
                                        }
                                    }} />
                                    </Grid> */}
            {/* {
                                        width < 800 ?
                                            <Grid item xs={12} className={styles.secondAddUser}>
                                                <Button variant="contained" onClick={handleSubmit} className={styles.addUserButtonTwo} >
                                                    Add user
                                    </Button>
                                            </Grid> : null
                                    } */}
            {/* <Grid item xs={12} className={styles.secondAddUser}>

                                        <Button variant="contained" onClick={checkUser} className={styles.addUserButtonTwo} >
                                            check user
                                        </Button>
                                    </Grid> */}
            {/* </Grid> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{
                width: "100%",
                padding: 16,
                marginBottom: 32,
                marginTop: 32,
                color: "white",
                backgroundColor: "green",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              <strong>Add User</strong>
            </Button>
          </Grid>
        </div>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      paddingRight: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
      paddingRight: 25,
      paddingBottom: 8,
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
    },
    dropdownContainer: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(80,96,238,1)",
      fontWeight: 600,
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    loadingContainer: {
      flex: 1,
      height: "10%",
      maxWidth: "15%",
      alignSelf: "center",
      paddingTop: 20,

      [theme.breakpoints.down("sm")]: {
        height: "12%",
        maxWidth: "25%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "12%",
      },
      [theme.breakpoints.up("lg")]: {

        maxWidth: "8%",
      },
      [theme.breakpoints.up("xl")]: {
        height: "7%",
        maxWidth: "5%",
      },
    },
    modalLoading: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.3)",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      justifyContent: "center",
      zIndex: 1000,
    },
  })
);

function randomCode(length) {
  var result = "";
  var characters = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
