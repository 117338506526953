import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import firebase from "./components/firebase/FirebaseHelper";
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  makeStyles,
  createStyles,
} from "@material-ui/core/styles";
import NewPassword from "./pages/NewPassword";
import Dashboard from "./pages/Dashboard";

// firebase.auth().useEmulator('http://localhost:9099')
// firebase.firestore().useEmulator("localhost", 8080)

export default function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const styles = makeStyles((theme) =>
    createStyles({
      root: {
        backgroundColor: "#EBEBEB",
      },
    })
  );
  const font = "Nunito Sans, sans-serif";
  let theme = createTheme({
    typography: {
      fontFamily: font,
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "1rem",
      },
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          fontSize: "calc(8px + 0.5vmin)",
          fontStyle: "italic",
        },
      },
    },
    //  spacing: 10
  });

  theme = responsiveFontSizes(theme);

  useEffect(() => {
    // firebase.auth().signOut().then(function () {
    //   console.log("Sign-out successful.")
    // }).catch(function (error) {
    //   // An error happened.
    // });
    const subscriber = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("web-admin")
          .doc(user.uid)
          .get()
          .then((firestoreDocument) => {
            if (!firestoreDocument.exists) {
              return;
            }
            const user = firestoreDocument.data();
            setUserData(user);
            changeLoggedInStatus(true);
          })
          .catch((error) => {
            alert(error);
          });
        //setUser(user)
      } else {
        changeLoggedInStatus(false);
      }
    });

    return subscriber;
  }, []);

  const changeLoggedInStatus = (newStatus) => {
    setIsUserLoggedIn(newStatus);
    if (!newStatus) {
      try {
        firebase
          .auth()
          .signOut()
          .then(function () { })
          .catch(function (error) {
            // An error happened.
          });
      } catch (e) { }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            render={() =>
              //use render to pass data to component
              !isUserLoggedIn ? (
                <Login changeLoggedInStatus={changeLoggedInStatus} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth",
                  }}
                />
              )
            }
          />
          <Route
            path="/password-reset"
            exact
            render={() => <PasswordReset />}
          />
          <Route path="/new-password" exact render={() => <NewPassword />} />
          <Route
            path="/login"
            exact
            render={() =>
              //use render to pass data to component
              !isUserLoggedIn ? (
                <Login changeLoggedInStatus={changeLoggedInStatus} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth",
                  }}
                />
              )
            }
          />
          <Route
            path="/auth"
            render={() =>
              //use render to pass data to component
              isUserLoggedIn ? (
                <div className={styles.root}>
                  <ResponsiveDrawer
                    changeLoggedInStatus={changeLoggedInStatus}
                    user={userData}
                  />
                </div>
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                  }}
                />
              )
            }
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
