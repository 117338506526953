import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//import Avatar from '@material-ui/core/Avatar';
//import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
//import InputLabel from '@material-ui/core/InputLabel';
//import FormControl from '@material-ui/core/FormControl';
///import Select from '@material-ui/core/Select';
import useWindowDimensions from "../utils/useWindowDimensions";
import TextField from "@material-ui/core/TextField";
//import { height } from "@fortawesome/pro-regular-svg-icons/faSackDollar";
import builders from "../buildersManifest.json";
import buildersImage from "../images/stay_curious.png";
import yaziAdminLogo from "../images/yazi-admin.png";
import swal from "sweetalert";
//import firebase from "../components/firebase/FirebaseHelper"
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import firebase from "../components/firebase/FirebaseHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function Login() {
  const { height, width } = useWindowDimensions();
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    showPassword === false ? setShowPassword(true) : setShowPassword(false);
  };

  const [userName, SetUserName] = useState("");
  const [userPassword, SetUserPassword] = useState("");
  let history = useHistory();
  // const firebase = props.firebaseApp
  const min = 0;
  const max = builders.builderData.length - 1;
  const rand = Math.floor(Math.random() * (max - min + 1)) + min;
  const [quoteIndex, setQuoteIndex] = useState(rand);

  useEffect(() => {
    // logoutFirebase()
  }, [height, width]);

  const logoutFirebase = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("Sign-out successful.");
      })
      .catch(function (error) {
        // An error happened.
      });
  };
  const checkAuth = () => {
    if (userName.trim().length === 0 || userPassword.trim().length === 0) {
      console.log("error in length");
      swal("Yazi Admin", "Username or Password cannot be empty", "error");
      return;
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(userName, userPassword)
      .then((response) => {
        const uid = response.user.uid;
        const usersRef = firebase.firestore().collection("web-admin");
        usersRef
          .doc(uid)
          .get()
          .then((firestoreDocument) => {
            if (!firestoreDocument.exists) {
              // alert("User does not exist anymore.")
              swal("Yazi Admin", "User does not exist anymore", "error");
              return;
            } else {
              const user = firestoreDocument.data();
              //    props.changeLoggedInStatus(true)
              history.push({
                pathname: "/auth",
                state: { user },
              });
            }
          })
          .catch((error) => {
            // alert(error)
            console.log("Yazi Admin Login Error Code: " + error);
            swal("Yazi Admin", error.message, "error");
          });
      })
      .catch((error) => {
        // alert(error)
        console.log("Yazi Admin Login Error Code: " + error.code);
        swal("Yazi Admin", error.message, "error");
        switch (error.code) {
          case "auth/invalid-email":
            swal(
              "Yazi Admin",
              "Email address not valid, kindly review email address prpovided",
              "error"
            );
            break;
          case "auth/wrong-password":
            swal("Yazi Admin", "Password is wrong, try again", "error");
            break;
          case "auth/user-not-found":
            swal(
              "Yazi Admin",
              "There's no email associated with this email address",
              "error"
            );
            break;
          case "auth/user-disabled":
            swal(
              "Yazi Admin",
              "User has been disabled by the Yazi Support Team, kindly contact the team",
              "error"
            );
            break;
          case "auth/too-many-requests":
            swal(
              "Yazi Admin",
              "Account is temporarily locked due to many unsuccessive tries, please try again after a while",
              "error"
            );
            break;
          default:
            swal(
              "Yazi Admin",
              "Fatal error occurred, kindly contact the Yazi Support Team",
              "error"
            );
        }
      });
  };
  return (
    <form className={styles.singleRoot} noValidate autoComplete="off">
      <Grid container direction="row" className={styles.parentGrid}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
          className={styles.leftColumn}
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
            className={styles.leftColumInner}
          >
            <Grid item className={styles.buttonContainer}>
              <CardMedia
                component="img"
                alt="Yazi Admin Logo"
                image={yaziAdminLogo}
                className={styles.logoImageContainer}
              />
            </Grid>
            <Grid item className={styles.buttonContainer}>
              {/* Login text shows here */}
              <Typography
                variant="h4"
                display="block"
                style={{
                  paddingBottom: 45,
                  color: "rgba(43, 50, 119, 1)",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Log in
              </Typography>
            </Grid>
            <Grid item className={styles.buttonContainer}>
              <Typography
                variant="h7"
                display="block"
                style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }}
                gutterBottom
              >
                EMAIL ADDRESS
              </Typography>
              <TextField
                style={{
                  paddingBottom: 35,
                }}
                error={false}
                required
                variant="outlined"
                id="standard-required"
                placeholder="yaziapp@gmail.com"
                inputProps={{}}
                helperText={""}
                className={styles.input}
                onChange={(e) => SetUserName(e.target.value)}
                onBlur={(e) => SetUserName(e.target.value)}
              />
            </Grid>
            <Grid item className={styles.buttonContainer}>
              <Typography
                variant="h7"
                display="block"
                style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }}
                gutterBottom
              >
                PASSWORD
              </Typography>
              <Grid direction="row" item spacing={1}>
                <TextField
                  style={{ paddingBottom: 45 }}
                  error={false}
                  required
                  variant="outlined"
                  id="standard-required"
                  placeholder="yazipassword"
                  inputProps={{}}
                  helperText={""}
                  className={styles.input}
                  onChange={(e) => SetUserPassword(e.target.value)}
                  onBlur={(e) => SetUserPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item className={styles.buttonContainer}>
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                className={styles.LoginButton}
                onClick={() => checkAuth()}
              >
                <strong>Log in</strong>
              </Button>
            </Grid>
            <Grid item className={styles.buttonContainer}>
              <Button
                style={{
                  marginTop: 30,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}
                onClick={() =>
                  history.push({
                    pathname: "/password-reset",
                  })
                }
              >
                <strong>Forgot Password?</strong>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
          className={styles.rightColumn}
        >
          <Grid item className={styles.cardContainer}>
            <CardMedia
              component="img"
              alt="Builder's Content"
              image={buildersImage}
              title="Builder's Content"
              className={styles.buildersImageContainer}
            />
            <CardContent>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(52,168,83,1)" }}
                gutterBottom
              >
                Builder's Foundation
              </Typography>
              <Typography
                variant="h4"
                display="block"
                style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }}
                gutterBottom
              >
                {builders.builderData[quoteIndex].title}
              </Typography>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(37,95,40,1)" }}
                gutterBottom
              >
                {builders.builderData[quoteIndex].description}
              </Typography>
            </CardContent>
          </Grid>

          {/* <Grid item container
                        xs={false} sm={false} md={6} lg={6} xl={6}
                        className={styles.image}>

                    </Grid>
                    <Grid item container
                        xs={12} sm={6} md={6} lg={6} xl={6}
                        direction="column"
                        justify="center"
                        alignContent="center"
                        className={styles.imageText}
                    >
                        <Typography variant="h6" display="block" style={{ color: "rgba(52,168,83,1)" }} gutterBottom>
                            Builder's Foundation
      </Typography>
                        <Typography variant="h4" display="block" style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }} gutterBottom>
                            {
                                builders.builderData[quoteIndex].title
                            }
                        </Typography>
                        <Typography variant="h6" display="block" style={{ color: "rgba(37,95,40,1)" }} gutterBottom>
                            {
                                builders.builderData[quoteIndex].description
                            }
                        </Typography>
                    </Grid> */}
        </Grid>
      </Grid>
    </form>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    singleRoot: {
      flexGrow: 1,
    },
    cardContainer: {
      padding: "5%",
      backgroundColor: "rgba(00,255,168,0)",
    },
    parentGrid: {
      maxHeight: "100%",
      maxWidth: "100%",
      height: "100vh",
    },
    leftColumn: {
      //   height: useWindowDimensions().height
    },
    leftColumInner: {
      // backgroundColor: "red",
    },
    rightColumn: {
      height: "100%",
      backgroundColor: "rgba(00,255,168,0.25)",
    },
    buildersImageContainer: {
      width: "50%",
      opacity: 0.8,
    },
    logoImageContainer: {
      width: "50%",
      paddingBottom: 45,
    },
    input: {
      width: "20vw",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        // backgroundColor: "Purple",
      },
    },
    image: {},
    buttonContainer: {
      width: "20vw",
      [theme.breakpoints.down("sm")]: {
        // width: "50vw",
        minWidth: "80%",
      },
      [theme.breakpoints.up("md")]: {
        //  minWidth: "50%"
      },
      [theme.breakpoints.up("lg")]: {
        //  minWidth: "50%"
      },
    },
    imageText: {
      //   backgroundColor: "rgba(52,168,255,1)",
      minWidth: "100%",
      padding: 20,
    },
    LoginButton: {
      padding: 8,
      backgroundColor: "rgba(52,168,83,1)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        minWidth: "50%",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "50%",
      },
    },
  })
);
