import React, { useState, useEffect, createRef, forwardRef } from "react";
import MaterialTable from "material-table";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import { TablePagination } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import SummaryHeader from "../../components/SummaryHeader"
import ViewELearningCourse from "../../components/ViewELearningCourse";
import firebase from "../../components/firebase/FirebaseHelper";
import { generateUUID } from "../../utils/uniqueKeyGenerator";
import {
  //   BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { yellow } from "@material-ui/core/colors";

const headerHeight = 1325;
const headerHeightSingle = 100;

const ColoredRadio = withStyles({
  root: {
    color: yellow[700],
    "&$checked": {
      color: yellow[900],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Courses(props) {
  const styles = useStyles();

  const [pagesPerRow, setPagesPerRow] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [firstCourse, setFirstCourse] = useState({});
  const [lastCourse, setLastCourse] = useState({});
  const [courses, setCourses] = useState([]);
  const [coursesLength, setCoursesLength] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  let { path } = useRouteMatch();
  let history = useHistory();
  let location = useLocation();
  const tableRef = createRef();
  useEffect(() => {
    let didCancel = false;

    async function fetchCoursesAvailable() {
      const currentUser = firebase.auth().currentUser;
      const uid = currentUser.uid;
      await firebase
        .firestore()
        .collection("e-learning-courses")
        .get()
        .then(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              setIsLoading(false);
              return;
            }
            const listCount = querySnapshot.docs.length;
            const computedTotalPage = Math.ceil(listCount / pagesPerRow);
            setCoursesLength(listCount);
            setTotalPages(computedTotalPage);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        );

      await firebase
        .firestore()
        .collection("e-learning-courses")
        .orderBy("title", "asc")
        //  .limit(pagesPerRow) //TOD
        .get()
        .then(async (querySnapshot) => {
          const tempDoc = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const docID = doc.id;
              const weijoThumbnailRef = firebase
                .storage()
                .refFromURL("gs://ancient-tractor-277102-e-learning")
                .child(`${docID}/thumbnail.jpg`);
              const weijoThumbnailURL =
                await weijoThumbnailRef.getDownloadURL();
              const _data = {
                id: docID,
                category: doc.data().category,
                thumbnail: weijoThumbnailURL,
                author: doc.data().author,
                description: doc.data().description,
                lessonDuration: doc.data().lessonDuration,
                lessonReadableDuration: doc.data().lessonReadableDuration,
                price: doc.data().price,
                takeAways: doc.data().takeAways,
                title: doc.data().title,
              };
              return _data;
            })
          );
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const firstVisible = querySnapshot.docs[0];

          const fixedData = {
            tempDoc,
            firstVisible,
            lastVisible,
          };
          return fixedData;
        })
        .then((fixedData) => {
          setCourses(fixedData.tempDoc);
          setFirstCourse(fixedData.firstVisible);
          setLastCourse(fixedData.lastVisible);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log("error", e);
        });

      if (!didCancel) {
        // Ignore if we started fetching something else
        //    console.log("change data");
      }
    }

    fetchCoursesAvailable();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [pagesPerRow]);
  const GetDataChangeValues = async (e, pageNumber) => {
    setIsLoading(true);
    let dataToFetch;
    if (pageNumber > currentPage) {
      dataToFetch = firebase
        .firestore()
        .collection("e-learning-courses")
        .orderBy("title", "asc")
        .startAfter(lastCourse)
        .limit(pagesPerRow); //TOD
    } else if (pageNumber < currentPage) {
      dataToFetch = firebase
        .firestore()
        .collection("e-learning-courses")
        .orderBy("title", "asc")
        .endBefore(firstCourse)
        .limitToLast(pagesPerRow); //TOD
    }
    setCurrentPage(pageNumber);

    await dataToFetch
      .get()
      .then(async (querySnapshot) => {
        const tempDoc = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const docID = doc.id;
            const weijoThumbnailRef = firebase
              .storage()
              .refFromURL("gs://ancient-tractor-277102-e-learning")
              .child(`${docID}/thumbnail.jpg`);
            const weijoThumbnailURL = await weijoThumbnailRef.getDownloadURL();

            const _data = {
              id: docID,
              thumbnail: weijoThumbnailURL,
              category: doc.data().category,
              author: doc.data().author,
              description: doc.data().description,
              lessonDuration: doc.data().lessonDuration,
              lessonReadableDuration: doc.data().lessonReadableDuration,
              price: doc.data().price,
              takeAways: doc.data().takeAways,
              title: doc.data().title,
            };
            return _data;
          })
        );

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const firstVisible = querySnapshot.docs[0];

        const fixedData = {
          tempDoc,
          firstVisible,
          lastVisible,
        };
        return fixedData;
      })
      .then((fixedData) => {
        setCourses(fixedData.tempDoc);
        setFirstCourse(fixedData.firstVisible);
        setLastCourse(fixedData.lastVisible);
      })
      .finally(() => setIsLoading(false));
  };
  const GetDataRowPerPage = (pagePerRow) => {
    setPagesPerRow(pagePerRow);
  };
  const rowClick = (row) => {
    setSelectedCourse(row);
    //  props.selectedData(row)
    //  setSelectedUserURL("/user/" + `${row.id}`)
    history.push({
      pathname: `${path}/${row.id}`,
      //   search: `?${row.id}`,
      state: { data: row },
    });
  };

  const summaryView = (
    <div className={styles.tables}>
      {/* <FormControl component="fieldset">
                <FormLabel component="legend">Users</FormLabel>
                <RadioGroup row aria-label="gender" name="gender1" value={kindOfUser} onChange={handleChangeUser}>
                    <FormControlLabel value="parent" control={<ColoredRadio />} label="Parent" />
                    <FormControlLabel value="student" control={<ColoredRadio />} label="Student" />
                    <FormControlLabel value="all" control={<ColoredRadio />} label="All" />
                </RadioGroup>
            </FormControl> */}
      {!isLoading}
      <MaterialTable
        icons={tableIcons}
        title={""}
        tableRef={tableRef}
        options={{ showFirstLastPageButtons: false }} // options={{ sorting: true, filtering: true, showFirstLastPageButtons: false }}
        columns={[
          {
            title: "Thumbnail",
            field: "thumbnail",
            render: (rowData) => (
              <div
                style={{
                  height: 64,
                  width: 64,
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ flex: 1, width: 64, height: 64, borderRadius: 50 }}
                  src={rowData.thumbnail}
                />
              </div>
            ),
            filtering: false,
            sorting: false,
          },
          {
            title: "Title",
            field: "title",
          },
          {
            title: "Category",
            field: "category",
          },
          {
            title: "Price",
            field: "price",
          },
          // {
          //     title: 'Date Created',
          //     field: 'fixedDateCreated',
          //     render: (rowData) => (
          //         <span>
          //             {rowData['fixedDateCreated'] ? rowData['fixedDateCreated'] : "--"}
          //         </span>
          //     )
          // }
        ]}
        data={courses}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              count={coursesLength}
              page={currentPage}
              rowsPerPage={pagesPerRow}
              rowsPerPageOptions={[5, 10, 25, 50]}
              showFirstLastPageButtons={false}
              onChangePage={GetDataChangeValues}
            />
          ),
        }}
        onRowClick={(event, rowData, toggleDetailPanel) => {
          rowClick(rowData);
        }}
        onChangeRowsPerPage={(pageSize) => GetDataRowPerPage(pageSize)}
        isLoading={isLoading}
      />
      <div className={styles.tableFooter} />
    </div>
  );

  return (
    <Switch>
      <div className={styles.root}>
        <Route path={`${path}`} exact>
          {summaryView}
        </Route>
        <Route path={`${path}/:id`} render={() => <ViewELearningCourse />} />
      </div>
    </Switch>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // display: 'flex',
      // width: "92vw",
      // paddingRight: "5vw",
      // flexDirection: "column"
    },
    tables: {
      flex: 1,
    },
    tableHeaders: {
      display: "flex",
      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Purple",
      },
    },
    tableFooter: {
      padding: theme.spacing(2),
    },
    tableHeaderNumber: {
      backgroundColor: "F29A33",
      width: "100px",
    },
    tableHeaderText1: {
      backgroundColor: "Green",
      width: "200px",
    },
    tableHeaderText2: {
      backgroundColor: "White",
      flex: 1,
    },
    tablePadding: {
      padding: theme.spacing(2),
    },
    singleViewHeader: {
      display: "flex",

      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Purple",
      },
    },
    avatar: {
      margin: "15px",
      width: "auto",
      height: "auto",
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    gridMainContainer: {
      marginTop: "0.25%",
      backgroundColor: "Purple",
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      borderRadius: "5px",
      backgroundColor: "White",

      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "84.5vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "85.5vw",
      },
      [theme.breakpoints.up("lg")]: {
        width: "86.3vw",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      height: "150px",
    },
    bioContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      //     margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
