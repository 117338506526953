import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import firebase from "../components/firebase/FirebaseHelper";
import no_profile from "../images/no_profile.png";

export default function Profile() {
  const styles = useStyles();
  const [MyProfileData, setMyProfileData] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    setMyProfileData(currentUser);
    UserRole();
    // console.log(userDetails.displayName);
  }, [MyProfileData]);

  const UserRole = (documentId) => {
    //    const currentUser = firebase.auth().currentUser.uid
    const uid = firebase.auth().currentUser.uid;
    const store = firebase.firestore();
    const usersRef = store.collection("web-admin").doc(uid);

    try {
      usersRef
        .get()
        .then((firestoreDocument) => {
          if (!firestoreDocument.exists) {
            swal("Yazi Admin", "User does not exist anymore", "error");
            return;
          }
          const user = firestoreDocument.data();
          setUserRole(user.adminLevel);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log("error", e);
    }
  };
  return (
    <div className={styles.root}>
      <Grid container className={styles.parentGrid}>
        <Grid container item direction={"column"} xs={12} sm={6}>
          <Grid item>
            <Typography
              variant="h5"
              display="block"
              style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
              gutterBottom
            >
              My Profile
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Grid item>
              <img
                src={no_profile}
                alt=""
                style={{ height: 128, width: 128 }}
              />
            </Grid>
          </Grid>
          <Paper>
            <Grid container style={{ padding: 16 }} direction="column">
              <Typography variant="h7">User Full Name</Typography>
            </Grid>
            <Grid
              container
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              direction="column"
            >
              <Typography variant="h6">{MyProfileData.displayName}</Typography>
            </Grid>
          </Paper>
          <Paper style={{ marginTop: 16 }}>
            <Grid container style={{ padding: 16 }} direction="column">
              <Typography variant="h7">Role</Typography>
            </Grid>
            <Grid
              container
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              direction="column"
            >
              <Typography variant="h6">{userRole}</Typography>
            </Grid>
          </Paper>
          <Paper style={{ marginTop: 16 }}>
            <Grid container style={{ padding: 16 }} direction="column">
              <Typography variant="h7">Email</Typography>
            </Grid>
            <Grid
              container
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              direction="column"
            >
              <Typography variant="h6">{MyProfileData.email}</Typography>
            </Grid>
          </Paper>
          <Paper style={{ marginTop: 16 }}>
            <Grid container style={{ padding: 16 }} direction="column">
              <Typography variant="h7">Phone Number</Typography>
            </Grid>
            <Grid
              container
              style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              direction="column"
            >
              <Typography variant="h6">
                {MyProfileData.phoneNumber == ""
                  ? "N/A"
                  : MyProfileData.phoneNumber}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      paddingRight: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
      paddingRight: 25,
      paddingBottom: 8,
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
