import React, { useEffect, useState } from "react";
import { timestamp } from "../components/firebase/FirebaseHelper";
import firebase from "../components/firebase/FirebaseHelper";
import moment from "moment";
import MaterialUITableTransactions from "../components/MaterialUITableTransactions";
import no_profile from "../images/no_profile.png";

export default function Transactions() {
  const transactionObject = {
    label: "Lorem Ipsum",
    key: "lorem_ipsum",
    summary:
      "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    addedBy: "Mr. Lorem Ipsum",
    fixCreatedOn: timestamp(),
  };
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedTransaction, setSelectedTransaction] =
    useState(transactionObject);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [firstTransaction, setFirstTransaction] = useState({});
  const [lastTransaction, setLastTransaction] = useState({});
  const [transactionList, setTransactionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;

    async function fetchCoursesMentorAvailable() {
      //GET ALL TOTAL
      await firebase
        .firestore()
        .collection("checkouts") //
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            setTotalCount(0);
            setNumberOfPages(0);
            return;
          }
          const listCount = querySnapshot.docs.length;
          const computedTotalPage = Math.ceil(listCount / rowsPerPage);
          setTotalCount(listCount);
          setNumberOfPages(computedTotalPage);
        });
      // GET INITIAL USERS and FIRST AND LAST for Pagination
      await firebase
        .firestore()
        .collection("checkouts") //
        // .orderBy("dateCreated", "desc")
        .limit(rowsPerPage)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            return;
          }
          const data = querySnapshot.docs.map((ref, index) => ({
            id: index,
            fixedDateCreated: ref.data().dateCreated
              ? moment(ref.data().dateCreated.toDate())
                  .format("MMM D, YYYY")
                  .toString()
              : "--",
            ...ref.data(),
          }));
          const firstVisible = querySnapshot.docs[0];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const fixedData = await FixData(data);

          //set initial users here
          setFirstTransaction(firstVisible);
          setLastTransaction(lastVisible);
          console.log("Subject List Data: " + data);
          setTransactionList(fixedData);
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("change data");
      }
    }

    fetchCoursesMentorAvailable();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [rowsPerPage]);

  const FixData = async (data) => {
    const fixedData = await Promise.all(
      data.map(async (data) => {
        let tutorName = "";
        let studentName = "";
        const getTutorName = firebase
          .firestore()
          .collection("users")
          .where("id", "==", data.tutorID);
        await getTutorName.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            console.log(doc.id, " => ", doc.data().fullName);
            tutorName = doc.data().fullName;
          });
        });

        const getStudentName = firebase
          .firestore()
          .collection("users")
          .where("id", "==", data.studentID);
        await getStudentName.get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            console.log(doc.id, " => ", doc.data().fullName);
            studentName = doc.data().fullName;
          });
        });
        let _fixedData = {
          tutorName,
          studentName,
          ...data,
        };
        return _fixedData;
      })
    );
    return fixedData;
  };

  const LoadNextData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection("checkout") //
      // .where(kindOfSubject)
      // .orderBy("dateCreated", "desc")
      .startAfter(lastTransaction)
      .limit(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
                .format("MMM D, YYYY")
                .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        setFirstTransaction(firstVisible);
        setLastTransaction(lastVisible);
        setTransactionList(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LoadPrevData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection("checkouts") //
      // .orderBy("dateCreated", "desc")
      .endBefore(firstTransaction)
      .limitToLast(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
                .format("MMM D, YYYY")
                .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setFirstTransaction(firstVisible);
        setLastTransaction(lastVisible);
        setTransactionList(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const handleChangeSubject = (event) => {
  //   setKindOfSubject(event.target.value);
  // };
  // const handleChange = (value) => {
  //   console.log(value);
  // };

  const ChangeSize = (pageSize) => {
    setRowsPerPage(pageSize);
    setIsLoading(true);
  };

  const getDataFromChild = (row) => {
    setSelectedTransaction(row);
    // //  props.selectedData(row)
    // //  setSelectedUserURL("/user/" + `${row.id}`)
    // history.push({
    //   pathname: `${path}/${kindOfSubject}`,
    //   //   search: `?${row.id}`,
    //   state: { data: row },
    // });
  };

  const ChangePage = async (e, pageNumber) => {
    //check if previous of next
    if (pageNumber > currentPage) {
      await LoadNextData();
    } else if (pageNumber < currentPage) {
      await LoadPrevData();
    }
    setCurrentPage(pageNumber);
  };

  return (
    <MaterialUITableTransactions
      getDataFromChild={getDataFromChild}
      listOfTransactions={transactionList}
      isLoading={isLoading}
      totalCount={totalCount}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      changeRowsPerPage={ChangeSize}
      changePage={ChangePage}
    />
  );
}
