import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/app-check";
const firebaseConfig = {
  apiKey: "AIzaSyAxLZBimDciWUJOs7wUx3GwCfvkTJuazN8",
  authDomain: "ancient-tractor-277102.firebaseapp.com",
  databaseURL: "https://ancient-tractor-277102.firebaseio.com",
  projectId: "ancient-tractor-277102",
  storageBucket: "ancient-tractor-277102.appspot.com",
  messagingSenderId: "293038259746",
  appId: "1:293038259746:web:47dabb1ec9f620cb8e547b",
  measurementId: "G-08GSJ346DE",
};

// const projectStorage = firebase.storage();
// const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const union = firebase.firestore.FieldValue.arrayUnion;
const taskEvents = firebase.storage.TaskEvent;
export { timestamp, union, taskEvents };
export default !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
