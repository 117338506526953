import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
//import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Card from '@material-ui/core/Card';
import firebase from "../components/firebase/FirebaseHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faUserGraduate } from "@fortawesome/pro-solid-svg-icons/faUserGraduate";

const UserButton = withStyles({
  root: {
    backgroundColor: "rgba(244,207, 76, 1)",
    "&:hover": {
      backgroundColor: "rgba(255,232, 21, 1)",
    },
  },
})(Button);
const MentorButton = withStyles({
  root: {
    backgroundColor: "rgba(51,114, 188, 1)",
    "&:hover": {
      backgroundColor: "rgba(38,158, 255, 1)",
    },
  },
})(Button);
const TutorButton = withStyles({
  root: {
    backgroundColor: "rgba(48,48, 120, 1)",
    "&:hover": {
      backgroundColor: "rgba(40,70, 173, 1)",
    },
  },
})(Button);

export default function Dashboard(props) {
  const [studentCount, setStudentCount] = useState(0);
  const [parentCount, setParentCount] = useState(0);
  const [tutorCount, setTutorCount] = useState(0);
  const [mentorCount, setMentorCount] = useState(0);

  const userData = props.user;
  const styles = useStyles();
  let { path } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    console.log(userData);
    let didCancel = false;
    if (userData.adminLevel != "Referrer Partner") {
      const asyncGetCollection = async () => {
        await firebase
          .firestore()
          .collection("users") //
          .where("userType", "==", "student") // future check if approved or not
          .get()
          .then(
            (querySnapshot) => {
              if (querySnapshot.empty) {
                console.log("none");
                return;
              }
              const listCount = querySnapshot.docs.length;
              setStudentCount(listCount);
            },
            (error) => {
              console.log("this is error", error);
              //    setLoadSchedules(false)
            }
          );

        await firebase
          .firestore()
          .collection("users") //
          .where("userType", "==", "parent") // future check if approved or not
          .get()
          .then(
            (querySnapshot) => {
              if (querySnapshot.empty) {
                console.log("none");
                return;
              }
              const listCount = querySnapshot.docs.length;
              setParentCount(listCount);
            },
            (error) => {
              console.log("this is error", error);
              //    setLoadSchedules(false)
            }
          );

        await firebase
          .firestore()
          .collection("users") //
          .where("guideType", "==", "mentor") // future check if approved or not
          .get()
          .then(
            (querySnapshot) => {
              if (querySnapshot.empty) {
                console.log("none");
                return;
              }
              const listCount = querySnapshot.docs.length;
              setMentorCount(listCount);
            },
            (error) => {
              console.log("this is error", error);
              //    setLoadSchedules(false)
            }
          );
        await firebase
          .firestore()
          .collection("users") //
          .where("guideType", "==", "tutor")
          .get()
          .then(
            (querySnapshot) => {
              if (querySnapshot.empty) {
                console.log("none");
                return;
              }
              const listCount = querySnapshot.docs.length;
              setTutorCount(listCount);
            },
            (error) => {
              console.log("this is error", error);
              //    setLoadSchedules(false)
            }
          );
        if (!didCancel) {
          // Ignore if we started fetching something else
          //    console.log("change data");
        }
      };
      asyncGetCollection();
    }

    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  const moveToOtherScreen = async (location) => {
    history.push({
      pathname: `${path}/${location}`,
    });
  };

  const getCollection = () => {
    try {
      firebase
        .firestore()
        .collection("users") //
        .where("userType", "==", "student") // future check if approved or not
        .get()
        .then(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("none");
              return;
            }
            const listCount = querySnapshot.docs.length;
            setStudentCount(listCount);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        );

      firebase
        .firestore()
        .collection("users") //
        .where("userType", "==", "parent") // future check if approved or not
        .get()
        .then(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("none");
              return;
            }
            const listCount = querySnapshot.docs.length;
            setParentCount(listCount);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        );

      firebase
        .firestore()
        .collection("users") //
        .where("guideType", "==", "mentor") // future check if approved or not
        .get()
        .then(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("none");
              return;
            }
            const listCount = querySnapshot.docs.length;
            setMentorCount(listCount);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        );
      firebase
        .firestore()
        .collection("users") //
        .where("guideType", "==", "tutor")
        .get()
        .then(
          (querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("none");
              return;
            }
            const listCount = querySnapshot.docs.length;
            setTutorCount(listCount);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        );
    } catch (e) {
      console.log("There is an error", e);
    }
  };

  const ListAuthUsers = async () => {
    const _listAuthUsers = firebase
      .functions()
      .httpsCallable("listAllAuthUsers");
    await _listAuthUsers({})
      .then((response) => {
        alert("Account Updated");
      })
      .catch((e) => alert("Something went wrong.", e));
  };
  return (
    <Grid container direction="row" className={styles.root}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        direction="column"
        justify="center"
        alignContent="center"
        alignItems="center"
        className={styles.columnContainer}
      >
        <Grid item className={styles.cardStyles}>
          <MentorButton
            variant="contained"
            onClick={() =>
              userData.adminLevel == "Referrer Partner"
                ? null
                : moveToOtherScreen("mentors")
            }
            className={styles.mentorButton}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
            >
              <Grid item>
                <Typography variant="h6" display="block" gutterBottom>
                  {userData.adminLevel === "Referrer Partner"
                    ? "Referral Code"
                    : "Mentors"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h2"
                  display="block"
                  style={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  {mentorCount > 0
                    ? mentorCount.toString()
                    : userData.adminLevel === "Referrer Partner"
                      ? userData.referralCode
                      : "--"}
                </Typography>
              </Grid>
              {userData.adminLevel != "Referrer Partner" ? (
                <>
                  <Grid item>
                    <button className="viewAllBtn">
                      View All{" "}
                      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </button>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </MentorButton>
        </Grid>
        <Grid item className={styles.cardStyles}>
          <TutorButton
            variant="contained"
            onClick={() =>
              userData.adminLevel == "Referrer Partner"
                ? null
                : moveToOtherScreen("tutors")
            }
            className={styles.tutorButton}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
            >
              <Grid item>
                <Typography variant="h6" display="block" gutterBottom>
                  {userData.adminLevel === "Referrer Partner"
                    ? "Referrals"
                    : "Tutors"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h2"
                  display="block"
                  style={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  {tutorCount > 0
                    ? tutorCount.toString()
                    : userData.adminLevel === "Referrer Partner"
                      ? userData.referrals
                      : "--"}
                </Typography>
              </Grid>
              {userData.adminLevel != "Referrer Partner" ? (
                <>
                  <Grid item>
                    <button className="viewAllBtn">
                      View All{" "}
                      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </button>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </TutorButton>
          {/* <TutorButton variant="contained" onClick={() => ListAuthUsers()} className={styles.tutorButton} >

                    </TutorButton> */}
        </Grid>
        {userData.adminLevel != "Referrer Partner" ? (
          <>
            <Grid item className={styles.cardStyles}>
              <UserButton
                variant="contained"
                onClick={() => moveToOtherScreen("users")}
                className={styles.userButton}
              >
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  alignContent="flex-start"
                  style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}
                >
                  <Grid item>
                    <Typography variant="h6" display="block" gutterBottom>
                      Users
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h2"
                      display="block"
                      style={{ fontWeight: "bold" }}
                      gutterBottom
                    >
                      {studentCount > 0 || parentCount > 0
                        ? (studentCount + parentCount).toString()
                        : "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <button className="viewAllBtn">
                      View All{" "}
                      <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    </button>
                  </Grid>
                </Grid>
              </UserButton>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        direction="column"
        justify="center"
        alignContent="center"
        alignItems="center"
        className={styles.columnContainer}
      >
        {/* <Grid item style={{ width: "100%", height: "100%" }}>
                    <Card >
                        <Typography variant="h6" display="block" style={{ color: "rgba(52,168,83,1)" }} gutterBottom>
                            Builder's Foundation
      </Typography>
                    </Card>
                </Grid> */}
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    columnContainer: {},
    cardStyles: {
      width: "100%",
      height: 200,
      marginTop: 20,
    },
    userButton: {
      color: "white",
      width: "100%",
      height: 200,
    },
    mentorButton: {
      color: "white",
      width: "100%",
      height: 200,
    },
    tutorButton: {
      color: "white",
      width: "100%",
      height: 200,
    },
  })
);
