import React, { createRef, forwardRef } from "react";
import { TablePagination } from "@material-ui/core";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { alpha } from "@material-ui/core/styles";
import moment from "moment";

export default function MaterialUITableTransactions(props) {
  const isLoading = props.isLoading;
  const listOfTransactions = props.listOfTransactions;
  const totalCount = props.totalCount;
  const currentPage = props.currentPage;
  const rowsPerPage = props.rowsPerPage;
  // const styles = useStyles();
  const tableRef = createRef();

  const ChangePage = (e, pageNumber) => {
    props.changePage(e, pageNumber);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  return (
    <MaterialTable
      icons={tableIcons}
      title={""}
      tableRef={tableRef}
      options={{ showFirstLastPageButtons: false }} // options={{ sorting: true, filtering: true, showFirstLastPageButtons: false }}
      columns={[
        // {
        //   title: "Avatar",
        //   field: "avatar",
        //   render: (rowData) => (
        //     <div
        //       style={{
        //         height: 64,
        //         width: 64,
        //         justifyContent: "center",
        //         alignContent: "center",
        //         alignItems: "center",
        //       }}
        //     >
        //       <img
        //         style={{ flex: 1, width: 64, height: 64, borderRadius: 50 }}
        //         src="../../public/logo192.png"
        //         alt=""
        //       />
        //     </div>
        //   ),
        //   filtering: false,
        //   sorting: false,
        // },
        {
          title: "Mentor/Tutor",
          field: "tutorName",
          // render: (rowData) => (
          // <span>
          //   {console.log("Mentor/Tutor Data: " + JSON.stringify(rowData))}
          // </span>
          // ),
        },
        {
          title: "Student",
          field: "studentName",
          // render: (rowData) => (
          //   <span>
          //     {console.log("Student Data: " + JSON.stringify(rowData))}
          //   </span>
          // ),
        },
        {
          title: "Session Type",
          field: "dateObject",
          render: (rowData) => (
            <span>
              {/* {rowData["dateObject"]
                ? Object.getOwnPropertyNames(rowData["dateObject"])
                : "--"} */}
              {Object.getOwnPropertyNames(rowData["dateObject"]) == "now"
                ? "Now"
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "onGoing"
                ? "On Going"
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "later"
                ? "Later"
                : "--"}
            </span>
          ),
        },
        {
          title: "Session Schedule",
          field: "dateObject",
          render: (rowData) => (
            <span>
              {Object.getOwnPropertyNames(rowData["dateObject"]) == "now"
                ? JSON.stringify(rowData["dateObject"].now.date).replace(
                    /"/g,
                    ""
                  ) +
                  ", " +
                  JSON.stringify(rowData["dateObject"].now.start).replace(
                    /"/g,
                    ""
                  )
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "onGoing"
                ? JSON.stringify(
                    rowData["dateObject"].onGoing.frequency
                  ).replace(/"/g, "") +
                  " every " +
                  JSON.stringify(
                    rowData["dateObject"].onGoing.daysOfWeek
                  ).replace(/"/g, "") +
                  ", " +
                  JSON.stringify(rowData["dateObject"].onGoing.start).replace(
                    /"/g,
                    ""
                  ) +
                  "- " +
                  JSON.stringify(rowData["dateObject"].onGoing.end).replace(
                    /"/g,
                    ""
                  )
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "later"
                ? JSON.stringify(rowData["dateObject"].later.date).replace(
                    /"/g,
                    ""
                  ) +
                  ", " +
                  JSON.stringify(rowData["dateObject"].later.start).replace(
                    /"/g,
                    ""
                  ) +
                  " - " +
                  JSON.stringify(rowData["dateObject"].later.end).replace(
                    /"/g,
                    ""
                  )
                : ""}
            </span>
          ),
        },
        {
          title: "Time Zone",
          field: "dateObject",
          render: (rowData) => (
            <span>
              {/* {rowData["dateObject"]
                ? Object.getOwnPropertyNames(rowData["dateObject"])
                : "--"} */}
              {Object.getOwnPropertyNames(rowData["dateObject"]) == "now"
                ? rowData["dateObject"].now.cityTimezone
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "onGoing"
                ? rowData["dateObject"].onGoing.cityTimezone
                : Object.getOwnPropertyNames(rowData["dateObject"]) == "later"
                ? rowData["dateObject"].later.cityTimezone
                : "--"}
            </span>
          ),
        },
        {
          title: "Hours",
          field: "hoursTaken",
        },
        {
          title: "Cost",
          field: "totalCost",
        },
        {
          title: "Returns",
          field: "yaziCut",
        },

        {
          title: "Date Created",
          field: "postedAt",
          render: (rowData) => (
            <span>
              {rowData["postedAt"]
                ? moment(rowData["postedAt"]).format("MMM D, YYYY").toString()
                : "--"}
            </span>
          ),
        },
      ]}
      data={listOfTransactions}
      components={{
        Pagination: (props) => (
          <TablePagination
            {...props}
            count={totalCount}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            showFirstLastPageButtons={false}
            onChangePage={ChangePage}
          />
        ),
      }}
      onRowClick={(event, rowData, toggleDetailPanel) =>
        props.getDataFromChild(rowData)
      }
      onChangeRowsPerPage={(pageSize) => props.changeRowsPerPage(pageSize)}
      isLoading={isLoading}
    />
  );
}
