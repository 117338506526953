import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useWindowDimensions from "../utils/useWindowDimensions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import { generateUUID } from "../utils/uniqueKeyGenerator";
import swal from "sweetalert";

import { Formik } from "formik";
import * as Yup from "yup";
import firebase from "../components/firebase/FirebaseHelper";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export default function FormMyTeam(props) {
  const [adminLevel] = useState("reviewer");
  const [fullName] = useState("");
  const [email] = useState("");
  const [members, setMembers] = useState([]);
  const { width } = useWindowDimensions();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState([1]);
  const [password] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    showPassword === false ? setShowPassword(true) : setShowPassword(false);
  };
  useEffect(() => {
    //  checkTeamList()
  }, []);

  useEffect(() => {
    let didCancel = false;

    async function fetchMembers() {
      const uid = firebase.auth().currentUser.uid;

      firebase
        .firestore()
        .collection("web-admin")
        .doc(uid)
        .collection("teamMembers")
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            return;
          }

          const data = snapshot.docs.map((ref, index) => ({
            ...ref.data(),
          }));
          setMembers(data);
        });

      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("change data");
      }
    }

    fetchMembers();

    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitDataTest = async (values, { resetForm }) => {
    // firebase.functions().useEmulator("localhost", 5001)
    setIsLoading(true);
    const uid = firebase.auth().currentUser.uid;
    const memberInfo = {
      fullName: values.name,
      email: values.email,
      adminLevel: values.adminType,
      phoneNumber: values.mobile,
      password: values.password,
      addToTeam: true,
      // createdBy: "Chito Aldrin Velez",
      // dateCreated: timestamp()
    };

    // const memberInfoCollection = {
    //   fullName: values.name,
    //   email: values.email,
    //   adminLevel: values.adminType,
    //   phoneNumber: values.mobile,
    //   //    password: values.password,
    //   createdBy: uid,
    //   // dateCreated: timestamp()
    // };
    const createUserNewRef = firebase
      .functions()
      .httpsCallable("verify-createAdminUser");
    const result = await createUserNewRef(memberInfo);

    if (result.data.success) {
      swal(
        "Yazi Admin",
        result.data.message,
        "success"
      );
    } else {
      swal(
        "Yazi Admin",
        result.data.message,
        "error"
      );
    }
    setIsLoading(false);
    resetForm();
    console.log("this is the result", JSON.stringify(result.data));
    // const store = firebase.firestore();
    // const createUserRef = firebase
    //   .functions()
    //   .httpsCallable("createWebAdminOnCall");

    // const checkUserEmailRef = firebase
    //   .functions()
    //   .httpsCallable("checkUserAuthEmailOnCall");
    // const checkUserMobileRef = firebase
    //   .functions()
    //   .httpsCallable("checkUserAuthMobileOnCall");

    // const updateAuthUserRef = firebase
    //   .functions()
    //   .httpsCallable("updateAuthUserOnCall");
    // const resultEmail = await checkUserEmailRef(memberInfo);
    // const resultMobile = await checkUserMobileRef(memberInfo);

    // if (resultEmail.data && resultMobile.data) {
    //   if (resultEmail.data.uid === resultMobile.data.uid) {
    //     await store
    //       .collection("web-admin")
    //       .doc(resultEmail.data.uid)
    //       .set(memberInfoCollection, { merge: true })
    //       .then(async () => {
    //         alert("Account created! ");
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   } else {
    //     alert(
    //       "Email or Mobile number is not matching. Please check if email or number is already used."
    //     );
    //   }
    // } else {
    //   if (resultEmail.data) {
    //     const docUid = resultEmail.data.uid;
    //     await store
    //       .collection("web-admin")
    //       .doc(docUid)
    //       .set(memberInfoCollection, { merge: true })
    //       .then(async () => {
    //         alert("Account created! Please use same password as firebase");
    //         await updateAuthUserRef({
    //           uid: docUid,
    //           phoneNumber: memberInfo.phoneNumber,
    //           fullName: memberInfo.fullName,
    //           addToTeam: true,
    //         });
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   } else if (resultMobile.data) {
    //     const docUid = resultMobile.data.uid;
    //     await store
    //       .collection("web-admin")
    //       .doc(docUid)
    //       .set(memberInfoCollection, { merge: true })
    //       .then(async () => {
    //         alert(
    //           "Account created! Please use same password as firebase Mobile"
    //         );
    //         await updateAuthUserRef({
    //           uid: docUid,
    //           email: memberInfo.email,
    //           password: memberInfo.password,
    //           fullName: memberInfo.fullName,
    //           addToTeam: true,
    //         });
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   } else {
    //     //account and add collection
    //     await createUserRef(memberInfo)
    //       .then((response) => {
    //         alert("Account created!");
    //       })
    //       .catch((e) => alert("Something went wrong.", e));
    //   }
    // }
  };

  return (
    <Formik
      initialValues={{
        name: fullName,
        email: email,
        password: password,
        mobile: "",
        adminType: adminLevel,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please input full name."),
        email: Yup.string().email().required("Please Re-type email."),
        password: Yup.string()
          .required("Please input a strong password")
          .min(6, "Too Short"),
        mobile: Yup.string().required("Please input a your mobile number"),
        adminType: Yup.string().required("Select a role."),
      })}
      onSubmit={submitDataTest}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className={styles.root}>
          {isLoading ? (
            <div className={styles.modalLoading}>
              {
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    maxWidth: "80%",
                    height: "100%",
                    left: "15%",
                    right: "10%",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <Paper className={styles.loadingContainer}>
                    <div style={{ display: "flex", flex: 1, height: "20%" }}>
                      <Typography
                        display="block"
                        className={styles.secondLabels}
                        gutterBottom
                      >
                        Please Wait
                      </Typography>
                    </div>
                  </Paper>
                </div>
              }
            </div>
          ) : null}
          <Grid container item direction={"column"} xs={12} sm={6}>
            <Grid container item alignItems="center" lg={12}>
              <Grid item lg={11}>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                  gutterBottom
                >
                  Add Team Members
                </Typography>
              </Grid>
              {/* {width > 800 ? (
                <Grid item xs={12} lg={1} className={styles.gridItems}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Add user
                  </Button>
                </Grid>
              ) : null} */}
            </Grid>
            <Grid
              container
              item
              direction="column"
              lg={members.length ? 6 : 12}
            >
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{ padding: 8, paddingRight: 24, paddingBottom: 16 }}
                >
                  <Typography
                    variant="h6"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    Member Name
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      variant="outlined"
                      placeholder="Enter Name Here"
                      className={styles.secondInputFields}
                      InputLabelProps={{ shrink: true }}
                      name="name"
                      //   onChange={(e) => inputCheck(e, "name")}
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      error={errors.name && touched.name ? true : false}
                      helperText={
                        errors.name && touched.name ? errors.name : null
                      }
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: 18,
                        },
                      }}
                    />
                  </div>
                </Paper>
              </Grid>

              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{ padding: 8, paddingRight: 24, paddingBottom: 16 }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h6"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      Mobile Number
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                    >
                      (+15555550003)
                    </Typography>
                  </div>

                  <TextField
                    variant="outlined"
                    placeholder="Enter Mobile Number"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="mobile"
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.mobile && touched.mobile ? true : false}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: 18,
                      },
                    }}
                  />

                  <Typography
                    variant="h6"
                    display="block"
                    style={{ paddingTop: 12 }}
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    EMAIL
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Enter Email"
                    type="email"
                    className={styles.secondInputFields}
                    name="email"
                    //   onChange={(e) => inputCheck(e, "email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: 18,
                      },
                    }}
                  />

                  <Typography
                    variant="h6"
                    display="block"
                    style={{ paddingTop: 12 }}
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    PASSWORD
                  </Typography>
                  <TextField
                    placeholder="Enter Password"
                    autoComplete="off"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="password"
                    value={values.password}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password ? true : false}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Paper>
              </Grid>
              <Button
                style={{
                  width: "100%",
                  marginTop: 16,
                  padding: 12,
                  backgroundColor: "green",
                  color: "white",
                }}
                variant="contained"
                onClick={handleSubmit}
              >
                <strong>Add User</strong>
              </Button>

              {/* <Grid item className={styles.fieldItems}>
                                <Typography variant="h6" display="block" className={styles.secondLabels} gutterBottom>
                                    {members.length ? members.length.toString() : "none"}
                                </Typography>
                            </Grid> */}
              {/* <Grid item className={styles.fieldItems}>
                        <Typography variant="h6" display="block" className={styles.secondLabels} gutterBottom>
                            Add User
      </Typography>
                        <TextField  placeholder="Type Name Here"
                            className={styles.secondInputFields}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    fontSize: "calc(12px + 2.5vmin)"
                                }
                            }} />
                    </Grid> */}
              {/* {width < 800 ? (
                <Grid item xs={12} className={styles.secondAddUser}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className={styles.addUserButtonTwo}
                  >
                    Add user
                  </Button>
                </Grid>
              ) : null} */}
            </Grid>
            {members.length ? (
              <Grid container item direction="column" lg={6}>
                <Typography
                  variant="h6"
                  display="block"
                  className={styles.secondLabels}
                  style={{ marginBottom: 20 }}
                  gutterBottom
                >
                  My Team
                </Typography>
                <Paper style={{ maxHeight: "100%", overflow: "auto" }}>
                  <List dense>
                    {members.map((value) => {
                      const generated = generateUUID();
                      const checkId = value?.uid ? value.uid : generated;
                      const labelId = `checkbox-list-secondary-label-${checkId}`;

                      return (
                        <ListItem key={checkId} button>
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar n°${value.fullName}`}
                            //   src={`/static/images/avatar/${value + 1}.jpg`}
                            >
                              {value.fullName[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            id={checkId}
                            primary={`${value.fullName}`}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(value)}
                              checked={checked.indexOf(value) !== -1}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>

                {/* <Typography variant="h6" display="block" className={styles.secondLabels} gutterBottom>
                                    {members.length ? members.length.toString() : "none"}
                                </Typography> */}
              </Grid>
            ) : null}
          </Grid>
        </div>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      // backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
    },
    dropdownContainer: {
      width: "20%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(80,96,238,1)",
      fontWeight: 600,
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    loadingContainer: {
      flex: 1,
      height: "10%",
      maxWidth: "15%",
      alignSelf: "center",
      paddingTop: 20,

      [theme.breakpoints.down("sm")]: {
        height: "12%",
        maxWidth: "25%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "12%",
      },
      [theme.breakpoints.up("lg")]: {

        maxWidth: "8%",
      },
      [theme.breakpoints.up("xl")]: {
        height: "7%",
        maxWidth: "5%",
      },
    },
    modalLoading: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.3)",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      justifyContent: "center",
      zIndex: 1000,
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
