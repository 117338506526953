import {
  Button,
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router";
import { timestamp } from "../components/firebase/FirebaseHelper";
import MaterialUITableSubjects from "../components/MaterialUITableSubjects";
import firebase from "../components/firebase/FirebaseHelper";
import moment from "moment";
import no_profile from "../images/no_profile.png";

const headerHeight = 1325;
const headerHeightSingle = 100;
const subjectObject = {
  label: "Lorem Ipsum",
  key: "lorem_ipsum",
  summary:
    "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
  addedBy: "Mr. Lorem Ipsum",
  fixCreatedOn: timestamp(),
};

const ColoredRadio = withStyles({
  root: {
    color: yellow[700],
    "&$checked": {
      color: yellow[900],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function Subjects() {
  let history = useHistory();
  let { path } = useRouteMatch();
  const headerHeight = 1325;
  const styles = useStyles();
  const [selectedView, setSelectedView] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState(subjectObject);
  const [kindOfSubject, setKindOfSubject] = useState("courses-mentor");
  //tables data
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [firstSubject, setFirstSubject] = useState({});
  const [lastSubject, setLastSubject] = useState({});
  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    let didCancel = false;

    async function fetchCoursesMentorAvailable() {
      //GET ALL TOTAL
      await firebase
        .firestore()
        .collection(kindOfSubject) //
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            setTotalCount(0);
            setNumberOfPages(0);
            return;
          }
          const listCount = querySnapshot.docs.length;
          const computedTotalPage = Math.ceil(listCount / rowsPerPage);
          setTotalCount(listCount);
          setNumberOfPages(computedTotalPage);
        });
      // GET INITIAL USERS and FIRST AND LAST for Pagination
      await firebase
        .firestore()
        .collection(kindOfSubject) //
        // .orderBy("dateCreated", "desc")
        .limit(rowsPerPage)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            return;
          }
          const data = querySnapshot.docs.map((ref, index) => ({
            id: index,
            fixedDateCreated: ref.data().dateCreated
              ? moment(ref.data().dateCreated.toDate())
                  .format("MMM D, YYYY")
                  .toString()
              : "--",
            ...ref.data(),
          }));
          const firstVisible = querySnapshot.docs[0];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const fixedData = await FixData(data);

          //set initial users here
          setFirstSubject(firstVisible);
          setLastSubject(lastVisible);
          console.log("Subject List Data: " + data);
          setSubjectList(fixedData);
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("change data");
      }
    }

    fetchCoursesMentorAvailable();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [kindOfSubject, rowsPerPage]);

  const FixData = async (data) => {
    const fixedData = await Promise.all(
      data.map(async (data) => {
        let avatar = no_profile;
        const imageRef = firebase
          .storage()
          .refFromURL("gs://ancient-tractor-277102-signup")
          .child(`${data.id}/profilePhoto`);
        await imageRef.getDownloadURL().then(onResolve, onReject);

        function onResolve(foundURL) {
          avatar = foundURL;
        }

        function onReject(error) {
          console.log(error.code);
        }
        let _fixedData = {
          avatar,
          ...data,
        };
        return _fixedData;
      })
    );
    return fixedData;
  };

  const LoadNextData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection(kindOfSubject) //
      // .where(kindOfSubject)
      // .orderBy("dateCreated", "desc")
      .startAfter(lastSubject)
      .limit(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
                .format("MMM D, YYYY")
                .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        setFirstSubject(firstVisible);
        setLastSubject(lastVisible);
        setSubjectList(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LoadPrevData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection(kindOfSubject) //
      // .orderBy("dateCreated", "desc")
      .endBefore(firstSubject)
      .limitToLast(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
                .format("MMM D, YYYY")
                .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setFirstSubject(firstVisible);
        setLastSubject(lastVisible);
        setSubjectList(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSubject = (event) => {
    setKindOfSubject(event.target.value);
  };
  const handleChange = (value) => {
    console.log(value);
  };

  const ChangeSize = (pageSize) => {
    setRowsPerPage(pageSize);
    setIsLoading(true);
  };

  const getDataFromChild = (row) => {
    setSelectedSubject(row);
    // //  props.selectedData(row)
    // //  setSelectedUserURL("/user/" + `${row.id}`)
    // history.push({
    //   pathname: `${path}/${kindOfSubject}`,
    //   //   search: `?${row.id}`,
    //   state: { data: row },
    // });
  };

  const ChangePage = async (e, pageNumber) => {
    //check if previous of next
    if (pageNumber > currentPage) {
      await LoadNextData();
    } else if (pageNumber < currentPage) {
      await LoadPrevData();
    }
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.tables}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Subjects</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={kindOfSubject}
          onChange={handleChangeSubject}
        >
          <FormControlLabel
            value="courses-mentor"
            control={<ColoredRadio />}
            label="Mentor"
          />
          <FormControlLabel
            value="courses-tutor"
            control={<ColoredRadio />}
            label="Tutors"
          />
        </RadioGroup>

        <Button
          onClick={() => history.push("subjects/add-subject")}
          variant="contained"
          color="primary"
          style={{ marginBottom: 16 }}
        >
          Add New Subject
        </Button>
      </FormControl>
      {/* <MaterialUITable getDataFromChild={getDataFromChild} userType={kindOfUser} key={generateUUID()} /> */}
      <MaterialUITableSubjects
        getDataFromChild={getDataFromChild}
        listOfSubjects={subjectList}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={ChangeSize}
        changePage={ChangePage}
      />
      <div className={styles.tableFooter} />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // display: 'flex',
      // width: "92vw",
      // paddingRight: "5vw",
      // flexDirection: "column"
    },
    tables: {
      flex: 1,
    },
    tableHeaders: {
      display: "flex",
      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Purple",
      },
    },
    tableFooter: {
      padding: theme.spacing(2),
    },
    tableHeaderNumber: {
      backgroundColor: "F29A33",
      width: "100px",
    },
    tableHeaderText1: {
      backgroundColor: "Green",
      width: "200px",
    },
    tableHeaderText2: {
      backgroundColor: "White",
      flex: 1,
    },
    tablePadding: {
      padding: theme.spacing(2),
    },
    singleViewHeader: {
      display: "flex",

      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Purple",
      },
    },
    avatar: {
      margin: "15px",
      width: "auto",
      height: "auto",
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    gridMainContainer: {
      marginTop: "0.25%",
      backgroundColor: "Purple",
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      borderRadius: "5px",
      backgroundColor: "White",

      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "84.5vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "85.5vw",
      },
      [theme.breakpoints.up("lg")]: {
        width: "86.3vw",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      height: "150px",
    },
    bioContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      //     margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
