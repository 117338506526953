import React, { useEffect } from "react";
export default function Revenue() {
  useEffect(() => {
    console.log("Revenue Screen Started");
  }, []);
  return (
    <div>
      <h2>Revenue Page</h2>
    </div>
  );
}
