import React, { useState, useEffect } from "react";
import MaterialUITableV2 from "../../components/MaterialUITableV2";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// import SummaryHeader from "../../components/SummaryHeader"
import FormViewLearners from "../../components/FormViewLearners";
import firebase, {
  timestamp,
  union,
} from "../../components/firebase/FirebaseHelper";
import { generateUUID } from "../../utils/uniqueKeyGenerator";
import {
  //   BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  useHistory,
  useRouteMatch,
  // useLocation
} from "react-router-dom";
import { yellow } from "@material-ui/core/colors";
import moment from "moment";
import no_profile from "../../images/no_profile.png";
const headerHeight = 1325;
const headerHeightSingle = 100;
const userObject = {
  avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
  email: "george.bluth@reqres.in",
  first_name: "George",
  id: 1,
  last_name: "Bluth",
};
const ColoredRadio = withStyles({
  root: {
    color: yellow[700],
    "&$checked": {
      color: yellow[900],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default function Learners(props) {
  const styles = useStyles();
  const [selectedView, setSelectedView] = useState(0);
  const [selectedUser, setSelectedUser] = useState(userObject);
  const [kindOfUser, setKindOfUser] = useState("student");
  //tables data
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [firstUser, setFirstUser] = useState({});
  const [lastUser, setLastUser] = useState({});
  const [userList, setUserList] = useState([]);

  const [yorcList, setYorcList] = useState([]);
  let { path } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    let didCancel = false;

    async function fetchUsersAvailable() {
      //GET ALL TOTAL
      await firebase
        .firestore()
        .collection("users") //
        .where("userType", "==", kindOfUser) // future check if approved or not
        .orderBy("dateCreated", "desc")
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            return;
          }
          const listCount = querySnapshot.docs.length;
          const computedTotalPage = Math.ceil(listCount / rowsPerPage);
          setTotalCount(listCount);
          setNumberOfPages(computedTotalPage);
        });
      // GET INITIAL USERS and FIRST AND LAST for Pagination
      await firebase
        .firestore()
        .collection("users") //
        .where("userType", "==", kindOfUser) // future check if approved or not
        .orderBy("dateCreated", "desc")
        // .limit(rowsPerPage)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            //  setIsLoading(false)
            return;
          }
          const data = querySnapshot.docs.map((ref, index) => ({
            id: index,
            fixedDateCreated: ref.data().dateCreated
              ? moment(ref.data().dateCreated.toDate())
                .format("MMM D, YYYY")
                .toString()
              : "--",
            ...ref.data(),
          }));
          const firstVisible = querySnapshot.docs[0];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const fixedData = await FixData(data);
          //set initial users here
          setFirstUser(firstVisible);
          setLastUser(lastVisible);
          setUserList(fixedData);
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("change data");
      }
    }

    fetchUsersAvailable();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [kindOfUser, rowsPerPage]);

  useEffect(() => {
    let didCancel = false;

    async function fetchYORCCourses() {
      console.log("We are called yorc")
      try {
        await firebase
          .firestore()
          .collection("e-learning-courses")
          //       .where("category", "==", "yazi")
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("empty data yorc");
              return;
            }
            const yorcData = querySnapshot.docs.map((ref, index) => {
              return {
                "id": ref.id, ...ref.data()
              }
            }
            );
            setYorcList(yorcData)

          });
        if (!didCancel) {
          // Ignore if we started fetching something else
          console.log("change data yorc");
        }
      } catch (err) {
        console.log("error in fetch yorc");
        console.log(err);
      }
    }

    fetchYORCCourses();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  const LoadNextData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection("users") //
      .where("userType", "==", kindOfUser) // future check if approved or not
      .orderBy("dateCreated", "desc")
      .startAfter(lastUser)
      .limit(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
              .format("MMM D, YYYY")
              .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const fixedData = await FixData(data);

        setFirstUser(firstVisible);
        setLastUser(lastVisible);
        setUserList(fixedData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const LoadPrevData = async () => {
    setIsLoading(true);
    await firebase
      .firestore()
      .collection("users") //
      .where("userType", "==", kindOfUser) // future check if approved or not
      .orderBy("dateCreated", "desc")
      .endBefore(firstUser)
      .limitToLast(rowsPerPage)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          //  setIsLoading(false)
          return;
        }
        const data = querySnapshot.docs.map((ref, index) => ({
          id: index,
          fixedDateCreated: ref.data().dateCreated
            ? moment(ref.data().dateCreated.toDate())
              .format("MMM D, YYYY")
              .toString()
            : "--",
          ...ref.data(),
        }));
        const firstVisible = querySnapshot.docs[0];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        const fixedData = await FixData(data);
        setFirstUser(firstVisible);
        setLastUser(lastVisible);
        setUserList(fixedData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const FixData = async (data) => {
    const fixedData = await Promise.all(
      data.map(async (data) => {
        let avatar = no_profile;
        const imageRef = firebase
          .storage()
          .refFromURL("gs://ancient-tractor-277102-signup")
          .child(`${data.id}/profilePhoto`);
        await imageRef.getDownloadURL().then(onResolve, onReject);

        function onResolve(foundURL) {
          avatar = foundURL;
        }

        function onReject(error) {
          console.log(error.code);
        }
        let _fixedData = {
          avatar,
          ...data,
        };
        return _fixedData;
      })
    );
    return fixedData;
  };
  const ChangePage = async (e, pageNumber) => {
    //check if previous of next
    if (pageNumber > currentPage) {
      await LoadNextData();
    } else if (pageNumber < currentPage) {
      await LoadPrevData();
    }
    setCurrentPage(pageNumber);
  };
  const ChangeSize = (pageSize) => {
    setRowsPerPage(pageSize);
    setIsLoading(true);
  };
  const getDataFromChild = (row) => {
    setSelectedUser(row);

    //  props.selectedData(row)
    //  setSelectedUserURL("/user/" + `${row.id}`)
    history.push({
      pathname: `${path}/${kindOfUser}`,
      //   search: `?${row.id}`,
      state: { data: row },
    });
  };
  const handleChangeUser = (event) => {
    setKindOfUser(event.target.value);
  };
  const handleChange = (value) => {
    console.log(value);
  };
  const changeStatus = (documentId, newStatus, action) => {
    const uid = firebase.auth().currentUser.uid;
    const store = firebase.firestore();
    const ref = store.collection("users").doc(documentId);
    const usersRef = store.collection("web-admin").doc(uid);

    try {
      usersRef
        .get()
        .then((firestoreDocument) => {
          if (!firestoreDocument.exists) {
            alert("User does not exist anymore.");
            return;
          }
          const user = firestoreDocument.data();
          ref
            .set(
              {
                approvalStatus: newStatus,
                reviewerData: {
                  reviewHistory: union({
                    dateReview: moment().toString(),
                    reviewer: user.fullName,
                    reviewAction: action,
                  }),
                },
              },
              { merge: true }
            )
            .then(
              (response) => {
                // history.push({
                //     pathname: `${path}/${type}/edit`,
                // })
                history.goBack();
              },
              (error) => {
                console.log("this is error", error);
                //    setLoadSchedules(false)
              }
            );
        })
        .catch((error) => {
          alert(error);
        });
    } catch (e) {
      console.log("error", e);
    }
  };
  const summaryView = (
    <div className={styles.tables}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Users</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={kindOfUser}
          onChange={handleChangeUser}
        >
          <FormControlLabel
            value="parent"
            control={<ColoredRadio />}
            label="Parent"
          />
          <FormControlLabel
            value="student"
            control={<ColoredRadio />}
            label="Student"
          />
        </RadioGroup>
      </FormControl>
      {/* <MaterialUITable getDataFromChild={getDataFromChild} userType={kindOfUser} key={generateUUID()} /> */}
      <MaterialUITableV2
        getDataFromChild={getDataFromChild}
        listOfUsers={userList}
        isLoading={isLoading}
        totalCount={totalCount}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={ChangeSize}
        changePage={ChangePage}
      />
      <div className={styles.tableFooter} />
    </div>
  );

  return (
    <Switch>
      <div className={styles.root}>
        <Route path={`${path}`} exact>
          {summaryView}
        </Route>
        <Route
          path={`${path}/${kindOfUser}`}
          exact
          render={() => <FormViewLearners changeStatus={changeStatus} yorcList={yorcList} />}
        />

        {/* {
                    selectedView === 0 ? summaryView : singleView(selectedUser)
                } */}
      </div>
    </Switch>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // display: 'flex',
      // width: "92vw",
      // paddingRight: "5vw",
      // flexDirection: "column"
    },
    tables: {
      flex: 1,
    },
    tableHeaders: {
      display: "flex",
      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeight}px)`,
        backgroundColor: "Purple",
      },
    },
    tableFooter: {
      padding: theme.spacing(2),
    },
    tableHeaderNumber: {
      backgroundColor: "F29A33",
      width: "100px",
    },
    tableHeaderText1: {
      backgroundColor: "Green",
      width: "200px",
    },
    tableHeaderText2: {
      backgroundColor: "White",
      flex: 1,
    },
    tablePadding: {
      padding: theme.spacing(2),
    },
    singleViewHeader: {
      display: "flex",

      // height: '10%',
      // marginTop: theme.spacing(8),
      marginBottom: "1vh",
      // backgroundColor: "Purple",
      [theme.breakpoints.down("sm")]: {
        height: "90px",
        backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        height: `calc(100% - ${headerHeightSingle}px)`,
        backgroundColor: "Purple",
      },
    },
    avatar: {
      margin: "15px",
      width: "auto",
      height: "auto",
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    gridMainContainer: {
      marginTop: "0.25%",
      backgroundColor: "Purple",
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      borderRadius: "5px",
      backgroundColor: "White",

      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        width: "84.5vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "85.5vw",
      },
      [theme.breakpoints.up("lg")]: {
        width: "86.3vw",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      height: "150px",
    },
    bioContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      //     margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
