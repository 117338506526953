import React, { useState } from "react";
import { Formik, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function QuizzesForm(props) {
  const styles = useStyles();
  const handleSumbitQuiz = async (lesson, { resetForm }) => {
    props.submitQuiz(lesson, { resetForm });
  };
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
    >{({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      const fixedError = error && error.length > 1 ? error : null;
      return touch && error ? (
        <Typography
          style={{
            fontSize: 12,
            fontStyle: "italic",
            paddingLeft: 25,
            color: "red",
          }}
        >
          {fixedError}
        </Typography>
      ) : null;
    }}
    </Field>
  );
  return (
    <Formik
      initialValues={{
        questions: [
          {
            question: "",
            answer: "",
            explanation: "",
            choices: [],
          },
        ],
      }}
      validationSchema={Yup.object().shape({
        questions: Yup.array()
          .of(
            Yup.object().shape({
              question: Yup.string().required("Please enter a question."),
              answer: Yup.string().required("Please enter an answer"),
              explanation: Yup.string().required("Please enter an explanation"),
              choices: Yup.array()
                .of(
                  Yup.string()
                    .trim()
                    .min(2, "You can explain it much better.")
                    .required("Enter a choice")
                )
                .required("Please add choices")
                .min(2, "Please add atleast two."),
            })
          )
          .required("Must have one question atleast")
          .min(3, "Minimum of 3 Questions"),
      })}
      onSubmit={handleSumbitQuiz}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Paper className={styles.root}>
          <FontAwesomeIcon
            icon={faTimes}
            size="3x"
            style={{
              borderRadius: 60,
              position: "absolute",
              color: "red",
              fontWeight: 600,
              bottom: "85%",
              right: 0,
              marginRight: "2%",
              zIndex: 1000,
            }}
            onClick={() => props.manageModal(false)}
          />
          <Grid container>
            <FieldArray
              name="questions"
            >{(arrayHelpers) => {
              const arrayErrors = (errors) => {
                return typeof errors?.choices === "string" ? (
                  <Typography
                    style={{
                      fontSize: 12,
                      fontStyle: "italic",
                      paddingLeft: 25,
                      color: "red",
                    }}
                  >
                    {errors.choices}
                  </Typography>
                ) : null;
              };
              return (
                <Grid container style={{ padding: 20, marginTop: 16 }}>
                  <Grid container item direction="row">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      item
                      className={styles.headerAddQuestion}
                    >
                      <Grid item>
                        <Button
                          style={{ padding: 12 }}
                          variant="contained"
                          // className={styles.addQuestion}
                          color="primary"
                          onClick={() =>
                            arrayHelpers.push({
                              question: "",
                              answer: "",
                              explanation: "",
                              choices: [],
                            })
                          }
                        >
                          <strong>Add a question</strong>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          // className={styles.submitQuiz}
                          style={{
                            marginLeft: 16,
                            backgroundColor: "green",
                            color: "white",
                            padding: 12,
                          }}
                        >
                          <strong>Submit Quiz</strong>
                        </Button>
                      </Grid>
                    </Grid>
                    {/* <Grid container item className={styles.headerSubmitQuiz}>
                                            <Button variant="contained" onClick={handleSubmit} className={styles.submitQuiz} >
                                                Submit Quiz
                                        </Button>
                                        </Grid> */}
                  </Grid>
                  {errors?.questions &&
                    typeof errors?.questions === "string" ? (
                    <Typography
                      style={{
                        fontSize: "calc(12px + 1.1vmin)",
                        fontStyle: "italic",
                        paddingLeft: 25,
                        color: "red",
                      }}
                    >
                      {errors.questions}
                    </Typography>
                  ) : null}
                  <Grid container item style={{ height: "90%" }}>
                    {values.questions.map((question, index) => (
                      <Grid
                        container
                        item
                        key={`question-${index}`}
                        className={styles.questionForm}
                      >
                        <Grid container item direction="row" spacing={2}>
                          <Grid item className={styles.questionNumberHeader}>
                            <Typography
                              style={{ marginTop: 32 }}
                              variant="h5"
                              display="block"
                              className={styles.questionLabels}
                            >
                              {`Question - ${index + 1}`}
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            className={styles.removeQuestionContainer}
                          >
                            <Button
                              variant="contained"
                              onClick={() => arrayHelpers.remove(index)}
                              // className={styles.removeQuestionButt}
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                marginLeft: 24,
                              }}
                            >
                              <strong>Remove Question</strong>
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          direction="column"
                          style={{
                            marginTop: 16,
                          }}
                        // className={styles.questionFormContents}
                        >
                          <Grid container item direction="column">
                            <Typography
                              variant="h6"
                              display="block"
                              className={styles.secondLabels}
                            >
                              Question
                            </Typography>
                            {/* <Field name={`questions.${index}.question`} /> */}
                            <TextField
                              placeholder="Add a question."
                              className={styles.secondInputFields}
                              name={`questions.${index}.question`}
                              value={question.question}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{ shrink: true }}
                              multiline={true}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  padding: 10,
                                  backgroundColor: "rgba(0,0,0,0.1)",
                                  fontSize: "calc(6px + 2vmin)",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`questions.${index}.question`}
                            />
                          </Grid>
                          <Grid container item direction="column">
                            <Typography
                              variant="h6"
                              display="block"
                              className={styles.secondLabels}
                            >
                              Answer
                            </Typography>
                            {/* <Field name={`questions.${index}.answer`} /> */}
                            <TextField
                              placeholder="Enter the correct Answer"
                              className={styles.secondInputFields}
                              name={`questions.${index}.answer`}
                              value={question.answer}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline={true}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  padding: 10,
                                  backgroundColor: "rgba(0,0,0,0.1)",
                                  fontSize: "calc(6px + 2vmin)",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`questions.${index}.answer`}
                            />
                          </Grid>
                          <Grid container item direction="column">
                            <Typography
                              variant="h6"
                              display="block"
                              className={styles.secondLabels}
                            >
                              Explanation
                            </Typography>
                            {/* <Field name={`questions.${index}.explanation`} /> */}
                            <TextField
                              placeholder="Enter the explanation of the correct answer"
                              className={styles.secondInputFields}
                              name={`questions.${index}.explanation`}
                              //  onChange={(e) => inputCheck(e, "password")}
                              value={question.explanation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline={true}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  padding: 10,
                                  backgroundColor: "rgba(0,0,0,0.1)",
                                  fontSize: "calc(6px + 2vmin)",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`questions.${index}.explanation`}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            direction="column"
                            style={{ marginTop: 16 }}
                          >
                            <Typography
                              variant="h6"
                              display="block"
                              className={styles.secondLabels}
                            >
                              Choices
                            </Typography>
                            <Grid container item direction="column">
                              <FieldArray
                                name={`questions.${index}.choices`}
                                validateOnChange={false}
                              >{(arrayHelpers1) => {
                                return (
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    spacing={2}
                                    className={styles.addChoicesContainer}
                                  >
                                    <Grid
                                      container
                                      item
                                      className={
                                        styles.addChoiceButtContainer
                                      }
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                          arrayHelpers1.push("")
                                        }
                                        style={{ padding: 12 }}
                                      // className={styles.addChoiceButt}
                                      >
                                        <strong>Add Choices</strong>
                                      </Button>
                                    </Grid>

                                    {values.questions[index].choices.map(
                                      (choice, idx) => (
                                        <Grid
                                          container
                                          item
                                          direction="column"
                                          key={`choice-${idx}`}
                                        >
                                          <Grid
                                            container
                                            item
                                            direction="column"
                                            spacing={2}
                                          >
                                            <Grid container item>
                                              <Grid container item>
                                                <Typography
                                                  variant="h6"
                                                  display="block"
                                                  className={
                                                    styles.secondLabels
                                                  }
                                                >
                                                  {`Choice: ${idx + 1}`}
                                                </Typography>
                                              </Grid>
                                              {/* <Field name={`questions.${index}.choices.${idx}`} /> */}
                                              <Grid container item>
                                                <Grid
                                                  container
                                                  item
                                                  className={
                                                    styles.choicesInput
                                                  }
                                                >
                                                  <TextField
                                                    placeholder="Enter a choice."
                                                    name={`questions.${index}.choices.${idx}`}
                                                    className={
                                                      styles.choicesInputfield
                                                    }
                                                    value={choice}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    multiline={true}
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    InputProps={{
                                                      disableUnderline: true,
                                                      style: {
                                                        paddingLeft: 10,
                                                        overflow: "auto",
                                                        fontSize:
                                                          "calc(6px + 2vmin)",
                                                      },
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid
                                                  container
                                                  item
                                                  className={
                                                    styles.removeChoiceButton
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTimes}
                                                    variant="contained"
                                                    onClick={() =>
                                                      arrayHelpers1.remove(
                                                        idx
                                                      )
                                                    }
                                                  // className={
                                                  //   styles.choicesButtons
                                                  // }
                                                  >
                                                    Remove choice
                                                  </FontAwesomeIcon>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item>
                                              <ErrorMessage
                                                name={`questions.${index}.choices.${idx}`}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                );
                              }}
                              </FieldArray>
                            </Grid>

                            {errors && errors?.questions
                              ? arrayErrors(errors.questions[index])
                              : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            }}
            </FieldArray>
          </Grid>
        </Paper>
      )}
    </Formik>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: "2%",
      paddingBottom: "5%",
      display: "flex",
      position: "absolute",
      minWidth: "80%",
      height: "100%",
      left: "15%",
      right: "10%",
      overflowX: "hidden",
      overflowY: "auto",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "10%",
      },
    },
    parentGrid: {
      flex: 1,
      padding: 20,

      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: "5%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10%",
      },
    },
    choicesInputfield: {
      width: "100%",
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
    },
    questionNumberHeader: {},

    questionLabels: {
      color: "rgba(40,48,120,1)",
      fontWeight: "bold",
      paddingLeft: 25,
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
      minHeight: 60,
      margin: 10,
    },
    questionForm: {
      paddingRight: "3%",
    },
    questionFormContents: {
      marginLeft: "3%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    questionDivider: {
      minHeight: 10,
      maxHeight: 10,
      backgroundColor: "rgba(255,150,150,0.5)",
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.down("sm")]: {
        minHeight: 5,
        maxHeight: 5,
        backgroundColor: "rgba(255,150,150,0.5)",
        marginTop: 30,
        marginBottom: 15,
        justifyContent: "center",
      },
    },
    submitQuiz: {
      //width: "0%",
      minHeight: 60,
      margin: 10,
      backgroundColor: "rgba(110,225,110,1)",
      color: "white",
    },
    addQuestion: {
      minHeight: 60,
      margin: 10,
      backgroundColor: "rgba(100,150,250,1)",
      color: "white",
    },
    choicesInput: {
      marginLeft: 50,

      width: "85%",
      backgroundColor: "rgba(0,0,0,0.1)",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 25,
      },
      [theme.breakpoints.down("lg")]: {
        width: "80%",
      },
    },
    removeChoiceButton: {
      width: "10%",
      justifyContent: "center",
      alignContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "10%",
        justifyContent: "center",
        marginTop: 25,
      },
      [theme.breakpoints.up("md")]: {
        width: "10%",
        marginLeft: "1%",
        justifyContent: "center",
        alignContent: "center",
      },
    },
    removeQuestionContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    removeQuestionButt: {
      backgroundColor: "rgba(220,100,100,1)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },

    addChoiceButtContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    addChoiceButt: {
      backgroundColor: "rgba(100,150,250,1)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    choicesButtons: {
      backgroundColor: "rgba(205,100,100,1)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    addChoicesContainer: {
      marginLeft: "2%",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    headerAddQuestion: {
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    headerSubmitQuiz: {
      width: "20%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(40,48,120,1)",
      //      fontWeight: 600,
      fontSize: "calc(6px + 2vmin)",
    },
    lessonsContainer: {
      width: "100%",
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    dropdownContainer: {
      width: "100%",
      paddingLeft: 25,
      paddingRight: 25,
      // [theme.breakpoints.down('sm')]: {
      //     width: '100%'
      // }
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
export default QuizzesForm;
