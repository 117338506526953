import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Deferred } from "../utils/DefferedPromise";
import { Formik, Field, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import firebase, { taskEvents } from "./firebase/FirebaseHelper";

import { useFilePicker } from "use-file-picker";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
function AddELearningCourse(props) {
  const [author] = useState("");
  const [title] = useState("");
  const [description] = useState("");
  const [teaser] = useState("");

  const [price] = useState(0);
  const [takeAways] = useState([]);
  const [category] = useState("");
  const [progress, setProgress] = useState(0);
  const [productIdAndroid, setProductIdAndroid] = useState("");
  const [productIdIOS, setProductIdIOS] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState("");

  const { width } = useWindowDimensions();
  const styles = useStyles();

  const [openFileSelector, teaserData] = useFilePicker({
    multiple: false,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    // accept: '.ics,.pdf',
    accept: [".mp4", ".m4v"],
  });

  const [openFileSelectorThumbnail, thumbnailData] = useFilePicker({
    multiple: false,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".jpg", ".jpeg"],
    imageSizeRestrictions: {
      minWidth: 1280,
      maxWidth: 1280,
      minHeight: 720,
      maxHeight: 720,
    },
  });
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        const fixedError = error && error.length > 1 ? error : null;
        return touch && error ? (
          <Typography
            style={{
              fontSize: 12,
              fontStyle: "italic",
              paddingLeft: 25,
              color: "red",
            }}
          >
            {fixedError}
          </Typography>
        ) : null;
      }}
    />
  );
  const checkVideoLength = async (videoData) => {
    let promise = new Deferred();
    let _videoData;
    var dur;
    var readableDur;
    if (videoData && videoData.plainFiles.length > 0) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(videoData.plainFiles[0]);

      vid.src = fileURL;
      //TODO get max duration
      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = function () {
        dur = this.duration;

        const secondsToMinutes = (seconds) =>
          Math.floor(seconds / 60) +
          ":" +
          ("0" + Math.floor(seconds % 60)).slice(-2);
        readableDur = secondsToMinutes(dur);
        _videoData = updateDuration(dur, readableDur);
        setTimeout(() => {
          promise.resolve(_videoData);
        }, 1000);
      };

      return promise;
    }
  };
  const updateDuration = (duration, readableDuration) => {
    return { duration, readableDuration };
  };

  const uploadVideo = async (longString, lessonId) => {
    const storage = firebase.storage();

    let promise = new Deferred();
    // var ref = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/1080ppp.mp4`);
    // var uploadVideoTask = ref.putString(teaserData.filesContent[0].content, 'data_url')
    var ref = storage
      .refFromURL("gs://ancient-tractor-277102-e-learning")
      .child(`${lessonId}/1080.mp4`);

    var uploadVideoTask = ref.putString(longString, "data_url");
    uploadVideoTask.on(
      taskEvents.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = roundToTwo(progress);
        console.log("Upload is " + progress + "% done");
        setUploadingText(`Uploading video: ${progress}% done`);
        setProgress(Number(progress));
        //    setProgress(progress)
        switch (snapshot.state) {
          case taskEvents.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case taskEvents.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // uploadVideoTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //     alert("Teaser Video upload Complete!")
        // });
        console.log("Video Upload complete");
        promise.resolve(true);
      }
    );
    return promise;
  };

  const uploadThumbnail = async (longString, lessonId) => {
    const storage = firebase.storage();

    let promise = new Deferred();

    // var refThumb = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/thumbnail1111.jpg`);
    // var uploadThumbnailTask = refThumb.putString(thumbnailData.filesContent[0].content, 'data_url')
    var refThumb = storage
      .refFromURL("gs://ancient-tractor-277102-e-learning")
      .child(`${lessonId}/thumbnail.jpg`);

    var uploadThumbnailTask = refThumb.putString(longString, "data_url");
    uploadThumbnailTask.on(
      taskEvents.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = roundToTwo(progress);
        setUploadingText(`Uploading thumbnail : ${progress}% done`);
        setProgress(Number(progress));
        switch (snapshot.state) {
          case taskEvents.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case taskEvents.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // uploadThumbnailTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //     alert("Thumbnail Upload complete");
        // });
        console.log("Thumbnail Upload complete");
        promise.resolve(true);
      }
    );

    return promise;
  };

  const submitData = async (values, { resetForm }) => {
    const store = firebase.firestore();
    let videoDone = false;
    let thumbnailDone = false;
    let _vidData;

    if (
      teaserData.filesContent.length === 0 ||
      thumbnailData.filesContent.length === 0
    ) {
      alert("Please add a video and thumbnail.");
      return;
    }
    let productId = {
      android: productIdAndroid,
      ios: productIdIOS,
    };
    setIsUploading(true);
    var doc = store.collection("e-learning-courses").doc();
    _vidData = await checkVideoLength(teaserData);
    const lessonDuration = _vidData?.duration ? Number(_vidData.duration) : 0;
    const lessonReadableDuration = _vidData?.readableDuration
      ? _vidData.readableDuration
      : "00:00";
    doc
      .set({ ...values, lessonDuration, lessonReadableDuration, productId })
      .then(async () => {
        const lessonId = doc.id;
        try {
          videoDone = await uploadVideo(
            teaserData.filesContent[0].content,
            lessonId
          );
          thumbnailDone = await uploadThumbnail(
            thumbnailData.filesContent[0].content,
            lessonId
          );
          setIsUploading(false);
          teaserData.clear();
          thumbnailData.clear();
          resetForm();
        } catch (e) { }
        //   resetForm();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const errorChecker = (error) => {
    console.log("this is the error", error);
  };
  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  return (
    <Formik
      initialValues={{
        author,
        title,
        description,
        teaser,
        thumbnail: "",
        price,
        takeAways,
        category,
      }}
      validationSchema={Yup.object().shape({
        author: Yup.string().required("Please input author's name."),
        title: Yup.string().required("Please input the title of this course."),
        description: Yup.string()
          .required("Please input a description")
          .min(30, "Too Short"),
        category: Yup.string().required("Select a category."),
        price: Yup.number().required("Please input a price."),
        takeAways: Yup.array()
          .of(
            Yup.string()
              .trim()
              .min(10, "You can explain it much better.")
              .required("Enter a take away")
          )
          .required("Please add a take away")
          .min(3, "Please add atleast three."),
      })}
      onSubmit={submitData}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className={styles.root}>
          {isUploading ? (
            <div className={styles.modalLoading}>
              {
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    maxWidth: "80%",
                    height: "100%",
                    left: "15%",
                    right: "10%",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <Paper className={styles.loadingContainer}>
                    <div style={{ display: "flex", flex: 3, height: "20%" }}>
                      <Typography
                        display="block"
                        className={styles.secondLabels}
                        gutterBottom
                      >
                        {uploadingText}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 6,
                        height: "80%",
                        paddingRight: 20,
                        paddingLeft: 20,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          height: "1.5vh",
                          width: "100%",
                          alignSelf: "center",
                          borderRadius: 25,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "rgba(100,200,100,1)",
                            width: `${progress}%`,
                            borderRadius: 25,
                          }}
                        />
                      </div>
                    </div>
                  </Paper>
                </div>
              }
            </div>
          ) : null}
          <Grid container direction="row" className={styles.parentGrid}>
            <Grid container item direction={"column"} xs={6} ls={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                  gutterBottom
                >
                  Add a Subject
                </Typography>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{
                    paddingTop: 16,
                    marginBottom: 16,
                    paddingRight: 24,
                    paddingBottom: 8,
                  }}
                >
                  <FormControl className={styles.dropdownContainer}>
                    <Select
                      value={values.category}
                      variant="outlined"
                      //  onChange={(e) => setAdminLevel(e.target.value)}
                      name="category"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayEmpty
                      disableUnderline
                      className={styles.dropdownStyle}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>Select an e-learning category</em>
                      </MenuItem>
                      <MenuItem value={"partner"}>Partner</MenuItem>
                      <MenuItem value={"steam"}>Steam</MenuItem>
                      <MenuItem value={"yazi"}>Yazi</MenuItem>
                      <MenuItem value={"yorc"}>YORC</MenuItem>
                    </Select>
                    <FormHelperText
                      error={errors.category && touched.category ? true : false}
                    >
                      {errors.category && touched.category
                        ? errors.category
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Paper>
                {values.category === "yazi" ? (
                  <Paper
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      paddingRight: 24,
                      marginBottom: 16,
                    }}
                  >
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      Android Product ID
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter the android ID"
                      className={styles.secondInputFields}
                      name="productIdAndroid"
                      value={productIdAndroid}
                      //  onChange={(e) => inputCheck(e, "password")}
                      onChange={(e) => setProductIdAndroid(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    <br />
                    <br />
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      Apple IOS Product ID
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter the IOS ID"
                      className={styles.secondInputFields}
                      name="productIdAndroid"
                      value={productIdIOS}
                      //  onChange={(e) => inputCheck(e, "password")}
                      onChange={(e) => setProductIdIOS(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Paper>
                ) : null}

                <Paper style={{ paddingTop: 16, paddingRight: 24 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      AUTHOR
                    </Typography>
                  </div>

                  <TextField
                    variant="outlined"
                    placeholder="Enter author's name"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="author"
                    value={values.author}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.author && touched.author ? true : false}
                    helperText={
                      errors.author && touched.author ? errors.author : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 8,
                    }}
                  >
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      TITLE
                    </Typography>
                  </div>

                  <TextField
                    variant="outlined"
                    placeholder="Enter title of the e-Learning course"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="title"
                    value={values.title}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.title && touched.title ? true : false}
                    helperText={
                      errors.title && touched.title ? errors.title : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 8,
                    }}
                  >
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      DESCRIPTION
                    </Typography>
                  </div>

                  <TextField
                    style={{ paddingBottom: 32 }}
                    variant="outlined"
                    placeholder="Enter a description for the course."
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.description && touched.description ? true : false
                    }
                    helperText={
                      errors.description && touched.description
                        ? errors.description
                        : null
                    }
                    InputLabelProps={{ shrink: true }}
                    multiline={true}
                    rowsMax={Infinity}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Paper>
              </Grid>
              <FieldArray
                name="takeAways"
                validateOnChange={false}
                render={(arrayHelpers) => {
                  return (
                    <Grid
                      item
                      className={styles.fieldItems}
                      style={{ marginBottom: 16 }}
                    >
                      <Paper style={{ padding: 16 }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            style={{ position: "relative", top: 12 }}
                            variant="h7"
                            display="block"
                            className={styles.secondLabels}
                            gutterBottom
                          >
                            What they can learn about the course
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignSelf: "flex-end",
                              alignItems: "flex-end",
                              alignContent: "flex-end",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.insert(values.takeAways.length, "")
                              } // insert an empty string at a position
                              style={{
                                marginRight: 10,
                                color: "green",
                                fontSize: "calc(12px + 1vmin)",
                                fontWeight: "bolder",
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>

                        {values.takeAways && values.takeAways.length > 0
                          ? values.takeAways.map((takeAway, index) => (
                            <Grid
                              container
                              direction="row"
                              key={`takeAway-${index}`}
                            >
                              <Grid item style={{ flex: 1 }}>
                                <TextField
                                  style={{ paddingBottom: 8 }}
                                  variant="outlined"
                                  placeholder="They can learn.. .."
                                  //  error={}
                                  //     helperText="Incorrect entry."
                                  className={styles.secondInputFields}
                                  name={`takeAways[${index}]`}
                                  //  onChange={(e) => inputCheck(e, "password")}
                                  value={takeAway}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: {},
                                  }}
                                />
                                <ErrorMessage name={`takeAways[${index}]`} />
                              </Grid>

                              <Grid
                                item
                                style={{
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                  minHeight: "100%",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }} // remove a friend from the list
                                  style={{
                                    height: "calc(12px + 2vmin)",
                                    width: "calc(12px + 2vmin)",
                                    marginRight: 10,
                                    marginLeft: 10,
                                    marginBottom: 8,
                                    color: "red",
                                    fontSize: "calc(12px + 1vmin)",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  -
                                </button>
                              </Grid>
                            </Grid>
                          ))
                          : null}
                        {values.takeAways.length > 0 &&
                          values.takeAways.length < 3 ? (
                          <Typography
                            style={{
                              fontSize: 12,
                              fontStyle: "italic",
                              paddingLeft: 25,
                              color: "red",
                            }}
                          >
                            {`Please add ${3 - values.takeAways.length} more`}
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              fontSize: 12,
                              fontStyle: "italic",
                              paddingLeft: 25,
                              color: "red",
                            }}
                          >
                            {typeof errors.takeAways === "string"
                              ? errors.takeAways
                              : null}
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  );
                }}
              />
              <Paper
                style={{
                  paddingTop: 16,
                  paddingRight: 8,
                  paddingBottom: 16,
                  marginBottom: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="h7"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    TEASER VIDEO
                  </Typography>
                  <Typography
                    variant="h7"
                    display="block"
                    className={styles.reuirementLables}
                    gutterBottom
                  >
                    (Only .mp4 accepted)
                  </Typography>
                </div>
                <Grid
                  container
                  item
                  direction="row"
                  style={{ paddingLeft: 25 }}
                >
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="Click browse to add video"
                      //  error={}
                      //     helperText="Incorrect entry."
                      name="teaser"
                      value={
                        teaserData.loading
                          ? "Loading...."
                          : teaserData.filesContent.length > 0 &&
                            teaserData.plainFiles.length > 0
                            ? teaserData.plainFiles[0].name
                            : teaserData.errors.length > 0
                              ? "Something went wrong please try again."
                              : ""
                      }
                      //  onChange={(e) => inputCheck(e, "password")}
                      style={{ minWidth: "100%", paddingRight: 16 }}
                      //  onChange={handleChange}
                      //   onBlur={handleBlur}
                      error={teaserData.errors.length ? true : false}
                      helperText={
                        teaserData.errors.length
                          ? errorChecker(teaserData.errors)
                          : null
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ alignSelf: "center" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => openFileSelector()}
                      style={{
                        marginTop: 8,
                        padding: 16,
                      }}
                    >
                      <strong>Browse</strong>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{ paddingTop: 16, paddingBottom: 16, marginBottom: 16 }}
              >
                <Grid container item direction="row">
                  <Typography
                    variant="h7"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    COVER PHOTO
                  </Typography>
                  <Typography
                    variant="h7"
                    display="block"
                    className={styles.reuirementLables}
                    gutterBottom
                  >
                    (Only 1280 x 720 .jpg accepted)
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  style={{ paddingLeft: 25 }}
                >
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="Click choose to add a cover photo"
                      name="thumbnail"
                      value={
                        thumbnailData.loading
                          ? "Loading...."
                          : thumbnailData.filesContent.length > 0 &&
                            thumbnailData.plainFiles.length > 0
                            ? thumbnailData.plainFiles[0].name
                            : thumbnailData.errors.length > 0
                              ? "Something went wrong please try again."
                              : ""
                      }
                      //  onChange={(e) => inputCheck(e, "password")}
                      style={{ minWidth: "100%", paddingRight: 24 }}
                      //  onChange={handleChange}
                      //   onBlur={handleBlur}
                      error={thumbnailData.errors.length ? true : false}
                      helperText={
                        thumbnailData.errors.length
                          ? errorChecker(thumbnailData.errors)
                          : null
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: "20%", alignSelf: "center" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => openFileSelectorThumbnail()}
                      style={{ marginTop: 8, padding: 16 }}
                    >
                      <strong>Choose</strong>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  paddingRight: 24,
                }}
              >
                <Typography
                  variant="h7"
                  display="block"
                  className={styles.secondLabels}
                  gutterBottom
                >
                  PRICE ($)
                </Typography>
                <TextField
                  type="number"
                  variant="outlined"
                  placeholder="Enter the price"
                  //  error={}
                  //     helperText="Incorrect entry."
                  className={styles.secondInputFields}
                  name="price"
                  value={values.price}
                  //  onChange={(e) => inputCheck(e, "password")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.price && touched.price ? true : false}
                  helperText={
                    errors.price && touched.price ? errors.price : null
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Paper>
              <Button
                style={{
                  width: "100%",
                  padding: 16,
                  marginBottom: 32,
                  marginTop: 16,
                  color: "white",
                  backgroundColor: "green",
                }}
                variant="contained"
                onClick={handleSubmit}
              >
                <strong>Add E-Learning Course</strong>
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
    },
    reuirementLables: {
      color: "rgba(205,100,100,1)",
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
    },

    dropdownContainer: {
      width: "100%",
      paddingBottom: 8,
      paddingLeft: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(80,96,238,1)",
      fontWeight: 600,
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    modalLoading: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.3)",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      justifyContent: "center",
      zIndex: 1000,
    },
    loadingContainer: {
      flex: 1,
      height: "10%",
      maxWidth: "50%",
      alignSelf: "center",
      paddingTop: 20,
      [theme.breakpoints.down("sm")]: {
        height: "12%",
      },
    },
  })
);
export default AddELearningCourse;
