import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import firebase, { timestamp } from "../components/firebase/FirebaseHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faUserGraduate } from "@fortawesome/pro-solid-svg-icons/faUserGraduate";
import "./css/chat.css";
import { faCoins } from "@fortawesome/pro-solid-svg-icons/faCoins";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons/faPaperPlane";

export default function AdminChatList(props) {
  const db = firebase.firestore();
  const currentUser = firebase.auth().currentUser;

  const [allChatList, setAllChatList] = useState([]);
  const [selectedWebsiteIndex, setSelectedWebsiteIndex] = useState(0);
  const [selectedChatterIndex, setSelectedChatterIndex] = useState(0);
  const [selectedUserName, setSelectedUserName] = useState("Help Desk");
  const [messageInput, setMessageInput] = useState("");

  const [hasUserStarted, setHasUserStarted] = useState(false);

  const allChatListenerOrderedRef = db
    .collection("all-chat-messages")
    .orderBy("timestamp", "asc");
  const allChatListenerRef = db.collection("all-chat-messages");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {}; // Remember if we start fetching something else
  }, []);

  useEffect(() => {
    console.log("useEffect allChatList", allChatList);
    if (allChatList != null && allChatList.length > 0) {
      if (
        allChatList[selectedWebsiteIndex] == null &&
        selectedWebsiteIndex != 0
      ) {
        setSelectedWebsiteIndex(0);
        setSelectedChatterIndex(0);
        if (allChatList[0] != null) {
          setSelectedUserName(allChatList[0].chats[0].fullname);
        } else {
          setSelectedUserName("Help Desk");
        }

        // console.log("selectedWebsiteIndex is now 0");
      } else if (
        allChatList[selectedWebsiteIndex].chats[selectedChatterIndex] == null &&
        selectedChatterIndex != 0
      ) {
        setSelectedChatterIndex(0);
        setSelectedUserName(
          allChatList[selectedWebsiteIndex].chats[0].fullname
        );
        // console.log("selectedChatterIndex is now 0");
      }
    }

    // console.log("selectedWebsiteIndex", selectedWebsiteIndex);

    // console.log(
    //   "useEffect allChatList[selectedWebsiteIndex]",
    //   allChatList[selectedWebsiteIndex]
    // );

    return () => {}; // Remember if we start fetching something else
  }, [allChatList]);

  useEffect(() => {
    const allChatListener = allChatListenerOrderedRef.onSnapshot((snapshot) => {
      var allChatListTmp = allChatList;

      snapshot.docChanges().forEach(async (change) => {
        var websiteDoc = change.doc;

        if (change.type === "added") {
          console.log(
            "allChatListener Added: ",
            websiteDoc.data().website,
            websiteDoc.data()
          );

          var websiteName = websiteDoc.data().website.toUpperCase();

          var newMsg = {
            timestamp: websiteDoc.data().timestamp,
            clientEmail: websiteDoc.data().clientEmail,
            senderEmail: websiteDoc.data().senderEmail,
            message: websiteDoc.data().message,
            read: websiteDoc.data().read,
            key: websiteDoc.id,
          };

          var newChatter = {
            id: websiteName + "-" + websiteDoc.data().clientEmail,
            timestamp: websiteDoc.data().timestamp,
            firstname: websiteDoc.data().firstname,
            lastname: websiteDoc.data().lastname,
            fullname:
              websiteDoc.data().firstname + " " + websiteDoc.data().lastname,
            clientEmail: websiteDoc.data().clientEmail,
            lastMsg: websiteDoc.data().message,
            messages: [newMsg],
            read: websiteDoc.data().read,
            lastKey: websiteDoc.id,
          };

          var newWebsite = {
            name: websiteName,
            timestamp: websiteDoc.data().timestamp,
            chats: [newChatter],
            read: websiteDoc.data().read,
          };

          var isNewWebsite = true;

          allChatListTmp.some((website) => {
            // If Website Exists, edit Website object
            if (website.name === websiteName) {
              // console.log("website exists");
              website.timestamp = websiteDoc.data().timestamp;
              website.read = websiteDoc.data().read;

              var isNewChatter = true;

              if (website.chats != null && website.chats.length > 0) {
                website.chats.some((chatter) => {
                  // If Chatter Exists, edit Chatter object
                  if (chatter.clientEmail === websiteDoc.data().clientEmail) {
                    // console.log("website's chatter exists");
                    chatter.read = websiteDoc.data().read;
                    chatter.timestamp = websiteDoc.data().timestamp;
                    chatter.lastMsg = websiteDoc.data().message;
                    chatter.lastKey = websiteDoc.id;
                    chatter.messages.push(newMsg);

                    isNewChatter = false;
                    return true;
                  } else {
                    isNewChatter = true;
                  }
                });
              }

              // If Chatter is new, add new Chatter to allChatListTmp array
              if (isNewChatter) {
                // console.log("website's chatter is new");
                website.chats.push(newChatter);
              }

              isNewWebsite = false;
              return true;
            } else {
              isNewWebsite = true;
            }
          });

          // If Website is new, add new Website to allChatListTmp array
          if (isNewWebsite) {
            // console.log("website is new");
            allChatListTmp.push(newWebsite);
          }
        }

        if (change.type === "modified") {
          console.log(
            "allChatListener Changed: ",
            websiteDoc.data().website,
            websiteDoc.data()
          );

          allChatListTmp.some((website) => {
            // If Website Exists, edit Website object
            if (website.name === websiteDoc.data().website.toUpperCase()) {
              website.read = websiteDoc.data().read;
              website.chats.some((chatter) => {
                // If Chatter Exists, edit Chatter object
                if (chatter.clientEmail === websiteDoc.data().clientEmail) {
                  chatter.read = websiteDoc.data().read;

                  return true;
                }
              });

              return true;
            }
          });
        }

        if (change.type === "removed") {
          console.log(
            "allChatListener Removed: ",
            websiteDoc.data().website,
            websiteDoc.data()
          );

          var removedWebsite = websiteDoc.data().website.toUpperCase();
          var removedChatter = websiteDoc.data().senderEmail;

          if (allChatListTmp != null && allChatListTmp.length > 0) {
            var newChatList = allChatListTmp.filter((website) => {
              // console.log("checking website", website);
              // If Website Exists, edit Website object
              if (website.name === removedWebsite) {
                // console.log("website found");
                var newChatters = website.chats.filter((chatter) => {
                  // console.log("checking chatter", chatter);
                  // If Chatter Exists, edit Chatter object
                  if (chatter.clientEmail === removedChatter) {
                    // console.log("chatter is excluded from list");
                  } else {
                    // console.log("chatter does not exist");
                    return true;
                  }
                });

                website.chats = newChatters;
                // console.log("website.chats.length", website.chats.length);

                if (website.chats.length > 0) {
                  return true;
                } else {
                  // console.log(website.name, " is excluded wtih no chatters");
                }
              } else {
                // console.log("website does not exist");
                return true;
              }
            });

            allChatListTmp = newChatList;
          }
        }
      });

      if (allChatListTmp != null && allChatListTmp.length > 0) {
        var filterAllChatListTmp = allChatListTmp.filter((website) => {
          if (website.chats.length > 0) {
            return true;
          }
        });
        allChatListTmp = filterAllChatListTmp;
        setAllChatList([...allChatListTmp]);
      } else {
        setAllChatList([]);
      }

      // auto scroll to bottom
      // .0005 second delay to wait for the msgs to load first
      setTimeout(function () {
        document.getElementById("messages-chat").scrollTop =
          document.getElementById("messages-chat").scrollHeight;
      }, 5);

      console.log("listener allChatListTmp", allChatListTmp);
    });

    return () => {
      allChatListener();
    }; // Remember if we start fetching something else
  }, []);

  async function addNewMessage(event) {
    event.preventDefault();

    const message = messageInput;

    if (message.trim() === "") {
      console.log("message is blank");
      return;
    }

    // clear the input box
    setMessageInput("");

    await allChatListenerRef.add({
      timestamp: timestamp(),
      firstname:
        allChatList[selectedWebsiteIndex].chats[selectedChatterIndex].firstname,
      lastname:
        allChatList[selectedWebsiteIndex].chats[selectedChatterIndex].lastname,
      clientEmail:
        allChatList[selectedWebsiteIndex].chats[selectedChatterIndex]
          .clientEmail,
      senderEmail: currentUser.email,
      website: allChatList[selectedWebsiteIndex].name.toLowerCase(),
      message: message,
      read: true,
    });

    // auto scroll to bottom
    // .0005 second delay to wait for the msgs to load first
    setTimeout(function () {
      document.getElementById("messages-chat").scrollTop =
        document.getElementById("messages-chat").scrollHeight;
    }, 5);
  }

  async function selectWebsite(event, row, index) {
    setSelectedWebsiteIndex(index);
    setSelectedChatterIndex(0);

    // console.log("all-chat-messages/" + allChatList[index].chats[0].lastKey);

    if (allChatList[index] != null) {
      setSelectedUserName(allChatList[index].chats[0].fullname);
    }

    // auto scroll to bottom
    // .0005 second delay to wait for the msgs to load first
    setTimeout(function () {
      document.getElementById("messages-chat").scrollTop =
        document.getElementById("messages-chat").scrollHeight;
    }, 5);

    await db
      .doc("all-chat-messages/" + allChatList[index].chats[0].lastKey)
      .set(
        {
          read: true,
        },
        { merge: true }
      );
  }

  async function selectChatter(event, row, index) {
    setSelectedChatterIndex(index);
    setSelectedUserName(row.fullname);

    console.log("row", row);

    // auto scroll to bottom
    // .0005 second delay to wait for the msgs to load first
    setTimeout(function () {
      document.getElementById("messages-chat").scrollTop =
        document.getElementById("messages-chat").scrollHeight;
    }, 5);

    await db
      .doc(
        "all-chat-messages/" +
          allChatList[selectedWebsiteIndex].chats[index].lastKey
      )
      .set(
        {
          read: true,
        },
        { merge: true }
      );

    // console.log("row", row);
  }

  async function archiveChatter(event) {
    var displayName =
      allChatList[selectedWebsiteIndex].chats[selectedChatterIndex].fullname;
    const response = window.confirm(
      "Are you sure you want to archive " + displayName + "'s chat?"
    );

    if (response) {
      // Save Chatter History to Firestore
      db.collection("all-chat-messages-archived").add({
        ...allChatList[selectedWebsiteIndex].chats[selectedChatterIndex],
        archiveTimestamp: timestamp(),
      });

      // Delete Chatter History from Firestore
      var batch = db.batch();
      allChatList[selectedWebsiteIndex].chats[
        selectedChatterIndex
      ].messages.map((row, index) => {
        batch.delete(db.collection("all-chat-messages").doc(row.key));
      });

      // // Removed Chatter from Website in UI
      // var allChatListTmp = allChatList;
      // var updatedChatters = allChatListTmp[selectedWebsiteIndex].chats.filter(
      //   (chatter) => {
      //     return (
      //       chatter.clientEmail !==
      //       allChatList[selectedWebsiteIndex].chats[selectedChatterIndex]
      //         .clientEmail
      //     );
      //   }
      // );

      // If Website no longer has Chatters
      // Removed Website from allChatList in UI
      // if (updatedChatters.length == 0) {
      //   var updatedWebsites = allChatListTmp.filter((website) => {
      //     console.log(
      //       "website.clientEmail",
      //       website.name !== allChatList[selectedWebsiteIndex].name,
      //       allChatList[selectedWebsiteIndex].name
      //     );
      //     return website.name !== allChatList[selectedWebsiteIndex].name;
      //   });
      //   allChatListTmp = [...updatedWebsites];
      // } else {
      //   allChatListTmp[selectedWebsiteIndex].chats = [...updatedChatters];
      // }

      // Commit all UI changes and Firestore deletion
      batch.commit().then(() => {
        // setAllChatList([...allChatListTmp]);
        handleClose();
        window.alert(displayName + "'s chat has been archived.");
      });
    }
  }

  return (
    <div id="wrapper">
      {hasUserStarted ? (
        <>
          <div className="content chat-content">
            <Box className="website-list-box">
              <Grid container spacing={2}>
                {allChatList.length > 0 ? (
                  allChatList.map((row, index) => (
                    <Grid item xs={6} sm={6} md={3} key={row.name}>
                      <Typography
                        sx={{ mt: 4, mb: 2 }}
                        variant="h6"
                        component="div"
                        className={`website-item ${
                          selectedWebsiteIndex === index
                            ? "website-item-active"
                            : ""
                        } ${!row.read ? "unread" : ""}`}
                        id={row.name}
                        onClick={(event) => {
                          selectWebsite(event, row, index);
                        }}
                      >
                        {row.name}
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <>
                    <div className="website-list-empty">
                      No Customers yet...
                    </div>
                  </>
                )}
              </Grid>
            </Box>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="content chat-content">
        <div className="chat-container">
          {!hasUserStarted ? (
            <>
              <div id="chat-container-start" className="chat-container-start">
                <button
                  id="chat-container-start-btn"
                  className="btn btn-default buttons-collection btn-default-dt-options"
                  type="button"
                  onClick={(event) => {
                    setHasUserStarted(true);
                  }}
                >
                  Start Chatting with Clients
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          <section className="chat-discussions" id="chat-discussions">
            {allChatList[selectedWebsiteIndex] != null ? (
              <>
                {allChatList[selectedWebsiteIndex].chats.length > 0 ? (
                  allChatList[selectedWebsiteIndex].chats.map((row, index) => (
                    <div
                      className={`chat-discussion ${
                        selectedChatterIndex == index ? "message-active" : ""
                      } ${row.read == true ? "" : "unread"}`}
                      key={`${row.id}`}
                      onClick={(event) => {
                        selectChatter(event, row, index);
                      }}
                    >
                      <div id={row.id} className="chat-profile-box"></div>
                      <div className="chat-desc-contact">
                        <div className="chat-desc-contact-header">
                          <p className="name">
                            {row.fullname.substring(0, 20) +
                              (row.fullname.length > 20 ? "..." : "")}
                          </p>
                        </div>
                        <p id={`${row.id}-last-msg`} className="message">
                          {row.lastMsg}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </section>

          <section className="chat-window">
            <div className="chat-header">
              <FontAwesomeIcon icon={faUser} size="lg" className="icon " />
              {/* <i className="icon fa fa-user-o" aria-hidden="true"></i> */}
              <p className="name" id="chat-window-name">
                {selectedUserName}
              </p>

              <Button
                id="basic-button"
                className="right icon chat-clickable"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
              </Button>
              <Menu
                id="basic-menu"
                style={{ marginTop: "45px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    archiveChatter(event);
                  }}
                >
                  Archive Chat
                </MenuItem>
              </Menu>
            </div>
            <div className="messages-chat" id="messages-chat">
              <div className="chat-message">
                {allChatList[selectedWebsiteIndex] != null ? (
                  <>
                    {allChatList[selectedWebsiteIndex].chats[
                      selectedChatterIndex
                    ] != null ? (
                      <>
                        {allChatList[selectedWebsiteIndex].chats[
                          selectedChatterIndex
                        ].messages.length > 0 ? (
                          allChatList[selectedWebsiteIndex].chats[
                            selectedChatterIndex
                          ].messages.map((row, index) => (
                            <div className="message" key={row.key}>
                              {row.clientEmail === row.senderEmail ? (
                                <>
                                  <p className="text">{row.message}</p>
                                </>
                              ) : (
                                <>
                                  <div className="chat-response">
                                    <p className="text">{row.message}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="footer-chat">
              {allChatList.length > 0 ? (
                <>
                  <input
                    id="message-input"
                    type="text"
                    className="chat-write-message"
                    placeholder="Type your message here"
                    value={messageInput}
                    onChange={async (event) => {
                      setMessageInput(event.target.value);
                    }}
                    onKeyUp={async (event) => {
                      if (event.key === "Enter") {
                        await addNewMessage(event);
                      }
                    }}
                  ></input>
                  <FontAwesomeIcon
                    id="message-btn"
                    icon={faPaperPlane}
                    size="lg"
                    className="icon send chat-clickable"
                    onClick={async (event) => {
                      await addNewMessage(event);
                    }}
                  />
                </>
              ) : (
                <>
                  <input
                    id="message-input"
                    type="text"
                    className="chat-write-message"
                    placeholder="Type your message here"
                    value={messageInput}
                    disabled
                    onChange={async (event) => {
                      setMessageInput(event.target.value);
                    }}
                    onKeyUp={async (event) => {
                      if (event.key === "Enter") {
                        await addNewMessage(event);
                      }
                    }}
                  ></input>
                  <FontAwesomeIcon
                    id="message-btn"
                    icon={faPaperPlane}
                    size="lg"
                    className="icon send chat-clickable"
                  />
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
