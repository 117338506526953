import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import avatarSrc from "../images/Chito.jpg";
import { capitalize } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons/faCheckSquare";
import { faTimesSquare } from "@fortawesome/pro-regular-svg-icons/faTimesSquare";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";

import firebase from "../components/firebase/FirebaseHelper";
import { Document, Page, pdfjs } from "react-pdf";
// import ImageViewer from "react-simple-image-viewer";
import ReactPlayer from "react-player";
import { generateUUID } from "../utils/uniqueKeyGenerator";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const userObject = {
  avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
  email: "george.bluth@reqres.in",
  first_name: "George",
  id: 1,
  last_name: "Bluth",
};
const gradeLevels = [
  {
    id: "primary",
    name: "Primary School (Grades 1-5)",
    gradeDesc: "Grades 1-5",
    grdMainLvl: "L1",
  },
  {
    id: "middle",
    name: "Middle School (Grades 6-8)",
    gradeDesc: "Grades 6-8",
    grdMainLvl: "L2",
  },
  {
    id: "high",
    name: "High School (Grades 9-12)",
    gradeDesc: "Grades 9-12",
    grdMainLvl: "L3",
  },
  {
    id: "college",
    name: "College and Graduates",
    gradeDesc: "College and Masteral Studies",
    grdMainLvl: "L4",
  },
  {
    id: "professional",
    name: "Professionals",
    gradeDesc: "Professionals / Mentorship",
    grdMainLvl: "L5_L6",
  },
];

export default function FormSingleView(props) {
  const location = useLocation();
  const styles = useStyles();
  const [userData] = useState(
    location?.state?.data ? location.state.data : userObject
  );
  const newTeachingData = userData?.teachingData ? userData.teachingData : {};
  const [teachingData] = useState(newTeachingData);
  const [referencesData] = useState(
    userData?.referencesData ? userData.referencesData : []
  );
  const fullName = userData.fullName ? userData.fullName : "--";
  const avatarUrl = userData.avatar ? `${userData.avatar} ` : avatarSrc;
  const [_addressData] = useState(
    userData?.addressData ? userData?.addressData : {}
  );
  const [salary, setSalaryLevel] = useState(
    userData?.tutorLevels ? userData?.tutorLevels : []
  );
  const mentorSubjects = location?.state?.mentorSubjects;
  const tutorSubjects = location?.state?.tutorSubjects;
  const tempSubjects =
    teachingData?.subjects && teachingData?.subjects.length > 0
      ? teachingData?.subjects
      : [];
  const tempPendingSubjects =
    teachingData?.pendingSubjects && teachingData?.pendingSubjects.length > 0
      ? teachingData?.pendingSubjects
      : [];
  const tempBackendSubjects =
    userData.guideType === "mentor" ? mentorSubjects : tutorSubjects;
  const _listedSubjects = tempBackendSubjects.filter((itemX) =>
    tempSubjects.includes(itemX.id)
  );
  const _pendingSubjects = tempBackendSubjects.filter((itemX) =>
    tempPendingSubjects.includes(itemX.id)
  );
  const _pendingGradeLevel = teachingData?.pendingGradeLevels
    ? gradeLevels.filter((itemX) =>
      teachingData?.pendingGradeLevels.includes(itemX.id)
    )
    : [];
  const [pendingGradeLevel, setPendingGradeLevel] =
    useState(_pendingGradeLevel);
  const [listedSubjects, setListedSubjects] = useState(_listedSubjects);
  const [pendingSubjects, setPendingSubjects] = useState(_pendingSubjects);
  const [approvalStatus, setApprovalStatus] = useState(
    userData?.approvalStatus
  );
  const [dataURL, setDataURL] = useState([]);

  const [showVideo, setShowVideo] = useState(false);
  const [showId, setShowId] = useState(false);
  const [showCV, setShowCV] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [pdfURL, setPDFURL] = useState(null);
  const salaryLevel = [
    "L1.1",
    "L1.2",
    "L1.3",
    "L2.1",
    "L2.2",
    "L2.3",
    "L3.1",
    "L3.2",
    "L3.3",
    "L4.1",
    "L4.2",
    "L4.3",
    "L5",
    "L6",
    "L7",
    "L8",
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const fileToFetch = ["cv", "sampleWork", "governmentId"];

  useEffect(async () => {
    try {
      const _links = await Promise.all(
        fileToFetch.map(async (fileFetch) => {
          const imageRef = firebase
            .storage()
            .refFromURL("gs://ancient-tractor-277102-signup")
            .child(`${userData.id}/${fileFetch}`);
          return await imageRef.getDownloadURL().then(onResolve, onReject);

          function onResolve(foundURL) {
            return foundURL;
          }

          function onReject(error) {
            console.log(error.code);
          }
        })
      );
      setDataURL(_links);

      setPDFURL({ url: dataURL[0] });
      // _links.map((url, index) => {
      //     switch (index) {
      //         case 0: {
      //             if (url !== undefined) {

      //             } else {
      //                 console.log("cv missing")
      //             }
      //             break;
      //         }
      //         case 1: {
      //             if (url !== undefined) {

      //             } else {
      //                 console.log("sample missing")
      //             }
      //             break;
      //         }
      //         case 2: {
      //             if (url !== undefined) {

      //             } else {
      //                 console.log("govt missing")
      //             }
      //             break;
      //         }
      //     }
      // })
    } catch (e) {
      console.log("err", e);
    }
  }, []);

  const handleChange = (e) => {
    const selectedValues = e.target.value;
    const _salary = [selectedValues];

    //      const tempPendingSubjects = selectedValues.filter(salary => salary !== "No Data")
    setSalaryLevel(_salary);
  };
  const handleAccountStatus = (newStatus) => {
    setApprovalStatus(newStatus);
  };
  const saveChanges = () => {
    const newChanges = {
      approvalStatus,
      salary,
      listedSubjects: listedSubjects.map((subj) => subj.id),
      pendingSubjects: pendingSubjects.map((subj) => subj.id),
    };
    props.saveChanges(newChanges, userData.id);
  };
  const approveSubject = (e) => {
    const tempPendingSubjects = pendingSubjects.filter(
      (subj) => subj.id !== e.id
    );
    const tempApproved = [...listedSubjects, e];

    setPendingSubjects(tempPendingSubjects);
    setListedSubjects(tempApproved);
    //  const products = this.state.products.filter(prod => prod.id !== e)
  };
  const deleteSubject = (e) => {
    const tempPendingSubjects = pendingSubjects.filter(
      (subj) => subj.id !== e.id
    );

    setPendingSubjects(tempPendingSubjects);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const headerBox = (
    <Grid item container justify="flex-start" alignItems="center">
      <Paper className={styles.informationContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={1}
          container
          justify="center"
          alignItems="center"
        >
          <div className={styles.avatarContainer}>
            <Avatar alt={fullName} src={avatarUrl} className={styles.avatar} />
          </div>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="center"
          sm={12}
          md={4}
          lg={5}
          className={styles.informationContainer2}
        >
          <Typography variant="caption" display="block">
            {capitalize(userData?.guideType)}
          </Typography>

          <Typography variant="h5" display="block">
            {fullName}
          </Typography>

          <Typography variant="overline" display="block">
            {fullName}
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          container
          justify="flex-end"
          alignItems="center"
          spacing={2}
          className={styles.buttonContainer}
        >
          {approvalStatus === "pending" ? (
            <>
              <Grid item>
                {
                  //TODO FIX EDIT STATUS
                }
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.confirm(
                      `Save changes to the account of ${userData.fullName}?`
                    ) && saveChanges()
                  }
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.confirm(
                      `Are you sure to reject the request of ${userData.fullName}?`
                    ) && handleAccountStatus("inactive")
                  }
                >
                  Reject
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.confirm(
                      `Confirm the approval of ${userData.fullName}?`
                    ) && handleAccountStatus("active")
                  }
                >
                  Accept
                </Button>
              </Grid>
            </>
          ) : approvalStatus === "active" ? (
            <>
              <Grid item>
                {
                  //TODO FIX EDIT STATUS
                }
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.confirm(
                      `Save changes to the account of ${userData.fullName}?`
                    ) && saveChanges()
                  }
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.confirm(
                      `Are you sure to de-activate the account of ${userData.fullName}?`
                    ) && handleAccountStatus("inactive")
                  }
                >
                  Deactivate
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                {
                  //TODO FIX EDIT STATUS
                }
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.confirm(
                      `Save changes to the account of ${userData.fullName}?`
                    ) && saveChanges()
                  }
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.confirm(
                      `Are you sure to re-activate the account of ${userData.fullName}?`
                    ) && handleAccountStatus("active")
                  }
                >
                  Re-activate
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
  const personalDataBox = (
    <Grid item>
      <Paper className={styles.bioContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Personal Information
        </Typography>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Birthday:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {userData?.birthday === undefined
                ? "--"
                : moment(userData?.birthday.toDate())
                  .format("MM-DD-YYYY")
                  .toString()}
            </Typography>
          </div>
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Phone:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {userData?.phone}
            </Typography>
          </div>
          {userData?.isPhoneVerified ? (
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                height: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="lg"
                style={{ color: "green" }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                height: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="lg"
                style={{ color: "red" }}
              />
            </div>
          )}
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Email:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {userData?.email}
            </Typography>
          </div>
          {userData?.isEmailVerified ? (
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                height: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="lg"
                style={{ color: "green" }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                marginLeft: 10,
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                height: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="lg"
                style={{ color: "gray" }}
              />
            </div>
          )}
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Account Created:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {userData?.dateCreated === undefined
                ? "--"
                : moment(userData?.dateCreated.toDate())
                  .format("MM-DD-YYYY")
                  .toString()}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
  const guideBioBox = (
    <Grid item>
      <Paper className={styles.bioContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          BIO
        </Typography>
        <div>
          <Typography variant="body2" gutterBottom>
            {userData?.bio ? userData.bio : "No data"}
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
  const guideLevelBox = (
    <Grid item>
      <Paper className={styles.dropdownContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          LEVEL
        </Typography>
        <FormControl className={styles.formControl}>
          {/* <InputLabel htmlFor="filled-age-native-simple">Level</InputLabel> */}
          <Select
            native
            value={salary}
            onChange={handleChange}
            disableUnderline
          >
            <option value={""} disabled>
              Not set
            </option>
            {salaryLevel.map((level) => (
              <option key={generateUUID()} value={level}>
                {level}
              </option>
            ))}
          </Select>

          {/* <Select
                        disableUnderline
                        multiple
                        value={salary.length > 0 ? salary : ["No Data"]}
                        onChange={handleChange}
                        input={<Input />}
                        renderValue={(selected) => (
                            <div className={styles.chips}>
                                {selected.map((value) => (
                                    <Chip key={generateUUID()} label={value} className={styles.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {salaryLevel.map((salaryLevel) => (
                            <MenuItem key={generateUUID()} value={salaryLevel} style={getStyles(salary, salaryLevel, theme)} >
                                {salaryLevel}
                            </MenuItem>
                        ))}
                    </Select> */}
        </FormControl>
      </Paper>
    </Grid>
  );
  const guidePendingLevelBox = (
    <Grid item>
      <Paper className={styles.dropdownContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Requested LEVEL
        </Typography>
        <div className={styles.paperItems}>
          <Typography variant="body2" gutterBottom>
            {pendingGradeLevel.length
              ? `${pendingGradeLevel[0].grdMainLvl} : ${pendingGradeLevel[0].name}`
              : "No data"}
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
  const guideAddressBox = (
    <Grid item>
      <Paper className={styles.bioContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Address Information
        </Typography>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                    <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Country:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {_addressData?.country}
            </Typography>
          </div>
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                    <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Street:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {_addressData?.streetAddress}
            </Typography>
          </div>
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                    <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              State:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {_addressData?.state}
            </Typography>
          </div>
        </div>
        <div className={styles.paperItems}>
          {/* <div className={styles.flagContainer}>
                    <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                </div> */}
          <div style={{ marginRight: 10 }}>
            <Typography variant="body2" gutterBottom>
              Post Code:
            </Typography>
          </div>
          <div>
            <Typography variant="body2" gutterBottom>
              {_addressData?.postalCode}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
  const guideApprovedSubjects = (
    <Grid item>
      <Paper className={styles.subjectsToTeachContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Approved Subjects
        </Typography>
        <Paper elevation={0} className={styles.paperListContainers}>
          {listedSubjects.length > 0 ? (
            listedSubjects.map((subj) => (
              <Paper key={generateUUID()} className={styles.reviewersList}>
                <Typography variant="body2" gutterBottom>
                  {subj.name}
                </Typography>
                {/* <div className={styles.subjectListButtonsContainer}>
                                        <div className={styles.subjectsButtons} onClick={() => console.log("ok")}>
                                            <FontAwesomeIcon icon={faCheckSquare} size="lg" style={{ color: "green" }} />
                                        </div>
                                        <div className={styles.subjectsButtons} onClick={() => window.confirm(`Are you sure you wish to remove ${subj.name} subject?`) &&
                                            deleteSubject(subj)}>
                                            <FontAwesomeIcon icon={faTimesSquare} size="lg" style={{ color: "red" }} />
                                        </div>
                                    </div> */}
              </Paper>
            ))
          ) : (
            <div className={styles.paperItems}>
              <Typography variant="body2" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </Paper>
      </Paper>
    </Grid>
  );
  const guidePendingSubjects = (
    <Grid item>
      <Paper className={styles.subjectsToTeachContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Pending Subjects
        </Typography>
        <Paper elevation={0} className={styles.paperListContainers}>
          {pendingSubjects.length > 0 ? (
            pendingSubjects.map((subj, index) => (
              <Paper key={generateUUID()} className={styles.reviewersList}>
                <Typography variant="body2" gutterBottom>
                  {subj.name}
                </Typography>
                <div className={styles.subjectListButtonsContainer}>
                  <div
                    className={styles.subjectsButtons}
                    onClick={() =>
                      window.confirm(
                        `Are you sure you wish to approve ${subj.name} subject?`
                      ) && approveSubject(subj)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      size="lg"
                      style={{ color: "green" }}
                    />
                  </div>
                  <div
                    className={styles.subjectsButtons}
                    onClick={() =>
                      window.confirm(
                        `Are you sure you wish to remove ${subj.name} subject?`
                      ) && deleteSubject(subj)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTimesSquare}
                      size="lg"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
              </Paper>
            ))
          ) : (
            <div className={styles.paperItems}>
              <Typography variant="body2" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </Paper>
      </Paper>
    </Grid>
  );
  const guideReviewers = (
    <Grid item>
      <Paper className={styles.reviewersContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          REVIEWERS
        </Typography>
        <Paper elevation={0} className={styles.paperListContainers}>
          {userData?.reviewerData &&
            userData?.reviewerData?.reviewHistory.length > 0 ? (
            userData?.reviewerData?.reviewHistory
              .map((ref) => (
                <Paper key={generateUUID()} className={styles.reviewersList}>
                  <Typography variant="body2" gutterBottom>
                    {ref.reviewer}
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={styles.reviewerListDate}
                  >
                    {moment(ref.dateReview).format("MM-DD-YYYY").toString()}
                  </Typography>
                </Paper>
              ))
              .reverse()
          ) : (
            <div className={styles.paperItems}>
              <Typography variant="body2" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </Paper>
      </Paper>
    </Grid>
  );

  const guideRefences = (
    <Grid item>
      <Paper className={styles.referencesContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          REFERENCES
        </Typography>
        <Paper elevation={0} className={styles.paperListContainers}>
          {referencesData.length ? (
            referencesData.map((ref) => (
              <Paper key={generateUUID()} className={styles.referencesList}>
                <Typography variant="body2" gutterBottom>
                  {ref.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {ref.emailAddress}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {ref.contactNumber}
                </Typography>
              </Paper>
            ))
          ) : (
            <div className={styles.paperItems}>
              <Typography variant="body2" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </Paper>
      </Paper>
    </Grid>
  );

  const guideApplicantFiles = (
    <Grid item>
      <Paper className={styles.applicantFilesContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          APPLICANT FILES
        </Typography>
        <Paper elevation={0} className={styles.paperListContainers}>
          {dataURL.length
            ? dataURL.map((url, index) => {
              switch (index) {
                case 0: {
                  if (url !== undefined) {
                    return (
                      <Paper
                        key={generateUUID()}
                        className={styles.referencesList}
                        onClick={() => setShowCV(!showCV)}
                      >
                        <Typography variant="body2" gutterBottom>
                          View Curriculum Vitae
                        </Typography>
                      </Paper>
                    );
                  }
                  break;
                }
                case 1: {
                  if (url !== undefined) {
                    return (
                      <Paper
                        key={generateUUID()}
                        className={styles.referencesList}
                        onClick={() => setShowVideo(!showVideo)}
                      >
                        <Typography variant="body2" gutterBottom>
                          View Sample Work
                        </Typography>
                      </Paper>
                    );
                  }
                  break;
                }
                case 2: {
                  if (url !== undefined) {
                    return (
                      <Paper
                        key={generateUUID()}
                        className={styles.referencesList}
                        onClick={() => setShowId(!showId)}
                      >
                        <Typography variant="body2" gutterBottom>
                          View Government Id
                        </Typography>
                      </Paper>
                    );
                  }
                  break;
                }
              }
            })
            : null}
        </Paper>
      </Paper>
    </Grid>
  );
  const guideInfoBox = (
    <Grid item container direction="row" justify="center" spacing={1}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        container
        direction="column"
        spacing={1}
      >
        {guideLevelBox}
        {salary.length === 0 ? guidePendingLevelBox : null}
        {personalDataBox}
        {guideBioBox}
        {guideAddressBox}
        {guideApprovedSubjects}
        {guidePendingSubjects}
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={6}
        lg={6}
        direction="column"
        spacing={1}
      >
        {guideRefences}
        {guideApplicantFiles}
        {guideReviewers}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={styles.gridMainContainer}
    >
      {headerBox}
      {guideInfoBox}
      {showVideo && (
        <Paper className={styles.playerContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowVideo(!showVideo)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            Close
          </Button>
          <ReactPlayer
            className={styles.playerStyle}
            url={dataURL[1]}
            height="80%"
            width="80%"
            playing
            controls
          />
        </Paper>
      )}
      {showId && (
        <Paper
          className={styles.playerContainer}
          onClick={() => setShowId(!showId)}
        >
          <img
            src={dataURL[2]}
            width="50%"
            height="50%"
            onClick={() => setShowId(!showId)}
            style={{
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Paper>
      )}
      {showCV && (
        <div style={{
          position: "absolute", minHeight: "85%",
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
        }}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="3x"
            style={{

              borderRadius: 60,
              position: "absolute",
              color: "red",
              fontWeight: 600,
              top: -25,
              right: -50,

              zIndex: 1000,
            }}
            onClick={() => {
              setShowCV(!showCV);
              setPageNumber(0);
            }}
          /><Paper
            // className={styles.playerContainer}
            style={{ height: "100%" }}
            onClick={() => {
              if (pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
              } else {
                setShowCV(!showCV);
                setPageNumber(1);
              }
            }}
          >
            <Document
              file={dataURL[0]}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={() => console.log("error")}

            >
              <Page pageNumber={pageNumber} className={styles.pagePdf} />
            </Document>
          </Paper></div>

      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(25),
        height: theme.spacing(25),
      },
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    labelHeaders: {
      color: "rgba(49,64,53,0.5)",
      fontWeight: 650,
    },
    subjectsButtons: {
      display: "flex",
      marginLeft: 10,
      justifyContent: "center",
      alignContent: "center",
      alignSelf: "center",
      height: "100%",
    },
    subjectListButtonsContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignContent: "center",
      alignItems: "center",
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    paperListContainers: {
      maxHeight: theme.spacing(35),
      overflow: "auto",
      marginBottom: theme.spacing(2),
    },
    gridMainContainer: {
      marginTop: "0.25%",
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    paperItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 25,
      marginLeft: theme.spacing(2),
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    bioContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    reviewersList: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      padding: theme.spacing(1.5),
      margin: theme.spacing(1),
    },
    reviewerListDate: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    referencesList: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      marginLeft: theme.spacing(1),
      width: "98%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      backgroundColor: "rgba(11,227,190,0.2)",
    },
    playerContainer: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.5)",
      minHeight: "80%",
      position: "absolute",
      justifyContent: "center",
      alignSelf: "center",
      alignContent: "center",
    },
    pagePdf: {

    },
    playerStyle: {
      // position: "absolute",
      // top: 0,
      // left: 0,
      // bottom: 0,
      // right: 0
    },
  })
);
