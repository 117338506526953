import React, { useEffect, useState, createRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import avatarSrc from "../images/Chito.jpg";
import { useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { Box, Modal, TextField } from "@material-ui/core";
import swal from "sweetalert";
import firebase, { timestamp } from "./firebase/FirebaseHelper";
import Multiselect from 'multiselect-react-dropdown';
const userObject = {
  avatar: "https://s3.amazonaws.com/uifaces/faces/twitter/calebogden/128.jpg",
  email: "george.bluth@reqres.in",
  first_name: "George",
  id: 1,
  last_name: "Bluth",
};

export default function FormSingleView(props) {
  const [amount, setAmount] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const { id } = useParams();
  let history = useHistory();
  const styles = useStyles();
  const [userData, setUserData] = useState(
    location?.state?.data ? location.state.data : userObject
  );
  const newTeachingData = userData?.teachingData ? userData.teachingData : {};
  const [teachingData, setTeachingData] = useState(newTeachingData);
  const [referencesData, setReferencesData] = useState(
    userData?.referencesData ? userData.referencesData : []
  );
  const [childrenData, setChildrenData] = useState(
    userData?.childrenData?.children ? userData.childrenData.children : []
  );
  const [selectedYorc, setSelectedYorc] = useState([])
  const fullName = userData?.fullName ? userData.fullName : "--";
  const avatarUrl = userData?.avatar ? `${userData.avatar} ` : avatarSrc;
  const multiselectRef = createRef();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "50px",
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [_addressData, setAddressData] = useState(
    userData?.addressData ? userData?.addressData : {}
  );
  const store = firebase.firestore();
  const storage = firebase.storage();
  console.log(props.yorcList.length)

  useEffect(() => {
    let didCancel = false;

    async function fetchOwnedCourses() {
      console.log("We are called yorc")
      try {
        await store
          .collection("users")
          .doc(userData.id)
          .collection("eLearningCourses")
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("empty data yorc");
              return;
            }
            const ownedCoursesData = []
            querySnapshot.docs.map((ref, index) => {
              props.yorcList.filter((element) => {
                if (element.id === ref.id) {
                  ownedCoursesData.push(element)
                }
                //  return element.id === ref.id;
              })
            }
            );
            setSelectedYorc(ownedCoursesData);
            //      setYorcList(yorcData)

          });
        if (!didCancel) {
          // Ignore if we started fetching something else
          console.log("change data yorc");
        }
      } catch (err) {
        console.log("error in fetch yorc");
        console.log(err);
      }
    }

    fetchOwnedCourses();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  const TopUpWallet = async (value) => {
    if (amount.trim().length === 0) {
      swal("Yazi Admin", "Amount cannot be empty", "error");
      return;
    }

    value = Number(value);
    var userWalBal = Number(userData.wallet);

    var result = value + userWalBal;


    var doc = store.collection("users").doc(userData.id);
    doc
      .update({
        wallet: parseFloat(result).toFixed(2),
      })
      .then(async () => {
        doc = store.collection("admin-wallet-topups").add({
          accountid: userData.id,
          amount: amount,
          newBalance: result,
          createdOn: timestamp(),
          admin: firebase.auth().currentUser.displayName,
        });
        handleClose();
        history.go(-2);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const UpdateCourses = async (courses) => {
    if (courses.length == 0) return;
    await Promise.all(
      courses.map(async (course) => {
        console.log(`this is the course id ${course.id}`)
        await GetCourseLessons(course.id)
      })
    );

  }
  const UpdateYorcCourse = async (courseId) => {

    // const yorcRef = store.collection("users").doc(userData.id).collection("eLearningCourses").doc(courseId);
    // await yorcRef.set({ courseCurrentLesson }) //GET first id
  }

  const GetCourseLessons = async (courseId) => {
    console.log("this is the course Id", courseId)
    const fixedLessonsData = await store
      .collection('e-learning-courses')
      .doc(courseId) //
      .collection('lessons')
      .orderBy("lessonNumber")
      .get()
      .then(async (lessonsSnapshot) => {
        if (lessonsSnapshot.empty) {
          console.log('empty?');
          //       setIsLoading(false)
          return [];
        }
        const lessonsData = lessonsSnapshot.docs.map((ref, index) => {
          return {
            "id": ref.id, ...ref.data()
          }
        })
        return lessonsData
      });
    //LOOP result add to users/elearning/docid/lessons/lessonId/
    //check for each lessonId for sub collections of FILES
    console.log(fixedLessonsData)

    await AddLessonsToUser(courseId, fixedLessonsData)
  }
  const GetLessonsSyllabus = async (courseId, lessonId) => {
    const syllabusTypes = [
      'audios',
      'videos',
      'documents',
      'presentations',
    ];
    await Promise.all(
      syllabusTypes.map(async (syllabusType) => {
        const syllabusData = await GetSyllabusType(courseId, lessonId, syllabusType)
        if (syllabusData.length > 0) {
          await AddSyllabusToUser(courseId, lessonId, syllabusData);
        }
      })
    );
  }
  const GetSyllabusType = async (courseId, lessonId, syllabusType) => {
    return await store
      .collection('e-learning-courses')
      .doc(courseId) //
      .collection('lessons')
      .doc(lessonId)
      .collection(syllabusType)
      .get()
      .then(
        (querySnapshot) => {
          if (querySnapshot.empty) {
            return [];
          }
          const syllabusData = querySnapshot.docs.map((ref, index) => {
            return {
              "id": ref.id, ...ref.data()
            }
          })
          return syllabusData;
        },
        (error) => {
          console.log("syllabusType error", syllabusType);
          console.log("syllabusType error", error);
          //    setLoadSchedules(false)
        }
      );
  }
  const AddLessonsToUser = async (courseId, lessons) => {
    if (lessons.length == 0) return;
    await Promise.all(
      lessons.map(async (lesson) => {
        console.log(`lesson id ${lesson.id}`)
        console.log(`lesson id ${lesson.lessonNumber}`)
        if (lesson.lessonNumber == 1) {
          await SetCurrentLesson(courseId, lesson.id)
        }
        await AddLessonToUser(courseId, lesson.id, lesson)
        await GetLessonsSyllabus(courseId, lesson.id)
      })
    );
  }
  const SetCurrentLesson = async (courseId, lessonId) => {
    await store.collection("users")
      .doc(userData.id)
      .collection("eLearningCourses")
      .doc(courseId)
      .set({
        courseCurrentLesson: lessonId,
      }, { merge: true });
  }
  const AddLessonToUser = async (courseId, lessonId, lesson) => {
    if (lesson.lessonNumber == 1) {
      await SetCurrentLesson(courseId, lessonId)
    }
    await store.collection("users")
      .doc(userData.id)
      .collection("eLearningCourses")
      .doc(courseId)
      .collection("lessons")
      .doc(lessonId)
      .set(lesson, { merge: true });
    //check lesson syllabus
  }
  const AddSyllabusToUser = async (courseId, lessonId, syllabus) => {
    await Promise.all(
      syllabus.map(async (syllabusData) => {
        console.log("syllabus data", syllabusData)
        await AddSyllabusToUserCollection(courseId, lessonId, syllabusData)
      })
    );
  }
  const AddSyllabusToUserCollection = async (courseId, lessonId, syllabus) => {
    await store
      .collection("users")
      .doc(userData.id)
      .collection('eLearningCourses')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId)
      .collection(syllabus.contentType)
      .doc(syllabus.id)
      .set(syllabus, { merge: true })
  }
  const GetCourseLessonsV2 = async (courseId) => {
    await store
      .collection('e-learning-courses')
      .doc(courseId)
      .collection('lessons')
      .get()
      .then(async (lessonsSnapshot) => {
        const lessonContents = [
          'audios',
          'videos',
          'documents',
          'presentations',
        ];
        if (lessonsSnapshot.empty) {
          console.log('empty?');
          //       setIsLoading(false)
          return;
        }
        //SORT ALL LESSONS DATA
        const lessonsData = await Promise.all(
          lessonsSnapshot.docs.map(async (lesson) => {
            const lessonID = lesson.id;

            //CHECK QUIZZES EACH LESSON
            const lessonQuizData = await store
              .collection('e-learning-courses')
              .doc(courseId)
              .collection('lessons')
              .doc(lessonID)
              .collection('quizzes')
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.empty) {
                  // console.log('we are empty quizzes');

                  return [];
                }
                const quizData = querySnapshot.docs.map((quiz) =>
                  quiz.data(),
                );
                return quizData;
              })
              .catch((e) => {
                console.log('error in getting quiz');
              });
            //CHECK CONTENTS EACH LESSON
            const contentsData = await Promise.all(
              lessonContents.map(async (content) => {
                console.log('CONTENT DATA COURSE ID', courseId)
                console.log('CONTENT DATA LESSON ID', lessonID)
                console.log('CONTENT DATA CONTENT', content)

                return await store
                  .collection('e-learning-courses')
                  .doc(courseId)
                  .collection('lessons')
                  .doc(lessonID)
                  .collection(content)
                  .get()
                  .then(async (contentSnapshot) => {
                    if (contentSnapshot.empty) {
                      console.log('YES EMPTY ', content);
                      //       setIsLoading(false)
                      return;
                    }

                    return await Promise.all(
                      contentSnapshot.docs.map(async (lessonContent) => {
                        const LESSON_CONTENT = lessonContent.data();

                        // const lessonContentFileName = LESSON_CONTENT.name;
                        //     const lessonContentId = LESSON_CONTENT.id;

                        // const contentRef = storage
                        //   .refFromURL(
                        //     'gs://ancient-tractor-277102-e-learning',
                        //   )
                        //   .child(
                        //     `${courseId}/lessons/${lessonID}/${content}/${lessonContentId}/${lessonContentFileName}`,
                        //   );
                        // const contentURL = await contentRef
                        //   .getDownloadURL()
                        //   .then((url) => url)
                        //   .catch((e) => {
                        //     console.log('error in url', e);
                        //     return '';
                        //   });

                        // const lessonComplete = await store
                        //   .collection('users')
                        //   .doc(userData.id)
                        //   .collection('eLearningCourses')
                        //   .doc(courseId)
                        //   .collection('lessons')
                        //   .doc(lessonID)
                        //   .collection(content)
                        //   .doc(lessonContentId)
                        //   .get()
                        //   .then((fStoreData) => {
                        //     let isLessonComplete = false;
                        //     if (!fStoreData.exists) {
                        //     } else {
                        //       isLessonComplete =
                        //         fStoreData.data().lessonComplete;
                        //     }
                        //     return isLessonComplete;
                        //   })
                        //   .catch((e) =>
                        //     console.log('lesson complete error', e),
                        //   );

                        return {
                          //  contentURL,
                          //   lessonComplete,
                          ...LESSON_CONTENT,
                        };
                      }),
                    );
                  })
                  .catch((e) =>
                    console.log('error in assembling lesson data', e),
                  );
              }),
            );

            let newArr = [];
            if (contentsData && contentsData.length > 0) {
              console.log('WHAT CONTENT DATA LESSON COMPLETE', contentsData.lessonComplete);
              console.log('WHAT CONTENT DATA URL', contentsData.contentURL);

              const checkingContents = contentsData.filter(
                (item) => item != undefined,
              );
              checkingContents.map((arrayContent) => {
                newArr.push(...arrayContent);
              });

              console.log("newArrar contents", newArr)
            }
            return {
              id: lessonID,
              lessonContents: [...newArr],
              lessonQuizData,
              ...lesson.data(),
            };
          }),
        );
        let sortedData;
        if (lessonsData && lessonsData.length > 0) {
          console.log('WHAT IS THE LESSON CONTENT', lessonsData);
          sortedData = lessonsData.sort((a, b) =>
            a.lessonNumber > b.lessonNumber ? 1 : -1,
          );
        } else {
          sortedData = {};
        }
        console.log("sorted Data");
        console.log(sortedData);

        await store.collection("users").doc(userData.id).collection("eLearningCourses").doc(courseId).set({
          courseCurrentLesson: sortedData[0].id
        }).catch((e) => console.log("error setting current lesson"))
        await Promise.all(
          sortedData.map(async (lessonSorted) => {
            await store.collection("users").doc(userData.id).collection("eLearningCourses").doc(courseId).collection("lessons").doc(sortedData[0].id).set({
              ...lessonSorted
            }).catch((e) => console.log("error setting current lesson"))
          }))
      });
  }


  const headerBox = (
    <Grid item container justify="flex-start" alignItems="center">
      <Paper className={styles.informationContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={1}
          container
          justify="center"
          alignItems="center"
        >
          <div className={styles.avatarContainer}>
            <Avatar
              alt={fullName}
              src={avatarUrl}
              variant={"circular"}
              className={styles.avatar}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="center"
          sm={12}
          md={4}
          lg={5}
          className={styles.informationContainer2}
        >
          <Typography variant="caption" display="block">
            {fullName}
          </Typography>

          <Typography variant="h5" display="block">
            {fullName}
          </Typography>

          <Typography variant="overline" display="block">
            {fullName}
          </Typography>
        </Grid>
        {userData?.approvalStatus === "active" ? (
          <>
            {" "}
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="flex-end"
              alignItems="center"
              spacing={2}
              className={styles.buttonContainer}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    props.changeStatus(userData.id, "inactive", "edit")
                  }
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    props.changeStatus(userData.id, "inactive", "disable")
                  }
                >
                  Disable
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              container
              justify="flex-end"
              alignItems="center"
              spacing={2}
              className={styles.buttonContainer}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    props.changeStatus(userData.id, "active", "re-activate")
                  }
                >
                  Re-activate
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
  const personalDataBox = (
    <Grid
      item
      container
      direction="column"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item>
        <Paper className={styles.bioContainer}>
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            className={styles.labelHeaders}
          >
            Personal Information
          </Typography>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Birthday:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {userData?.birthday === undefined
                  ? "--"
                  : moment(userData?.birthday.toDate())
                    .format("MM-DD-YYYY")
                    .toString()}
              </Typography>
            </div>
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Phone:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {userData?.phone}
              </Typography>
            </div>
            {userData?.isPhoneVerified ? (
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "green" }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  style={{ color: "red" }}
                />
              </div>
            )}
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Email:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {userData?.email}
              </Typography>
            </div>
            {userData?.isEmailVerified ? (
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="lg"
                  style={{ color: "green" }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  height: "100%",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="lg"
                  style={{ color: "gray" }}
                />
              </div>
            )}
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                            <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                        </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Account Created:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {userData?.dateCreated === undefined
                  ? "User Profile Not completed"
                  : moment(userData?.dateCreated.toDate())
                    .format("MM-DD-YYYY")
                    .toString()}
              </Typography>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
  const bioBox = (
    <Grid
      item
      container
      direction="column"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item>
        <Grid item>
          <Paper className={styles.bioContainer}>
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              className={styles.labelHeaders}
            >
              Wallet Information
            </Typography>
            <div className={styles.paperItems}>
              <Typography variant="body2" gutterBottom>
                <strong>
                  {userData?.wallet ? "$ " + userData.wallet : "$ 0"}
                </strong>
              </Typography>
            </div>
            <Button
              onClick={handleOpen}
              variant="contained"
              color="primary"
              style={{ alignSelf: "end" }}
            >
              Add Funds
            </Button>
          </Paper>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add funds to Wallet
            </Typography>
            <br />
            <TextField
              id="standard-basic"
              error={false}
              required
              variant="outlined"
              placeholder="Amount"
              helperText={""}
              className={styles.input}
              type={"number"}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={(e) => setAmount(e.target.value)}
            />
            <Button
              onClick={() => TopUpWallet(amount)}
              style={{
                padding: 16,
                marginLeft: 8,
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Box>
        </Modal>

        <Paper className={styles.bioContainer}>
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            className={styles.labelHeaders}
          >
            BIO
          </Typography>
          <div>
            <Typography variant="body2" gutterBottom>
              {userData?.bio ? userData.bio : "No data"}
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
  const addressDataBox = (
    <Grid
      item
      container
      direction="column"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Grid item>
        <Paper className={styles.bioContainer}>
          <Typography
            variant="h6"
            display="block"
            gutterBottom
            className={styles.labelHeaders}
          >
            Address Information
          </Typography>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                                <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                            </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Country:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {_addressData?.country}
              </Typography>
            </div>
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                                <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                            </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Street:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {_addressData?.streetAddress}
              </Typography>
            </div>
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                                <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                            </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                State:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {_addressData?.state}
              </Typography>
            </div>
          </div>
          <div className={styles.paperItems}>
            {/* <div className={styles.flagContainer}>
                                <Avatar alt={`${_addressData?.country}`} src={`https://www.countryflags.io/be/flat/64.png`} variant={'square'} className={styles.flag} />
                            </div> */}
            <div style={{ marginRight: 10 }}>
              <Typography variant="body2" gutterBottom>
                Post Code:
              </Typography>
            </div>
            <div>
              <Typography variant="body2" gutterBottom>
                {_addressData?.postalCode}
              </Typography>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
  const additionalInfoBox = (
    <Grid item container direction="row" justify="center">
      <Grid
        item
        container
        direction="column"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item>
          <Paper className={styles.additionalInfoContainer}>
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              className={styles.labelHeaders}
            >
              ADDITIONAL INFORMATION
            </Typography>
            <Typography variant="body2" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              placerat orci nulla pellentesque dignissim enim. Ac turpis egestas
              sed tempus urna et. Egestas purus viverra accumsan in nisl nisi
              scelerisque. Nunc sed blandit libero volutpat sed cras ornare. Est
              placerat in egestas erat. Diam quam nulla porttitor massa id
              neque. Tellus cras adipiscing enim eu turpis egestas. Potenti
              nullam ac tortor vitae purus faucibus ornare suspendisse. Et netus
              et malesuada fames ac turpis egestas sed tempus. Augue interdum
              velit euismod in pellentesque massa placerat. Dictum at tempor
              commodo ullamcorper a lacus. Lacus luctus accumsan tortor posuere
              ac ut consequat semper viverra. Feugiat nibh sed pulvinar proin.
              Nisl pretium fusce id velit ut tortor pretium viverra suspendisse.
              Adipiscing at in tellus integer feugiat scelerisque varius morbi
              enim. Id diam maecenas ultricies mi eget mauris pharetra.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
  const childrenDataBox = (
    <Grid
      item
      container
      direction="column"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Paper className={styles.referencesContainer}>
        <Typography
          variant="h6"
          display="block"
          gutterBottom
          className={styles.labelHeaders}
        >
          Children
        </Typography>
        <Typography variant="body2" gutterBottom>
          {childrenData.length
            ? childrenData.map((ref) => (
              <Paper className={styles.referencesList}>
                <Typography variant="body2" gutterBottom>
                  {ref.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {ref.gradeLevel}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {ref.subject}
                </Typography>
              </Paper>
            ))
            : null}
        </Typography>
      </Paper>
    </Grid>
  );
  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={styles.gridMainContainer}
    >
      {headerBox}
      {personalDataBox}
      {bioBox}
      {userData?.addressData ? addressDataBox : null}
      {userData?.userType === "parent" && userData?.childrenData
        ? childrenDataBox
        : null}
      {
        <Paper className={styles.referencesContainer}>
          <Grid
            container
            direction="row"
            spacing={1}
            className={styles.gridRowContainer}
          >
            <Typography
              variant="h6"
              display="block"
              gutterBottom
              className={styles.labelHeaders}
            >
              Owned Courses
            </Typography>
            <div style={{ width: 20 }} />
            <Button
              onClick={() => {
                if (multiselectRef.current) {
                  UpdateCourses(multiselectRef.current.getSelectedItems());
                }
              }}
              variant="contained"
              color="primary"
              style={{ alignSelf: "end" }}
            >
              Add Course
            </Button>
          </Grid>
          <Typography variant="body2" gutterBottom>
            {/* {props.yorcList.length
            ? props.yorcList.map((ref) => (
              <Paper className={styles.referencesList}>
                <Typography variant="body2" gutterBottom>
                  {ref.title}
                </Typography>

              </Paper>
            ))
            : null} */}
            <Multiselect
              ref={multiselectRef}
              options={props.yorcList} // Options to display in the dropdown
              selectedValues={selectedYorc} // Preselected value to persist in dropdown
              onSelect={(value) => console.log(value)} // Function will trigger on select event
              onRemove={(value) => console.log(`removed ${value}`)} // Function will trigger on remove event
              displayValue="title" // Property name to display in the dropdown options
              placeholder="Select Lesson"
              hidePlaceholder={props.yorcList && props.yorcList.length > 0}
              disablePreSelectedValues
            />
          </Typography>
        </Paper>
      }
    </Grid>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarContainer: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(25),
        height: theme.spacing(25),
      },
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    },
    flagContainer: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    flag: {
      [theme.breakpoints.down("sm")]: {
        width: "30%",
        height: "30%",
      },
      [theme.breakpoints.up("md")]: {
        width: "30%",
        height: "30%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30%",
        height: "30%",
      },
    },
    userDetails: {
      width: "37.5%",
    },
    userDetails2: {
      width: "37.5%",
    },
    singleRoot: {
      flexGrow: 1,
    },
    labelHeaders: {
      color: "rgba(49,64,53,0.5)",
      fontWeight: 650,
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        // backgroundColor: "Purple",
      },
    },
    paperItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 25,
      marginLeft: theme.spacing(2),
    },
    gridMainContainer: {},
    gridRowContainer: {
      paddingBottom: theme.spacing(1),
    },
    informationContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    informationContainer2: {
      padding: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    dropdownContainer: {
      padding: theme.spacing(1),
      height: "150px",
    },
    bioContainer: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //  height: "500px"
    },
    additionalInfoContainer: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // height: "500px"
    },
    subjectsToTeachContainer: {
      padding: theme.spacing(1),
      // height: "500px"
    },
    reviewersContainer: {
      padding: theme.spacing(1),
      //  height: "500px"
    },
    referencesContainer: {
      padding: theme.spacing(1),
    },
    referencesList: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    applicantFilesContainer: {
      padding: theme.spacing(1),
    },
    formControl: {
      //     margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        margin: "10px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    // walletModalBox: {
    //   position: "absolute",
    //   top: "50%",
    //   left: "50%",
    //   transform: "translate(-50%, -50%)",
    //   width: 400,
    //   bgcolor: "background.paper",
    //   border: "2px solid #000",
    //   boxShadow: 24,
    //   p: 4,
    // },
  })
);
