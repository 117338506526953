import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import firebase, { timestamp } from "../components/firebase/FirebaseHelper";
import * as Yup from "yup";
import swal from "sweetalert";

export default function AddSubject() {
  const styles = useStyles();
  const [subKey] = useState("");
  const [subLabel] = useState("");
  const [subAbout] = useState("");
  const [subSummary] = useState("");
  const [subType] = useState("");
  const [catType] = useState("");

  useEffect(() => {}, []);

  const submitData = async (values, { resetForm }) => {
    const user = firebase.auth().currentUser.displayName;

    const tutorInfo = {
      about: values.about,
      key: values.key,
      summary: values.summary,
      label: values.label,
      category: values.cat,
      addedBy: user,
      fixedDateCreated: timestamp(),
    };

    const mentorInfo = {
      about: values.about,
      key: values.key,
      summary: values.summary,
      label: values.label,
      addedBy: user,
      fixedDateCreated: timestamp(),
    };

    console.log("User Name: " + user);
    console.log(timestamp());
    console.log(values.type);

    const store = firebase.firestore();

    await store
      .collection(values.type === "mentor" ? "courses-mentor" : "courses-tutor")
      .doc()
      .set(values.type === "mentor" ? mentorInfo : tutorInfo, {
        merge: true,
      })
      .then(async () => {
        values.about = "";
        values.key = "";
        values.summary = "";
        values.label = "";
        values.cat = "";
        swal("Yazi Admin", "New Subject added successfully", "success");
      })
      .catch((e) => {
        swal(
          "Yazi Admin",
          "Fatal Error Occurred, Contact the Yazi Support Team",
          "error"
        );
        console.log(e);
      });
  };

  return (
    <Formik
      initialValues={{
        key: subKey,
        label: subLabel,
        about: subAbout,
        summary: subSummary,
        type: subType,
        cat: catType,
      }}
      validationSchema={Yup.object().shape({
        key: Yup.string().required(
          "Please provide a unique key id for this Subject"
        ),
        label: Yup.string()
          .required()
          .required("Please provide a name for the Subject"),
        about: Yup.string().required(
          "Please provide an about statement for the Subject"
        ),
        summary: Yup.string().required(
          "Please provide an summary statement for the Subject"
        ),
        type: Yup.string().required("Select a type for this Subject"),
        cat: Yup.string().required("Select a category for this Subject"),
      })}
      onSubmit={submitData}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div className={styles.root}>
          <Grid container className={styles.parentGrid}>
            <Grid container item direction={"column"} xs={12} sm={6}>
              <Grid item>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                  gutterBottom
                >
                  Add a Subject
                </Typography>
              </Grid>
              <Paper style={{ marginBottom: 16 }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                  className={styles.headerAddUser}
                >
                  <Grid item xs={12} ls={12}>
                    <FormControl className={styles.dropdownContainer}>
                      <Select
                        value={values.type}
                        variant="outlined"
                        //  onChange={(e) => setAdminLevel(e.target.value)}
                        name="type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        displayEmpty
                        disableUnderline
                        className={styles.dropdownStyle}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>Select a Subject Type</em>
                        </MenuItem>
                        <MenuItem value={"mentor"}>Mentor</MenuItem>
                        <MenuItem value={"tutor"}>Tutor</MenuItem>
                      </Select>
                      <FormHelperText
                        error={errors.type && touched.type ? true : false}
                      >
                        {errors.type && touched.type ? errors.type : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <Paper style={{ paddingTop: 12 }}>
                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      NAME
                    </Typography>
                  </div>

                  <TextField
                    placeholder="Algebra"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="label"
                    value={values.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.label && touched.label ? true : false}
                    helperText={
                      errors.label && touched.label ? errors.label : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      CATEGORY
                    </Typography>
                  </div>

                  <TextField
                    placeholder="Math"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="cat"
                    value={values.cat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.cat && touched.cat ? true : false}
                    helperText={errors.cat && touched.cat ? errors.cat : null}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      KEY
                    </Typography>
                  </div>

                  <TextField
                    placeholder="SubjectKey"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="key"
                    value={values.key}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.key && touched.key ? true : false}
                    helperText={errors.key && touched.key ? errors.key : null}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      ABOUT
                    </Typography>
                  </div>

                  <TextField
                    placeholder="A little write up about the Subject"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="about"
                    multiline="true"
                    value={values.about}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.about && touched.about ? true : false}
                    helperText={
                      errors.about && touched.about ? errors.about : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>

                <Grid item style={{ paddingBottom: 32 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <Typography
                      variant="H7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      SUMMARY
                    </Typography>
                  </div>

                  <TextField
                    placeholder="A little summary about the Subject"
                    variant="outlined"
                    className={styles.secondInputFields}
                    name="summary"
                    multiline="true"
                    value={values.summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.summary && touched.summary ? true : false}
                    helperText={
                      errors.summary && touched.summary ? errors.summary : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item direction={"column"} xs={12} sm={6}>
            <Button
              style={{
                width: "100%",
                padding: 16,
                marginBottom: 32,
                marginTop: 32,
                color: "white",
                backgroundColor: "green",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              <strong>Add Subject</strong>
            </Button>
          </Grid>
        </div>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      paddingRight: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
      paddingRight: 25,
      paddingBottom: 8,
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
    },
    dropdownContainer: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(80,96,238,1)",
      fontWeight: 600,
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
