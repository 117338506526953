import React, { useState, useEffect } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
//import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
//import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { faBell, faStar } from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/pro-solid-svg-icons/faGraduationCap";
import { faUserGraduate } from "@fortawesome/pro-solid-svg-icons/faUserGraduate";
import { faUserNinja } from "@fortawesome/pro-solid-svg-icons/faUserNinja";
import { faBook } from "@fortawesome/pro-solid-svg-icons/faBook";
import { faBookOpen } from "@fortawesome/pro-solid-svg-icons/faBookOpen";
import { faHeadphones } from "@fortawesome/pro-solid-svg-icons/faHeadphones";
import { faListUl } from "@fortawesome/pro-solid-svg-icons/faListUl";
import { faCoins } from "@fortawesome/pro-solid-svg-icons/faCoins";
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons/faSignOutAlt";
import CardMedia from "@material-ui/core/CardMedia";
// import { AccountCircle, FormatAlignJustify } from '@material-ui/icons';
// import { faUser } from '@fortawesome/pro-duotone-svg-icons';
// import MaterialUITable from "./MaterialUITable"
// import RouterBreadcrumbs from "./RouterBreadcrumbs"
import mainLogo from "../../src/images/yazi-admin.png";
import construction from "../../src/images/construction.png";
import Learners from "../pages/Users/Learners";
// import Mentors from "../pages/Users/Mentors"
// import Tutors from "../pages/Users/Tutors"
import Guides from "../pages/Users/Guides";
import Dashboard from "../pages/Dashboard";
import Admins from "../pages/Users/Admins";
import FormAddUser from "./FormAddUser";
import FormMyTeam from "./FormMyTeam";
import no_profile from "../images/no_profile.png";

import Courses from "../pages/courses/Courses";
//import Subjects from "../pages/Data/Subjects"
//import Login from "../pages/Login"
//import { v4 as uuidv4 } from "uuid";
//import MaterialUILink, { LinkProps } from '@material-ui/core/Link';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { generateUUID } from "../utils/uniqueKeyGenerator";
import firebase, { timestamp } from "../components/firebase/FirebaseHelper";
//import FormSingleView from "../components/FormSingleView"
import useWindowDimensions from "../utils/useWindowDimensions";
import AddELearningCourse from "./AddELearningCourse";
import {
  faUserFriends,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import Subjects from "../pages/Subjects";
import { Box, Button, Grid, Modal, TextField } from "@material-ui/core";
import swal from "sweetalert";
import { StylesContext } from "@material-ui/styles";
import AdminChatList from "../pages/AdminChatList";
import Profile from "../pages/Profile";
import Transactions from "../pages/Transactions";
import Revenue from "../pages/Data/Revenue";
import AddSubject from "../pages/AddSubject";

const drawerWidth = 240;
const capitalizeFirstChar = (str) =>
  str.charAt(0).toUpperCase() + str.substring(1);
export default function ResponsiveDrawer(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [report, setReport] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "50px",
  };
  const [open, setOpen] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState(false);

  const styles = useStyles();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLogoutOpen = () => setOpenLogout(true);
  const handleLogoutClose = () => setOpenLogout(false);
  const { window } = props;
  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isELearningOpen, setIsELearningOpen] = useState(false);
  const [isLiveChatOpen, setIsLiveChatOpen] = useState(false);
  // const [tableName, setTableName] = useState("My Dashboard")
  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  // const [currentUser, setCurrentUser] = useState({})
  // const [selectedUser, setSelectedUser] = useState({});
  //  let params = useParams();
  let { path } = useRouteMatch();
  let location = useLocation();
  let history = useHistory();
  const userData = props.user;
  const [loggedUser, setLoggedUser] = useState(userData);

  const submitReport = async (value) => {
    const reporterName = firebase.auth().currentUser.displayName;
    const reporterEmail = firebase.auth().currentUser.email;

    if (report.trim().length === 0) {
      swal("Yazi Admin", "Problem cannot be empty", "error");
      return;
    }

    const store = firebase.firestore();
    var doc = store.collection("admin-andon-cord");
    doc.add({
      reporter: reporterName,
      reporterEmail: reporterEmail,
      message: report,
      createdOn: timestamp(),
    });

    swal(
      "Yazi Admin",
      "Report sent successfully, we will revert shortly",
      "success"
    );
    handleClose();
  };

  const pagesLink = {
    authHome: `${path}`,
    dashboard: `${path}/dashboard`,
    login: `${path}/login`,
    users: `${path}/users`,
    mentors: `${path}/mentors`,
    tutors: `${path}/tutors`,
    subjects: `${path}/subjects`,
    addSubject: `${path}/subjects/add-subject`,
    transactions: `${path}/transactions`,
    revenues: `${path}/revenues`,
    AUsers: `${path}/ausers`,
    team: `${path}/team`,
    add: `${path}/add`,
    eLearningAll: `${path}/all-eLearning-Course`,
    eLearning: `${path}/add-eLearning-Course`,
    profile: `${path}/profile`,
    adminChatList: `${path}/adminChatList`,
  };

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    setUserDetails(currentUser);
    // console.log(userDetails.displayName);
  }, [userDetails]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logMeOut = () => {
    props.changeLoggedInStatus(false);
    // firebase.auth().signOut().then(function () {
    //     history.push({
    //         pathname: `/`
    //     })
    // }).catch(function (error) {
    //     // An error happened.
    // });
  };
  const yaziBreadCrumbs = (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split("/").filter((x) => x);
        return (
          <Breadcrumbs
            separator={
              <NavigateNextIcon fontSize="inherit" style={{ color: "gray" }} />
            }
            aria-label="Breadcrumb"
          >
            <RouterLink color="inherit" to="/" className={classes.link}>
              <Typography variant="h6">Dashboard</Typography>
            </RouterLink>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <CurrentURLTextTypography
                  variant="h6"
                  key={to}
                  className={classes.currentUrl}
                >
                  {capitalizeFirstChar(value)}
                </CurrentURLTextTypography>
              ) : (
                <RouterLink
                  color="inherit"
                  to={to}
                  key={to}
                  className={classes.link}
                >
                  <WhiteTextTypography variant="h6">
                    {capitalizeFirstChar(value)}
                  </WhiteTextTypography>
                </RouterLink>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <div>
        <img
          src={mainLogo}
          style={{
            width: "100%",
            height: "100%",
            padding: "8% 10% 5%",
          }}
          alt="Admin Logo"
        />
      </div>
      <Divider />
      <List>
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          MY ACCOUNT
        </ListSubheader>
        <ListItem button component={RouterLink} to={pagesLink.authHome}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faStar}
              size="lg"
              style={{ marginLeft: "5%" }}
            />
          </ListItemIcon>
          <ListItemText primary="My Dashboard" />
        </ListItem>
        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <FontAwesomeIcon icon={faAlarmPlus} size="lg" /> : <MailIcon />}
                        </ListItemIcon>

                        <ListItemText primary={text} />
                    </ListItem>
                ))} */}
      </List>
      {loggedUser.adminLevel != "Referrer Partner" ? (
        <>
          <Divider />
          <List>
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              APP ACCOUNTS
            </ListSubheader>
            <ListItem button component={RouterLink} to={pagesLink.users}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  size="lg"
                  style={{ marginLeft: "5%" }}
                />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={RouterLink} to={pagesLink.mentors}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  size="lg"
                  style={{ marginLeft: "5%" }}
                />
              </ListItemIcon>
              <ListItemText primary="Mentors" />
            </ListItem>
            <ListItem button component={RouterLink} to={pagesLink.tutors}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faUserNinja}
                  size="lg"
                  style={{ marginLeft: "5%" }}
                />
              </ListItemIcon>
              <ListItemText primary="Tutors" />
            </ListItem>
          </List>
        </>
      ) : null}
      <Divider />
      <List>
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          DATA
        </ListSubheader>
        {loggedUser.adminLevel != "Referrer Partner" ? (
          <>
            <ListItem button component={RouterLink} to={pagesLink.subjects}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faBook}
                  size="lg"
                  style={{ marginLeft: "5%" }}
                />
              </ListItemIcon>
              <ListItemText primary="Subjects" />
            </ListItem>
            <ListItem button component={RouterLink} to={pagesLink.transactions}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faBookOpen}
                  size="lg"
                  style={{ marginLeft: "5%" }}
                />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </ListItem>
          </>
        ) : null}
        <ListItem button component={RouterLink} to={pagesLink.revenues}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faCoins}
              size="lg"
              style={{ marginLeft: "5%" }}
            />
          </ListItemIcon>
          <ListItemText primary="Revenue" />
        </ListItem>
      </List>
      <Divider />
      {loggedUser.adminLevel === "Administrator" ? (
        <>
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            ADMIN
          </ListSubheader>
          <ListItem button onClick={() => setIsAdminOpen(!isAdminOpen)}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faBuilding}
                size="lg"
                style={{ marginLeft: "5%" }}
              />
            </ListItemIcon>
            <ListItemText primary="My Team" />
            {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={RouterLink}
                to={pagesLink.AUsers}
                className={classes.adminList}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faUsers}
                    size="lg"
                    style={{ marginLeft: "5%" }}
                  />
                </ListItemIcon>
                <ListItemText primary="All User accounts" />
              </ListItem>

              <ListItem
                button
                component={RouterLink}
                to={pagesLink.add}
                className={classes.adminList}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    size="lg"
                    style={{ marginLeft: "5%" }}
                  />
                </ListItemIcon>
                <ListItemText primary="Add a new User" />
              </ListItem>

              <ListItem
                button
                component={RouterLink}
                to={pagesLink.team}
                className={classes.adminList}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    size="lg"
                    style={{ marginLeft: "5%" }}
                  />
                </ListItemIcon>
                <ListItemText primary="My Team" />
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            E-LEARNING
          </ListSubheader>
          <ListItem button onClick={() => setIsELearningOpen(!isELearningOpen)}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faBook}
                size="lg"
                style={{ marginLeft: "5%" }}
              />
            </ListItemIcon>
            <ListItemText primary="E-Learning Actions" />
            {isELearningOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isELearningOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={RouterLink}
                to={pagesLink.eLearning}
                className={classes.adminList}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    size="lg"
                    style={{ marginLeft: "5%" }}
                  />
                </ListItemIcon>
                <ListItemText primary="Add a new e-Learning Course" />
              </ListItem>
              <ListItem
                button
                component={RouterLink}
                to={pagesLink.eLearningAll}
                className={classes.adminList}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    size="lg"
                    style={{ marginLeft: "5%" }}
                  />
                </ListItemIcon>
                <ListItemText primary="View all e-Learning Course" />
              </ListItem>
            </List>
          </Collapse>

          <Divider />
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            Live Chat
          </ListSubheader>
          <ListItem button component={RouterLink} to={pagesLink.adminChatList}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faHeadphones}
                size="lg"
                style={{ marginLeft: "5%" }}
              />
            </ListItemIcon>
            <ListItemText primary="Workhub Chat List" />
          </ListItem>
          <Divider />
        </>
      ) : null}

      <List
        style={{
          background: "green",
          margin: 8,
          borderRadius: 6,
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon icon={faBell} size="lg" color="white" />
          </ListItemIcon>
          <ListItemText onClick={handleOpen} style={{ color: "white" }}>
            Andon Cord
          </ListItemText>
        </ListItem>
      </List>

      <List>
        <ListItem button component={RouterLink} to={pagesLink.profile}>
          {/* <ListItemIcon>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="lg"
              style={{ marginLeft: "5%" }}
            />
          </ListItemIcon>
          <ListItemText primary="Logout" onClick={logMeOut} /> */}
          <Grid container direction="row">
            <Grid item style={{ marginRight: 8 }}>
              <img src={no_profile} alt="" style={{ width: 60, height: 60 }} />
            </Grid>
            <Grid direction="column" item>
              <ListItemText>{userDetails.displayName}</ListItemText>
              <ListItemText
                style={{ color: "green" }}
                onClick={handleLogoutOpen}
              >
                Log out
              </ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      </List>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container style={{ justifyContent: "center" }}>
            <img
              src={mainLogo}
              alt=""
              style={{ height: 64, width: 150, marginBottom: 8 }}
            />
          </Grid>
          <Typography
            variant="h6"
            // id="modal-modal-title"
            component="h7"
            style={{ margin: 16 }}
          >
            Encountered a problem? Report it here
          </Typography>
          <br />
          <Grid container direction="column">
            <Grid item>
              <TextField
                style={{ width: "100%", marginTop: 16 }}
                multiline="true"
                error={false}
                required
                variant="outlined"
                placeholder="Briefly explain your problem"
                helperText={""}
                className={styles.input}
                type={"number"}
                onChange={(e) => setReport(e.target.value)}
                onBlur={(e) => setReport(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => submitReport(report)}
                style={{
                  padding: 16,
                  marginTop: 8,
                }}
                variant="contained"
                color="primary"
              >
                <strong>Send</strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openLogout}
        onClose={handleLogoutClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container style={{ justifyContent: "center" }}>
            <img src={mainLogo} alt="" style={{ height: 64, width: 160 }} />
          </Grid>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h7"
            style={{ paddingBottom: 16 }}
          >
            Are you sure you want to log out?
          </Typography>
          <br />
          <Grid
            container
            direction="row"
            style={{ justifyContent: "space-evenly" }}
          >
            <Grid item>
              <Button
                onClick={logMeOut}
                style={{
                  padding: 16,
                  marginTop: 8,
                  background: "green",
                  color: "white",
                }}
                variant="contained"
                color="primary"
              >
                <strong>Yes</strong>
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => handleLogoutClose()}
                style={{
                  padding: 16,
                  marginTop: 8,
                  background: "red",
                  color: "white",
                }}
              >
                <strong>No</strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <AppBar position="fixed" className={classes.appBar}> */}
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* {TestFirebase} */}
          {yaziBreadCrumbs}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {/* {pages.map((page) => (
                    // Render more <Route>s with the same paths as
                    // above, but different components this time.
                    <Route
                        key={uuidv4()}
                        path={page.path}
                        exact={page.exact}
                    //  children={<page.main />}
                    />
                ))} */}

        <Switch>
          <Route
            path={pagesLink.authHome}
            exact
            render={() => <Dashboard user={userData} />}
          />
          <Route path={pagesLink.users} render={() => <Learners />} />
          <Route
            path={pagesLink.mentors}
            render={() => <Guides key={generateUUID()} guideType="mentor" />}
          />
          <Route
            path={pagesLink.tutors}
            render={() => <Guides key={generateUUID()} guideType="tutor" />}
          />
          <Route path={pagesLink.subjects} exact render={() => <Subjects />} />
          <Route
            path={pagesLink.transactions}
            exact
            render={() => <Transactions />}
          />
          <Route path={pagesLink.revenues} exact render={() => <Revenue />} />
          <Route
            path={pagesLink.adminChatList}
            exact
            render={() => <AdminChatList />}
          />
          <Route path={pagesLink.team} exact render={() => <FormMyTeam />} />
          <Route path={pagesLink.AUsers} exact render={() => <Admins />} />
          <Route path={pagesLink.add} exact render={() => <FormAddUser />} />
          <Route
            path={pagesLink.eLearning}
            exact
            render={() => <AddELearningCourse />}
          />
          <Route path={pagesLink.addSubject} render={() => <AddSubject />} />
          <Route path={pagesLink.eLearningAll} render={() => <Courses />} />
          <Route path={pagesLink.profile} exact render={() => <Profile />} />
        </Switch>
      </main>
    </div>
  );
}

function UnderConstruction() {
  return (
    <div style={{ flex: 1 }}>
      <div style={{ flex: 1, textAlign: "center" }}>
        <Typography>Under Construction</Typography>
        <img src={construction} alt="logo" style={{ width: "50%" }} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      //  flexWrap: "wrap",
      minHeight: "100vh",
      width: "100vw",
      minWidth: "100vw",
      //
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        //  flexShrink: 0
      },
    },
    appBar: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: drawerWidth,
        backgroundColor: "white",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: "white",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      //     backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      flexGrow: 1,
      width: "60vw",
      //   backgroundColor: "red",

      padding: theme.spacing(3),
    },
    breadCrumbsText: {
      color: "white",
    },
    link: {
      textDecoration: "none",
    },
    currentUrl: {
      textDecoration: "none",
      color: "red",
    },
    media: {
      height: 140,
    },
    adminList: {
      paddingLeft: theme.spacing(4),
    },
  })
);
const WhiteTextTypography = withStyles({
  root: {
    // color: "#FFFFFF"
  },
})(Typography);
const CurrentURLTextTypography = withStyles({
  root: {
    color: "gray",
  },
})(Typography);
