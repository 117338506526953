import React, { forwardRef, createRef } from "react";
import { TablePagination } from "@material-ui/core";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { alpha } from "@material-ui/core/styles";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function MaterialUITableV2(props) {
  const isLoading = props.isLoading;
  const listOfUsers = props.listOfUsers;
  const totalCount = props.totalCount;
  const currentPage = props.currentPage;
  const rowsPerPage = props.rowsPerPage;
  // const styles = useStyles();
  const tableRef = createRef();

  const ChangePage = (e, pageNumber) => {
    props.changePage(e, pageNumber);
  };
  return (
    <MaterialTable
      icons={tableIcons}
      title={""}
      tableRef={tableRef}
      options={{ showFirstLastPageButtons: false }} // options={{ sorting: true, filtering: true, showFirstLastPageButtons: false }}
      columns={[
        {
          title: "Avatar",
          field: "avatar",
          render: (rowData) => (
            <div
              style={{
                height: 64,
                width: 64,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ flex: 1, width: 64, height: 64, borderRadius: 50 }}
                src={rowData.avatar}
                alt=""
              />
            </div>
          ),
          filtering: false,
          sorting: false,
        },
        {
          title: "Name",
          field: "fullName",
        },
        {
          title: "Email",
          field: "email",
        },
        {
          title: "Region",
          field: "region",
          render: (rowData) => (
            <span>
              {rowData?.addressData?.country
                ? rowData.addressData.country
                : "--"}
            </span>
          ),
        },
        {
          title: "Date Created",
          field: "fixedDateCreated",
          render: (rowData) => (
            <span>
              {rowData["fixedDateCreated"] ? rowData["fixedDateCreated"] : "--"}
            </span>
          ),
        },
      ]}
      data={listOfUsers}
      components={{
        Pagination: (props) => (
          <TablePagination
            {...props}
            count={totalCount}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            showFirstLastPageButtons={false}
            onPageChange={ChangePage}

          />
        ),
      }}
      onRowClick={(event, rowData, toggleDetailPanel) =>
        props.getDataFromChild(rowData)
      }
      onChangeRowsPerPage={(pageSize) => props.changeRowsPerPage(pageSize)}

      isLoading={isLoading}
    />
  );
}
