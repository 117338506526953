import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
//import Avatar from '@material-ui/core/Avatar';
//import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
//import InputLabel from '@material-ui/core/InputLabel';
//import FormControl from '@material-ui/core/FormControl';
///import Select from '@material-ui/core/Select';
import useWindowDimensions from "../utils/useWindowDimensions";
import TextField from "@material-ui/core/TextField";
//import { height } from "@fortawesome/pro-regular-svg-icons/faSackDollar";
import builders from "../buildersManifest.json";
import buildersImage from "../images/stay_curious.png";
import swal from "sweetalert";
//import firebase from "../components/firebase/FirebaseHelper"
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import firebase from "../components/firebase/FirebaseHelper";

export default function PasswordReset() {
  const { height, width } = useWindowDimensions();
  const styles = useStyles();

  const [email, SetEmail] = useState("");
  const min = 0;
  const max = builders.builderData.length - 1;
  const rand = Math.floor(Math.random() * (max - min + 1)) + min;
  const [quoteIndex, setQuoteIndex] = useState(rand);

  const sendPasswordReset = () => {
    console.log("Email received is: " + email);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log(
          "Password reset link successfully sent to the email address"
        );
        // alert("Password reset link successfully sent to the email address");
        swal(
          "Yazi Admin",
          "Password reset link successfully sent to the email address",
          "success"
        );
        SetEmail("");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Error Code: " + errorCode);
        console.log("Error Message: " + errorMessage);
        // alert("Error Occurred, Please ensure this email account was used in the account creation");
        swal("Yazi Admin", error.message, "error");
      });
  };

  return (
    <form className={styles.singleRoot} noValidate autoComplete="off">
      <Grid container direction="row" className={styles.parentGrid}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
          className={styles.leftColumn}
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
            className={styles.leftColumInner}
          >
            <Grid item className={styles.buttonContainer}>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }}
                gutterBottom
              >
                Password Reset Email
              </Typography>
              <TextField
                error={false}
                required
                variant="outlined"
                id="standard-required"
                placeholder="yaziapp@gmail.com"
                inputProps={{}}
                helperText={""}
                className={styles.input}
                onChange={(e) => SetEmail(e.target.value)}
                onBlur={(e) => SetEmail(e.target.value)}
              />
            </Grid>
            <Grid item className={styles.buttonContainer}>
              <Button
                variant="contained"
                className={styles.SignUpButton}
                onClick={() => sendPasswordReset()}
              >
                <strong>Send</strong>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
          className={styles.rightColumn}
        >
          <Grid item className={styles.cardContainer}>
            <CardMedia
              component="img"
              alt="Builder's Content"
              image={buildersImage}
              title="Builder's Content"
              className={styles.buildersImageContainer}
            />
            <CardContent>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(52,168,83,1)" }}
                gutterBottom
              >
                Builder's Foundation
              </Typography>
              <Typography
                variant="h4"
                display="block"
                style={{ color: "rgba(10,73,12,1)", fontWeight: "bold" }}
                gutterBottom
              >
                {builders.builderData[quoteIndex].title}
              </Typography>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(37,95,40,1)" }}
                gutterBottom
              >
                {builders.builderData[quoteIndex].description}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    singleRoot: {
      flexGrow: 1,
    },
    cardContainer: {
      padding: "5%",
      backgroundColor: "rgba(00,255,168,0)",
    },
    parentGrid: {
      maxHeight: "100%",
      maxWidth: "100%",
      height: "100vh",
    },
    leftColumn: {
      //   height: useWindowDimensions().height
    },
    leftColumInner: {
      // backgroundColor: "red",
    },
    rightColumn: {
      height: "100%",
      backgroundColor: "rgba(00,255,168,0.25)",
    },
    buildersImageContainer: {
      width: "50%",
      opacity: 0.8,
    },
    input: {
      width: "20vw",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        //      height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "center",
        //    backgroundColor: "blue",
      },
      [theme.breakpoints.up("md")]: {
        //    height: `calc(100% - ${headerHeightSingle}px)`,
        textAlign: "left",
        //   backgroundColor: "Red",
      },
      [theme.breakpoints.up("lg")]: {
        //     height: `calc(100% - ${headerHeightSingle}px)`,
        // backgroundColor: "Purple",
      },
    },
    image: {},
    buttonContainer: {
      width: "20vw",
      [theme.breakpoints.down("sm")]: {
        // width: "50vw",
        minWidth: "80%",
      },
      [theme.breakpoints.up("md")]: {
        //  minWidth: "50%"
      },
      [theme.breakpoints.up("lg")]: {
        //  minWidth: "50%"
      },
    },
    imageText: {
      //   backgroundColor: "rgba(52,168,255,1)",
      minWidth: "100%",
      padding: 20,
    },
    SignUpButton: {
      backgroundColor: "rgba(52,168,83,1)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        minWidth: "50%",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "50%",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "50%",
      },
    },
  })
);
