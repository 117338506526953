import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import useWindowDimensions from "../utils/useWindowDimensions";
import { generateUUID } from "../utils/uniqueKeyGenerator";

import { Deferred } from "../utils/DefferedPromise";
import LessonForm from "../pages/courses/LessonForm";
import QuizzesForm from "../pages/courses/QuizzesForm";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import firebase, { taskEvents } from "./firebase/FirebaseHelper";
import moment, { duration } from "moment";
import { useFilePicker } from "use-file-picker";
import { useLocation } from "react-router-dom";
import { Alert } from "@material-ui/lab";

function ViewELearningCourse(props) {
  let location = useLocation();

  const store = firebase.firestore();
  const storage = firebase.storage();
  const courseData = location?.state?.data ? location.state.data : {};
  const [allCourses, setAllCourses] = useState([]);
  const [author, setAuthor] = useState(courseData.author);
  const [title, setTitle] = useState(courseData.title);
  const [description, setDescription] = useState(courseData.description);
  const [teaser, setTeaser] = useState("");

  const [price, setPrice] = useState(courseData.price);
  let _takeAways =
    courseData?.takeAways && courseData?.takeAways.length > 0
      ? courseData.takeAways
      : [];
  const [takeAways, setTakeAways] = useState(_takeAways);
  const [progress, setProgress] = useState(0);
  const [selectedLesson, setSelectedLesson] = useState({});
  const { height, width } = useWindowDimensions();

  const [lessonsData, setLessonsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState("");
  const [isLessonShowing, setIsLessonShowing] = useState(false);
  const [isAddQuizShowing, setIsAddQuizShowing] = useState(false);
  const [changeStatus, setChangeStatus] = useState("add");
  const styles = useStyles();

  const emptyLesson = {
    author: null,
    description: null,
    takeAways: [],
    thumbnail: null,
    isQuizAvailable: false,
    lessonNumber: null,
    title: null,
  };

  useEffect(() => {
    let didCancel = false;

    async function fetchCourses() {
      const tutorCourses = await firebase
        .firestore()
        .collection("courses-tutor")
        .get()
        .then((querySnapshot) => {
          const tempDoc = querySnapshot.docs.map((doc) => {
            const docID = doc.id;
            return { ...doc.data(), id: docID, type: "tutor" };
          });

          return tempDoc;
        })
        .catch((e) => {
          console.log("error", e);
        });

      const mentorCourses = await firebase
        .firestore()
        .collection("courses-mentor")
        .get()
        .then((querySnapshot) => {
          const tempDoc = querySnapshot.docs.map((doc) => {
            const docID = doc.id;
            return { ...doc.data(), id: docID, type: "mentor" };
          });

          return tempDoc;
        })
        .catch((e) => {
          console.log("error", e);
        });
      const temp = [...tutorCourses, ...mentorCourses];
      const sortedData = temp.sort((a, b) => (a.label > b.label ? 1 : -1));
      setAllCourses(sortedData);
      if (!didCancel) {
        // Ignore if we started fetching something else
        //    console.log("change data");
      }
    }

    fetchCourses();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, []);

  useEffect(() => {
    let didCancel = false;

    async function fetchCoursesAvailable() {
      const currentUser = firebase.auth().currentUser;

      await firebase
        .firestore()
        .collection("e-learning-courses")
        .doc(courseData.id)
        .collection("lessons")
        .get()
        .then(
          async (querySnapshot) => {
            const lessonContents = [
              "audios",
              "videos",
              "documents",
              "presentations",
            ];
            if (querySnapshot.empty) {

              //       setIsLoading(false)
              return;
            }
            const lessonsData = await Promise.all(
              //GET lesson per collection
              querySnapshot.docs.map(async (lesson) => {
                //loop the collections
                const lessonID = lesson.id;

                const contentsData = await Promise.all(
                  lessonContents.map(async (content) => {
                    return await firebase
                      .firestore()
                      .collection("e-learning-courses")
                      .doc(courseData.id)
                      .collection("lessons")
                      .doc(lessonID)
                      .collection(content)
                      .get()
                      .then(async (querySnapshot) => {
                        if (querySnapshot.empty) {
                          // console.log("empty ", content);
                          //       setIsLoading(false)
                          return;
                        }
                        //
                        return await Promise.all(
                          querySnapshot.docs.map(async (lessonContent) => {
                            //get url or not
                            return lessonContent.data();
                          })
                        );

                        // if (content === "audios") {
                        //   audios = { ...audios, contents }
                        //   return audios
                        // }
                        // if (content === "videos") {
                        //   videos = { ...videos, contents }
                        //   return videos
                        // }
                        // if (content === "documents") {
                        //   documents = { ...documents, contents }
                        //   return documents
                        // }
                        // if (content === "presentations") {
                        //   presentations = { ...presentations, contents }
                        //   return presentations
                        // }
                      }
                      ).catch((e) => console.log("getting contents error", e))
                  }))

                //TODO disable thumbnail
                //GET the 4 collections
                //check if has contents
                //get file url
                // const weijoThumbnailRef = firebase
                //   .storage()
                //   .refFromURL("gs://ancient-tractor-277102-e-learning")
                //   .child(`${courseData.id}/lessons/${docID}/thumbnail.jpg`);
                // const weijoThumbnailURL =
                //   await weijoThumbnailRef.getDownloadURL();

                return {
                  id: lessonID,
                  audios:
                    contentsData[0] && contentsData[0].length > 0
                      ? contentsData[0]
                      : [],
                  videos:
                    contentsData[1] && contentsData[1].length > 0
                      ? contentsData[1]
                      : [],
                  documents:
                    contentsData[2] && contentsData[2].length > 0
                      ? contentsData[2]
                      : [],
                  presentations:
                    contentsData[3] && contentsData[3].length > 0
                      ? contentsData[3]
                      : [],
                  //    thumbnail: weijoThumbnailURL,
                  ...lesson.data(),
                };
              })
            );

            const sortedData = lessonsData.sort((a, b) =>
              a.lessonNumber > b.lessonNumber ? 1 : -1
            );
            setLessonsData(sortedData);
            setIsUploading(false);
            setIsLoading(false);
          },
          (error) => {
            console.log("this is error", error);
            //    setLoadSchedules(false)
          }
        ).catch((e) => console.log("we have an error", e))
        ;

      if (!didCancel) {
        // Ignore if we started fetching something else
        // console.log("change data");
      }
    }

    fetchCoursesAvailable();
    return () => {
      didCancel = true;
    }; // Remember if we start fetching something else
  }, [isLoading]);

  const [openFileSelector, teaserData] = useFilePicker({
    multiple: false,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    // accept: '.ics,.pdf',
    accept: [".mp4"],
    //  limitFilesConfig: { min: 2, max: 3 }
    // minFileSize: 1, // in megabytes
    // maxFileSize: 1,
    // maxImageHeight: 1024, // in pixels
    // minImageHeight: 1024,
    // maxImageWidth: 768,
    // minImageWidth: 768
    //  readFilesContent: true, // ignores file content
  });

  const [openFileSelectorThumbnail, thumbnailData] = useFilePicker({
    multiple: false,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    // accept: '.ics,.pdf',
    accept: [".jpg", ".jpeg"],
    //  limitFilesConfig: { min: 2, max: 3 }
    // minFileSize: 1, // in megabytes
    // maxFileSize: 1,
    maxImageHeight: 720, // in pixels
    minImageHeight: 720,
    maxImageWidth: 1280,
    minImageWidth: 1280,
    // readFilesContent: false, // ignores file content
  });
  const submitQuizData = async (values, { resetForm }) => {
    const store = firebase.firestore();
    const quizRef = store.collection("e-learning-courses").doc(courseData.id);
    // const quizRef = store.collection("e-learning-courses").doc(courseData.id).collection("quizzes").doc()
    await quizRef.set(values, { merge: true }).then(() => {
      alert("quiz added");
      setIsAddQuizShowing(false);
      resetForm();
    });
  };
  const submitData = async (values, { resetForm }) => {
    const store = firebase.firestore();
    setIsUploading(true);
    let videoDone = false;
    let thumbnailDone = false;
    let _vidData;
    var doc = store.collection("e-learning-courses").doc(courseData.id);

    try {
      if (teaserData.filesContent.length === 1) {
        _vidData = await checkVideoLength(teaserData);
        videoDone = await uploadVideo(
          true,
          teaserData.filesContent[0].content,
          null
        );
      }
      if (thumbnailData.filesContent.length === 1) {
        thumbnailDone = await uploadThumbnail(
          true,
          thumbnailData.filesContent[0].content,
          null
        );
      }
      const lessonDuration = _vidData?.duration ? Number(_vidData.duration) : 0;
      const lessonReadableDuration = _vidData?.readableDuration
        ? _vidData.readableDuration
        : "00:00";

      doc
        .set(
          { ...values, lessonDuration, lessonReadableDuration },
          { merge: true }
        )
        .then(() => {
          setIsUploading(false);
          setIsLoading(true);
        });
    } catch (e) { }
  };
  const uploadVideo = async (uploadCourse, longString, lessonId) => {
    let promise = new Deferred();
    // var ref = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/1080ppp.mp4`);
    // var uploadVideoTask = ref.putString(teaserData.filesContent[0].content, 'data_url')
    var ref = uploadCourse
      ? storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/1080.mp4`)
      : storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/lessons/${lessonId}/1080.mp4`);

    var uploadVideoTask = ref.putString(longString, "data_url");
    uploadVideoTask.on(
      taskEvents.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = roundToTwo(progress);
        console.log("Upload is " + progress + "% done");
        setUploadingText(`Uploading video: ${progress}% done`);
        setProgress(Number(progress));
        //    setProgress(progress)
        switch (snapshot.state) {
          case taskEvents.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case taskEvents.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // uploadVideoTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //     alert("Teaser Video upload Complete!")
        // });
        console.log("Video Upload complete");
        promise.resolve(true);
      }
    );
    return promise;
  };

  const uploadDocument = async (uploadCourse, longString, lessonId) => {
    let promise = new Deferred();
    // var ref = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/1080ppp.mp4`);
    // var uploadVideoTask = ref.putString(teaserData.filesContent[0].content, 'data_url')
    var ref = uploadCourse
      ? storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/document/`)
      : storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/lessons/${lessonId}/document/`);

    var uploadDocumentTask = ref.putString(longString, "data_url");
    uploadDocumentTask.on(
      taskEvents.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = roundToTwo(progress);
        console.log("Upload is " + progress + "% done");
        setUploadingText(`Uploading video: ${progress}% done`);
        setProgress(Number(progress));
        //    setProgress(progress)
        switch (snapshot.state) {
          case taskEvents.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case taskEvents.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // uploadVideoTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //     alert("Teaser Video upload Complete!")
        // });
        console.log("DOcument Upload complete");
        promise.resolve(true);
      }
    );
    return promise;
  };

  const uploadThumbnail = async (uploadCourse, longString, lessonId) => {
    let promise = new Deferred();

    // var refThumb = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/thumbnail1111.jpg`);
    // var uploadThumbnailTask = refThumb.putString(thumbnailData.filesContent[0].content, 'data_url')
    var refThumb = uploadCourse
      ? storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/thumbnail.jpg`)
      : storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(`${courseData.id}/lessons/${lessonId}/thumbnail.jpg`);

    var uploadThumbnailTask = refThumb.putString(longString, "data_url");
    uploadThumbnailTask.on(
      taskEvents.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = roundToTwo(progress);
        setUploadingText(`Uploading thumbnail : ${progress}% done`);
        setProgress(Number(progress));
        switch (snapshot.state) {
          case taskEvents.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case taskEvents.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        // uploadThumbnailTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        //     alert("Thumbnail Upload complete");
        // });
        console.log("Thumbnail Upload complete");
        promise.resolve(true);
      }
    );

    return promise;
  };
  const errorChecker = (error) => {
    console.log("this is the error", error);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }
  const closeModal = (value) => {
    setIsLessonShowing(value);
  };
  const handleModalQuiz = (value) => {
    setIsAddQuizShowing(value);
  };
  const updateLesson = async (lessonData, { resetForm }) => {
    const {
      id,
      _author,
      _description,
      _isQuizAvailable,
      _lessonNumber,
      _takeAways,
      _title,
      selectedTopic,
      fixedPresData,
      fixedDocData,
      fixedAudData,
      fixedVidData,
      lessonQuizData
    } = lessonData;
    const courseId = courseData?.id;
    let lessonId = id ? null : null;

    let _relatedTopic = {};
    const tempSelected = allCourses.filter(
      (topic) => topic.id === selectedTopic
    );

    if (tempSelected.length === 1) {
      _relatedTopic = {
        id: tempSelected[0].id,
        type: tempSelected[0].type,
      };
    }

    const lessonDataObject = {
      author: _author,
      relatedTopicData: _relatedTopic,
      description: _description,
      takeAways: _takeAways,
      isQuizAvailable: _isQuizAvailable,
      lessonNumber: Number(_lessonNumber),
      title: _title
    };

    let lessonRef = lessonId
      ? store
        .collection("e-learning-courses")
        .doc(courseId)
        .collection("lessons")
        .doc(lessonId)
      : store
        .collection("e-learning-courses")
        .doc(courseId)
        .collection("lessons")
        .doc();
    //to generate lesson Id and save necessary data
    await lessonRef.set(lessonDataObject, { merge: true }).then(async () => {
      lessonId = lessonRef.id;
    });
    //If we have lesson Id upload the files to specific collection

    //Status EDIT / NEW know if it has Id
    if (lessonId) {
      setIsUploading(true);
      if (fixedVidData && fixedVidData.length > 0) {
        await Promise.all(fixedVidData.map(async (videoData, index) => {
          return await uploadLessonContent(videoData, null, "videos", lessonId, index)
        }))
      }
      if (fixedAudData && fixedAudData.length > 0) {
        await Promise.all(fixedAudData.map(async (audioData, index) => {
          return await uploadLessonContent(audioData, null, "audios", lessonId, index)
        }))
      }
      if (fixedDocData && fixedDocData.length > 0) {
        await Promise.all(fixedDocData.map(async (docData, index) => {
          return await uploadLessonContent(docData, null, "documents", lessonId, index)
        }))
      }
      if (fixedPresData && fixedPresData.length > 0) {
        await Promise.all(fixedPresData.map(async (presData, index) => {
          return await uploadLessonContent(presData, null, "presentations", lessonId, index)
        }))
      }

      let quizID = lessonQuizData?.lessonQuizID
      let quizRef = quizID ? store
        .collection("e-learning-courses")
        .doc(courseId)
        .collection("lessons")
        .doc(lessonId)
        .collection("quizzes")
        .doc(quizID)
        :
        store
          .collection("e-learning-courses")
          .doc(courseId)
          .collection("lessons")
          .doc(lessonId)
          .collection("quizzes")
          .doc()
      if (Object.keys(lessonQuizData).length === 0) {
        console.log("quiz data empty")
      } else {
        await quizRef.set({ questions: lessonQuizData.questions }, { merge: true })
      }


    } else {
      alert("We don't have a Lesson Id");
    }
    setIsLoading(false);
    setIsUploading(false);
    setIsLessonShowing(false);
  };

  const uploadLessonContent = async (contentData, contentId, contentType, lessonId, index) => {
    const contentIndex = index + 1
    const courseId = courseData?.id
    const { content, name, duration, readableDuration, filenameType } = contentData
    let _contentId = contentId
    let lessonData = {}
    let lezRef = _contentId ?
      store
        .collection("e-learning-courses")
        .doc(courseId)
        .collection("lessons")
        .doc(lessonId)
        .collection(contentType)
        .doc(contentId) :
      store
        .collection("e-learning-courses")
        .doc(courseId)
        .collection("lessons")
        .doc(lessonId)
        .collection(contentType)
        .doc()
    if (contentType === "presentations" || contentType === "documents") {
      lessonData = {
        contentType,
        name,
        contentIndex,
        filenameType
      }
    } else {
      lessonData = {
        contentType,
        name,
        contentIndex,
        duration,
        readableDuration,
        filenameType
      }
    }

    await lezRef
      .set({ ...lessonData, id: lezRef.id }, { merge: true })
      .then(async () => {
        _contentId = lezRef.id;
      })
      .catch((e) => alert("Something went wrong adding collection"));
    //upload to storage

    let promise = new Deferred();
    if (_contentId) {
      // var ref = storage.refFromURL('gs://ancient-tractor-277102-e-learning').child(`${courseData.id}/1080ppp.mp4`);
      // var uploadVideoTask = ref.putString(teaserData.filesContent[0].content, 'data_url')
      var storageRef = storage
        .refFromURL("gs://ancient-tractor-277102-e-learning")
        .child(
          `${courseId}/lessons/${lessonId}/${contentType}/${_contentId}/${name}`
        );

      var uploadVideoTask = storageRef.putString(content, "data_url");
      uploadVideoTask.on(
        taskEvents.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progress = roundToTwo(progress);

          setUploadingText(`Uploading ${contentType}: ${progress}% done`);
          setProgress(Number(progress));
          switch (snapshot.state) {
            case taskEvents.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case taskEvents.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            // ...
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadVideoTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await store
              .collection("e-learning-courses")
              .doc(courseId)
              .collection("lessons")
              .doc(lessonId)
              .collection(contentType)
              .doc(_contentId)
              .update({ contentURL: downloadURL }, { merge: true })
              .catch((e) => alert("Something went wrong adding collection"));
          });

          promise.resolve(true);
        }
      );
    }
    return promise;
  };

  const checkVideoLength = async (videoData) => {
    let promise = new Deferred();
    let _videoData;
    var dur;
    var readableDur;
    if (videoData && videoData.plainFiles.length > 0) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(videoData.plainFiles[0]);

      vid.src = fileURL;
      //TODO get max duration
      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = function () {
        dur = this.duration;

        const secondsToMinutes = (seconds) =>
          Math.floor(seconds / 60) +
          ":" +
          ("0" + Math.floor(seconds % 60)).slice(-2);
        readableDur = secondsToMinutes(dur);
        _videoData = updateDuration(dur, readableDur);
        setTimeout(() => {
          promise.resolve(_videoData);
        }, 1000);
      };

      return promise;
    }
  };
  const updateDuration = (duration, readableDuration) => {
    return { duration, readableDuration };
  };

  const lessonHandler = (lesson) => {
    return (
      <Grid
        key={generateUUID()}
        container
        item
        className={styles.lessonsContainer}
        direction="row"
      >
        <Paper
          style={{
            display: "flex",
            width: "100%",
            paddingTop: 16,
            flexDirection: "row",
          }}
          onClick={() => {
            setSelectedLesson(lesson);
            setIsLessonShowing(true);
            setChangeStatus("edit");
          }}
        >
          {/* <div
            style={{
              display: "flex",
              flex: 3,
            }}
          >
            <img
              src={lesson.thumbnail}
              alt="logo"
              style={{
                marginLeft: 16,
                width: "100px",
                height: "100px",
                backgroundSize: "contain",
              }}
            />
          </div> */}
          <div className={styles.lessonsDescription}>
            <Typography
              variant="h6"
              display="block"
              style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
              gutterBottom
            >
              {lesson.title}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h6"
                display="block"
                style={{ color: "rgba(40,48,120,1)", marginRight: 5 }}
                gutterBottom
              >
                by
              </Typography>
              <Typography
                variant="h"
                display="block"
                style={{ color: "rgba(255,180,120,1)", fontWeight: "bold" }}
                gutterBottom
              >
                {lesson.author}
              </Typography>
            </div>
            <Typography
              noWrap="true"
              variant="body2"
              display="block"
              style={{
                marginRight: 8,
                color: "rgba(40,48,120,1)",
                fontWeight: "bold",
                overflow: "hidden",
              }}
              gutterBottom
            >
              {lesson.description}
            </Typography>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <Formik
      initialValues={{
        author,
        title,
        description,
        teaser,
        thumbnail: "",
        price,
        takeAways,
      }}
      validationSchema={Yup.object().shape({
        author: Yup.string().required("Please input author's name."),
        title: Yup.string().required("Please input the title of this course."),
        description: Yup.string()
          .required("Please input a description")
          .min(30, "Too Short"),

        price: Yup.number().required("Please input a price."),
        takeAways: Yup.array()
          .of(
            Yup.string()
              .trim()
              .min(10, "You can explain it much better.")
              .required("Enter a take away")
          )
          .required("Please add a take away")
          .min(3, "Please add atleast three."),
      })}
      onSubmit={() => submitData}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className={styles.root}>
          {isLessonShowing ? (
            <div className={styles.modal} onClick={() => { }}>
              {
                <LessonForm
                  data={selectedLesson}
                  close={closeModal}
                  update={updateLesson}
                  status={changeStatus}
                  courses={allCourses}
                />
              }
            </div>
          ) : null}
          {isAddQuizShowing ? (
            <div className={styles.modal} onClick={() => { }}>
              <QuizzesForm
                manageModal={handleModalQuiz}
                submitQuiz={submitQuizData}
              />
            </div>
          ) : null}
          {isUploading ? (
            <div className={styles.modalLoading}>
              {
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    maxWidth: "80%",
                    height: "100%",
                    left: "15%",
                    right: "10%",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                >
                  <Paper className={styles.loadingContainer}>
                    <div style={{ display: "flex", flex: 3, height: "20%" }}>
                      <Typography
                        display="block"
                        className={styles.secondLabels}
                        gutterBottom
                      >
                        {uploadingText}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 6,
                        height: "80%",
                        paddingRight: 20,
                        paddingLeft: 20,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          height: "1.5vh",
                          width: "100%",
                          alignSelf: "center",
                          borderRadius: 25,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "rgba(100,200,100,1)",
                            width: `${progress}%`,
                            borderRadius: 25,
                          }}
                        />
                      </div>
                    </div>
                  </Paper>
                </div>
              }
            </div>
          ) : null}

          <Grid container spacing={2} className={styles.parentGrid}>
            <Grid container item direction={"column"} lg={6}>
              <Grid
                container
                item
                alignItems="center"
                alignContent="center"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    display="block"
                    style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                    gutterBottom
                  >
                    View e-Learning Course
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsAddQuizShowing(true)}
                  >
                    <strong>Add Quiz</strong>
                  </Button>
                </Grid>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper style={{ paddingTop: 12, paddingBottom: 12 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      AUTHOR
                    </Typography>
                  </div>

                  <TextField
                    placeholder="Enter author's name"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="author"
                    value={values.author}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.author && touched.author ? true : false}
                    helperText={
                      errors.author && touched.author ? errors.author : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "calc(2px + 2vmin)",
                      },
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper style={{ paddingTop: 12, paddingBottom: 12 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      TITLE
                    </Typography>
                  </div>

                  <TextField
                    placeholder="Enter title of the e-Learning course"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="title"
                    value={values.title}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.title && touched.title ? true : false}
                    helperText={
                      errors.title && touched.title ? errors.title : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "calc(2px + 2vmin)",
                      },
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      DESCRIPTION
                    </Typography>
                  </div>

                  <TextField
                    placeholder="Enter a description for the course."
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.description && touched.description ? true : false
                    }
                    helperText={
                      errors.description && touched.description
                        ? errors.description
                        : null
                    }
                    InputLabelProps={{ shrink: true }}
                    multiline={true}
                    rowsMax={Infinity}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "calc(2px + 2vmin)",
                      },
                    }}
                  />
                </Paper>
              </Grid>
              <FieldArray
                name="takeAways"
                validateOnChange
              >{(arrayHelpers) => {
                const ErrorMessage = ({ name }) => (
                  <Field name="takeAways[0]">
                    {({ field, form, meta }) => {
                      const error = getIn(form.errors, name);
                      const touch = getIn(form.touched, name);
                      const fixedError =
                        error && error.length > 1 ? error : null;
                      return touch && error ? (
                        <Typography
                          style={{
                            fontSize: 12,
                            fontStyle: "italic",
                            paddingLeft: 25,
                            color: "red",
                          }}
                        >
                          {fixedError}
                        </Typography>
                      ) : (
                        ""
                      );
                    }}
                  </Field>
                );
                return (
                  <Grid item className={styles.fieldItems} style={{}}>
                    <Paper
                      style={{
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingRight: 24,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          style={{ marginTop: 8 }}
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          What they can learn about the course
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignSelf: "flex-end",
                            alignItems: "flex-end",
                            alignContent: "flex-end",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(values.takeAways.length, "")
                            } // insert an empty string at a position
                            style={{
                              height: "calc(12px + 2vmin)",
                              width: "calc(12px + 2vmin)",
                              marginRight: 10,
                              color: "green",
                              fontSize: "calc(12px + 1vmin)",
                              fontWeight: "bolder",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {values.takeAways && values.takeAways.length > 0
                        ? values.takeAways.map((takeAway, index) => (
                          <Grid
                            container
                            direction="row"
                            key={`takeAway-${index}`}
                          >
                            <Grid item style={{ flex: 1 }}>
                              <TextField
                                placeholder="They can learn.. .."
                                //  error={}
                                //     helperText="Incorrect entry."
                                className={styles.secondInputFields}
                                name={`takeAways[${index}]`}
                                //  onChange={(e) => inputCheck(e, "password")}
                                value={takeAway}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "calc(2px + 2vmin)",
                                  },
                                }}
                              />
                              <ErrorMessage name={`takeAways[${index}]`} />
                            </Grid>

                            <Grid
                              item
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }} // remove a friend from the list
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "red",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          </Grid>
                        ))
                        : null}
                      {values.takeAways.length > 0 &&
                        values.takeAways.length < 3 ? (
                        <Typography
                          style={{
                            fontSize: 12,
                            fontStyle: "italic",
                            paddingLeft: 25,
                            color: "red",
                          }}
                        >
                          {`Please add ${3 - values.takeAways.length} more`}
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            fontSize: 12,
                            fontStyle: "italic",
                            paddingLeft: 25,
                            color: "red",
                          }}
                        >
                          {typeof errors.takeAways === "string"
                            ? errors.takeAways
                            : null}
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                );
              }}
              </FieldArray>
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      TEASER
                    </Typography>
                  </div>
                  <Grid
                    container
                    item
                    direction="row"
                    style={{ paddingLeft: 25 }}
                  >
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter teaser URL"
                        //  error={}
                        //     helperText="Incorrect entry."
                        name="teaser"
                        value={
                          teaserData.loading
                            ? "Loading...."
                            : teaserData.filesContent.length > 0 &&
                              teaserData.plainFiles.length > 0
                              ? teaserData.plainFiles[0].name
                              : teaserData.errors.length > 0
                                ? "Something went wrong please try again."
                                : ""
                        }
                        //  onChange={(e) => inputCheck(e, "password")}
                        style={{ minWidth: "100%" }}
                        //  onChange={handleChange}
                        //   onBlur={handleBlur}
                        error={teaserData.errors.length ? true : false}
                        helperText={
                          teaserData.errors.length
                            ? errorChecker(teaserData.errors)
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "calc(2px + 2vmin)",
                          },
                        }}
                      />
                    </Grid>
                    <Button
                      style={{ padding: 12, marginTop: 8 }}
                      color="primary"
                      variant="contained"
                      onClick={() => openFileSelector()}
                    >
                      <strong>Choose a .mp4 file</strong>
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                  }}
                >
                  {" "}
                  <Grid container item direction="row">
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.secondLabels}
                      gutterBottom
                    >
                      THUMBNAIL
                    </Typography>
                    <Typography
                      variant="h7"
                      display="block"
                      className={styles.reuirementLables}
                      gutterBottom
                    >
                      (Only 1280 x 720 .jpg accepted)
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    style={{ paddingLeft: 25 }}
                  >
                    <Grid item style={{ width: "100%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter thumbnail URL"
                        name="thumbnail"
                        value={
                          thumbnailData.loading
                            ? "Loading...."
                            : thumbnailData.filesContent.length > 0 &&
                              thumbnailData.plainFiles.length > 0
                              ? thumbnailData.plainFiles[0].name
                              : thumbnailData.errors.length > 0
                                ? "Something went wrong please try again."
                                : ""
                        }
                        //  onChange={(e) => inputCheck(e, "password")}
                        style={{ minWidth: "100%" }}
                        //  onChange={handleChange}
                        //   onBlur={handleBlur}
                        error={thumbnailData.errors.length ? true : false}
                        helperText={
                          thumbnailData.errors.length
                            ? errorChecker(thumbnailData.errors)
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "calc(2px + 2vmin)",
                          },
                        }}
                      />
                    </Grid>
                    <Button
                      style={{
                        padding: 12,
                        marginTop: 8,
                      }}
                      color="primary"
                      variant="contained"
                      onClick={() => openFileSelectorThumbnail()}
                    >
                      <strong>Choose a thumbnail</strong>
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item className={styles.fieldItems}>
                <Paper
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                  }}
                >
                  {" "}
                  <Typography
                    variant="h7"
                    display="block"
                    className={styles.secondLabels}
                    gutterBottom
                  >
                    PRICE ($)
                  </Typography>
                  <TextField
                    placeholder="Enter the price"
                    //  error={}
                    //     helperText="Incorrect entry."
                    className={styles.secondInputFields}
                    name="price"
                    value={values.price}
                    //  onChange={(e) => inputCheck(e, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.price && touched.price ? true : false}
                    helperText={
                      errors.price && touched.price ? errors.price : null
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "calc(2px + 2vmin)",
                      },
                    }}
                  />
                </Paper>
              </Grid>

              <Grid item className={styles.secondAddUser}>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "green",
                    color: "white",
                    padding: 12,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  className={styles.addUserButtonTwo}
                >
                  Update Course
                </Button>
              </Grid>

              {/* <Grid item className={styles.fieldItems}>
                        <Typography variant="h6" display="block" className={styles.secondLabels} gutterBottom>
                            Add User
      </Typography>
                        <TextField  placeholder="Type Name Here"
                            className={styles.secondInputFields}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    fontSize: "calc(12px + 2.5vmin)"
                                }
                            }} />
                    </Grid> */}
              {/* {width < 800 ? (
                <Grid item xs={12} className={styles.secondAddUser}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className={styles.addUserButtonTwo}
                  >
                    Update Course
                  </Button>
                </Grid>
              ) : (
                <Grid item className={styles.secondAddUser}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className={styles.addUserButtonTwo}
                  >
                    Update Course
                  </Button>
                </Grid>
              )} */}
              {/* <Grid item xs={12} className={styles.secondAddUser}>

                                <Button variant="contained" onClick={checkUser} className={styles.addUserButtonTwo} >
                                    check user
      </Button>
                            </Grid> */}
            </Grid>

            <Grid
              style={{ marginTop: 4 }}
              container
              direction="column"
              lg={6}
              spacing={1}
            >
              <Grid container item direction="row" style={{ marginBottom: 12 }}>
                <Typography
                  variant="h5"
                  display="block"
                  style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                  gutterBottom
                >
                  Available Lessons
                </Typography>
                <Button
                  style={{ marginBottom: 4 }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setSelectedLesson(emptyLesson);
                    setIsLessonShowing(true);
                    setChangeStatus("add");
                  }}
                  className={styles.addLessonButtonTwo}
                >
                  <strong>Add Lesson</strong>
                </Button>
              </Grid>

              {lessonsData.length > 0 &&
                lessonsData.map((lesson) => lessonHandler(lesson))}
            </Grid>
          </Grid>
        </div>
      )}
    </Formik>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      //  backgroundColor: "red"
    },
    parentGrid: {
      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
    },
    reuirementLables: {
      color: "rgba(205,100,100,1)",
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
    },
    addLessonButtonTwo: {
      marginLeft: 20,

      width: "20%",
    },
    dropdownContainer: {
      width: "20%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(80,96,238,1)",
      fontWeight: 600,
    },
    lessonsContainer: {
      maxHeight: "15%",
      width: "100%",
      marginBottom: "2.5%",
      [theme.breakpoints.down("md")]: {
        maxHeight: "5%",
      },
      [theme.breakpoints.up("md")]: {
        maxHeight: "10%",
      },
    },
    lessonsDescription: {
      display: "flex",
      flex: 6,
      maxWidth: "60%",
      flexDirection: "column",
      padding: 5,
      [theme.breakpoints.down("md")]: {
        minHeight: "25%",
      },
      [theme.breakpoints.up("md")]: {
        //  maxHeight: "10%",
        minWidth: "75%",
      },
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
    loadingContainer: {
      flex: 1,
      height: "10%",
      maxWidth: "50%",
      alignSelf: "center",
      paddingTop: 20,
      [theme.breakpoints.down("sm")]: {
        height: "12%",
      },
    },
    modal: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.5)",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      justifyContent: "center",
      zIndex: 900,
    },
    modalLoading: {
      display: "flex",
      backgroundColor: "rgba(0,0,0,0.3)",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      justifyContent: "center",
      zIndex: 1000,
    },
    modalForm: {
      width: "50%",
    },
  })
);
export default ViewELearningCourse;
