import React, { useState, useEffect, useLayoutEffect } from "react";
import { Formik, Field, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

//toggle button
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";

import { generateUUID } from "../../utils/uniqueKeyGenerator";

import { Deferred } from "../../utils/DefferedPromise";
import { useFilePicker } from "use-file-picker";
import { duration } from "moment";
import { Divider } from "@material-ui/core";
import QuizzesForm from "./QuizzesForm";
function LessonForm(props) {
  const emptyLessonData = {
    title: "",
    description: "",
    teaser: "",
    thumbnail: "",
    takeAways: [],
    presentations: [],
    documents: [],
    audios: [],
    videos: [],
  };

  const data =
    Object.keys(props.data).length === 0 && props.data.constructor === Object
      ? emptyLessonData
      : props.data;
  const {
    author,
    title,
    description,
    teaser,
    thumbnail,
    takeAways,
    isQuizAvailable,
    relatedTopicData,
    lessonNumber,
    id,
    presentations,
    documents,
    audios,
    videos,
  } = data;

  var videoData = {};
  const status = props.status;
  const courses = props?.courses ? props.courses : [];
  const topicId =
    relatedTopicData && relatedTopicData?.id ? relatedTopicData.id : "";
  const [allCourses, setAllCourses] = useState(courses);
  const [_isQuizAvailable, setIsQuizAvailable] = useState(isQuizAvailable);
  const [_author, setAuthor] = useState(author);
  const [_title, setTitle] = useState(title);
  const [_description, setDescription] = useState(description);
  const [_teaser, setTeaser] = useState(teaser);
  const [_thumbnail, setThumbnail] = useState(thumbnail);
  const [_takeAways, setTakeAways] = useState(takeAways);
  const [_lessonNumber, setLessonNumber] = useState(Number(lessonNumber));
  const [_duration, setDuration] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(topicId);
  const [_presentations, setPresentations] = useState(presentations)
  const [_documents, setDocuments] = useState(documents)
  const [_audios, setAudios] = useState(audios)
  const [_videos, setVideos] = useState(videos)

  const [lessonQuizData, setLessonQuizData] = useState({})
  const [lessonQuizID, setLessonQuizID] = useState("")
  const [showQuizModal, setShowQuizModal] = useState(false)
  const styles = useStyles();
  const { height, width } = useWindowDimensions();

  const [openVidSelector, vidsData] = useFilePicker({
    multiple: true,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".mp4", ".m4v"],
    maxFileSize: 1000 //check max size>?
  });
  const [openAudioSelector, audioData] = useFilePicker({
    multiple: true,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".mp3", ".wav"],
  });
  const [openDocSelector, docData] = useFilePicker({
    multiple: true,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    // accept: [".doc", ".docx", ".txt", ".rtf", ".odt"],
    accept: [".pdf"],
  });
  const [openPresentSelector, presentationData] = useFilePicker({
    multiple: true,
    readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    //accept: [".ppt", ".pptx", ".odp", ".xls", ".xlsx", ".ods"],
    accept: [".pdf"],
  });
  // const [openFileSelectorThumbnail, thumbnailData] = useFilePicker({
  //   multiple: false,
  //   readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
  //   // accept: '.ics,.pdf',
  //   accept: [".jpg", ".jpeg"],
  //   imageSizeRestrictions: {
  //     minWidth: 1280,
  //     maxWidth: 1280,
  //     minHeight: 720,
  //     maxHeight: 720,
  //   },
  // });

  useEffect(() => {
    if (audioData.plainFiles.length > 0) {
      setAudios(audioData.plainFiles)
    }
    //do changes here
  }, [audioData.plainFiles])
  useEffect(() => {
    if (vidsData.plainFiles.length > 0) {
      setVideos(vidsData.plainFiles)
    }
  }, [vidsData.plainFiles])
  useEffect(() => {
    if (presentationData.plainFiles.length > 0) {
      setPresentations(presentationData.plainFiles)
    }
  }, [presentationData.plainFiles])
  useEffect(() => {
    if (docData.plainFiles.length > 0) {
      setDocuments(docData.plainFiles)
    }
  }, [docData.plainFiles])
  const errorChecker = (error) => {
    console.log("this is the error", error);
  };
  const errorCheckerPicker = (err) => {
    return (
      <div style={{ paddingLeft: 25 }}>
        <Typography style={{ color: "red", fontStyle: "italic" }}>
          {err.fileSizeTooSmall && 'File size is too small!'}
          {err.fileSizeToolarge && 'File size is too large!'}
          {err.readerError && 'Problem occured while reading file!'}
          {err.maxLimitExceeded && 'Too many files'}
          {err.minLimitNotReached && 'Not enought files'}
        </Typography>
      </div>
    )
  }
  const checkFileLength = async (plainFiles) => {
    let promise = new Deferred();
    let _fileData;
    var dur;
    var readableDur;
    if (plainFiles) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(plainFiles);

      vid.src = fileURL;
      //TODO get max duration
      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = function () {
        dur = this.duration;

        const secondsToMinutes = (seconds) =>
          Math.floor(seconds / 60) +
          ":" +
          ("0" + Math.floor(seconds % 60)).slice(-2);
        readableDur = secondsToMinutes(dur);
        _fileData = updateDuration(dur, readableDur);
        setTimeout(() => {
          promise.resolve(_fileData);
        }, 1000);
      };
      return promise;
    } else {
      promise.resolve({});
      return promise;
    }
  };
  const checkVideoLength = async (videoData) => {
    let promise = new Deferred();
    let _videoData;
    var dur;
    var readableDur;
    if (videoData && videoData.plainFiles.length > 0) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(videoData.plainFiles[0]);

      vid.src = fileURL;
      //TODO get max duration
      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = function () {
        dur = this.duration;

        const secondsToMinutes = (seconds) =>
          Math.floor(seconds / 60) +
          ":" +
          ("0" + Math.floor(seconds % 60)).slice(-2);
        readableDur = secondsToMinutes(dur);
        _videoData = updateDuration(dur, readableDur);
        setTimeout(() => {
          promise.resolve(_videoData);
        }, 1000);
      };
      return promise;
    } else {
      promise.resolve({});
      return promise;
    }
  };
  const updateDuration = (duration, readableDuration) => {
    return { duration, readableDuration };
  };
  const handleQuizAvailability = (event, nextValue) => {
    if (nextValue) {
      setShowQuizModal(nextValue)
    }
    setIsQuizAvailable(nextValue);
  };
  const fixAudioVideoData = async (contentData, contentFiles, fileData) => {

    const tempDoc = await Promise.all(fileData.map(async (files, index) => {
      const deets = contentData.filter((x) => x.name === files.name)
      const deetaType = contentFiles.filter((x) => x.name === files.name)

      const durationData = await checkFileLength(files)
      return {
        ...deets[0],
        ...durationData,
        filenameType: deetaType[0].type
      }
    }))
    return tempDoc
  }
  const submitData = async (lesson, { resetForm }) => {
    let fixedVidData = []
    let fixedAudData = []
    let fixedDocData = []
    let fixedPresData = []
    let availableLessons = {}
    let newLessonData = {}
    //Check if we have audio or video files to check the duration
    if (_videos && _videos.length > 0) {
      fixedVidData = await fixAudioVideoData(vidsData.filesContent, vidsData.plainFiles, _videos)
    }
    if (_audios && _audios.length > 0) {
      fixedAudData = await fixAudioVideoData(audioData.filesContent, audioData.plainFiles, _audios)
    }
    if (_documents && _documents.length > 0) {
      fixedDocData = _documents.map((doc) => {
        const deeta = docData.filesContent.filter((x) => x.name === doc.name)
        const deetaType = docData.plainFiles.filter((x) => x.name === doc.name)

        return {
          ...deeta[0],
          filenameType: deetaType[0].type
        }
      })
    }
    if (_presentations && _presentations.length > 0) {
      fixedPresData = _presentations.map((pres) => {
        const deeta = presentationData.filesContent.filter((x) => x.name === pres.name)
        const deetaType = presentationData.plainFiles.filter((x) => x.name === pres.name)
        return {
          ...deeta[0],
          filenameType: deetaType[0].type
        }
      })
    }
    if (fixedVidData.length > 0) {
      availableLessons = { ...availableLessons, fixedVidData }
    }
    if (fixedAudData.length > 0) {
      availableLessons = { ...availableLessons, fixedAudData }
    }
    if (fixedDocData.length > 0) {

      availableLessons = { ...availableLessons, fixedDocData }
    }
    if (fixedPresData.length > 0) {
      availableLessons = { ...availableLessons, fixedPresData }
    }

    if (status === "edit") {
      //edit we have a doc id for the collection
      newLessonData = { id, ...lesson, ...availableLessons, lessonQuizData }
    } else if (status === "add") {

      newLessonData = { ...lesson, ...availableLessons, lessonQuizData }
    }
    props.update(newLessonData, { resetForm });
  };


  return (
    <Formik
      initialValues={{
        _author,
        _title,
        _description,
        _teaser,
        _lessonNumber,
        _isQuizAvailable,
        _thumbnail,
        _takeAways: _takeAways ? _takeAways : [],
        selectedTopic,
      }}
      validationSchema={Yup.object().shape({
        _author: Yup.string().required("Please input author's name."),
        _title: Yup.string().required("Please input the title of this course."),
        _description: Yup.string()
          .required("Please input a description")
          .min(30, "Too Short"),
        _lessonNumber: Yup.number()
          .required("Please enter a number")
          .min(1, "Try a number greater than 0")
          .max(100, "Try a number lower than 100"),
        _takeAways: Yup.array()
          .of(
            Yup.string()
              .trim()
              .min(10, "You can explain it much better.")
              .required("Enter a take away")
          )
          .required("Please add a take away")
          .min(3, "Please add atleast three."),
        selectedTopic: Yup.string().required("Please select a Topic"),
      })}
      onSubmit={submitData}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Paper className={styles.root}>
          {
            showQuizModal ? <QuizzesForm
              manageModal={(value) => setShowQuizModal(value)}
              submitQuiz={(values, { resetForm }) => {
                if (values?.questions && values?.questions.length > 0) {
                  let questions = values.questions
                  setLessonQuizData({
                    lessonQuizID,
                    questions
                  })
                  setIsQuizAvailable(true)
                  setShowQuizModal(false)
                }
              }}
            />
              :

              <Grid container spacing={2} className={styles.parentGrid}>
                <Grid container item direction={"column"}>
                  <Grid item>
                    <Typography
                      variant="h5"
                      display="block"
                      style={{ color: "rgba(40,48,120,1)", fontWeight: "bold" }}
                      gutterBottom
                    >
                      Add Lesson in e-Learning Course
                    </Typography>
                  </Grid>
                  {/* <Grid style={{ marginTop: 16 }} container item direction={"row"}>
                <Grid container item lg={5}>
                  {thumbnailData.filesContent.length ? (
                    <img
                      alt=""
                      src={thumbnailData.filesContent[0].content}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        maxWidth: "100%",
                      }}
                    />
                  ) : (
                    <img
                      alt=""
                      src={_thumbnail}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        maxWidth: "100%",
                      }}
                    />
                  )}
                </Grid>
                <Grid container item lg={7} style={{ paddingLeft: 25 }}>
                  <Grid
                    container
                    item
                    direction="row"
                    style={{ marginBottom: 12 }}
                  >
                    <Grid item style={{ width: "70%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Select a file for the Thumbnail 1280x720 jpg file only"
                        name="_thumbnail"
                        style={{ width: "100%" }}
                        value={
                          thumbnailData.loading
                            ? "Loading...."
                            : thumbnailData.filesContent.length > 0 &&
                              thumbnailData.plainFiles.length > 0
                              ? thumbnailData.plainFiles[0].name
                              : thumbnailData.errors.length > 0
                                ? "Something went wrong please try again."
                                : ""
                        }
                        error={thumbnailData.errors.length ? true : false}
                        helperText={
                          thumbnailData.errors.length
                            ? errorChecker(thumbnailData.errors)
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18",
                          },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openFileSelectorThumbnail()}
                        style={{
                          width: "100%",
                          height: "80%",
                          marginLeft: 8,
                          marginTop: 8,
                        }}
                      >
                        <strong>Choose a thumbnail</strong>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row">
                    <Grid item style={{ width: "70%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Select a video file for the Lesson"
                        name="_lessonVideo"
                        style={{ width: "100%" }}
                        value={
                          teaserData.loading
                            ? "Loading...."
                            : teaserData.filesContent.length > 0 &&
                              teaserData.plainFiles.length > 0
                              ? teaserData.plainFiles[0].name
                              : teaserData.errors.length > 0
                                ? "Something went wrong please try again."
                                : ""
                        }
                        error={teaserData.errors.length ? true : false}
                        helperText={
                          teaserData.errors.length
                            ? errorChecker(teaserData.errors)
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                          },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => openFileSelector()}
                        style={{
                          width: "100%",
                          height: "80%",
                          marginLeft: 8,
                          marginTop: 4,
                        }}
                      >
                        <strong>Choose a .mp4 file</strong>
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{ marginTop: 12 }}
                    container
                    item
                    direction="row"
                  >
                    <Grid item style={{ width: "70%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Select a study lesson file"
                        name="_lessonFile"
                        style={{ width: "100%" }}
                        value={
                          lessonFileData.loading
                            ? "Loading...."
                            : lessonFileData.filesContent.length > 0 &&
                              lessonFileData.plainFiles.length > 0
                              ? lessonFileData.plainFiles[0].name
                              : lessonFileData.errors.length > 0
                                ? "Something went wrong please try again."
                                : ""
                        }
                        error={lessonFileData.errors.length ? true : false}
                        helperText={
                          lessonFileData.errors.length
                            ? errorChecker(lessonFileData.errors)
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                          },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        // onClick={() => openFileSelectorLessonData()}
                        onClick={() => testSubmitMultipe()}
                        style={{
                          width: "100%",
                          height: "80%",
                          marginLeft: 8,
                          marginTop: 4,
                        }}
                      >
                        <strong>Choose file</strong>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
                  <Grid item className={styles.fieldItems}>
                    <Paper style={{ paddingBottom: 12, paddingRight: 24 }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          TITLE
                        </Typography>
                      </div>

                      <TextField
                        style={{ marginBottom: 12 }}
                        variant="outlined"
                        placeholder="Enter title of the e-Learning course"
                        //  error={}
                        //     helperText="Incorrect entry."
                        className={styles.secondInputFields}
                        name="_title"
                        value={values._title}
                        //  onChange={(e) => inputCheck(e, "password")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors._title && touched._title ? true : false}
                        helperText={
                          errors._title && touched._title ? errors._title : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                            color: "rgba(40,48,120,1)",
                          },
                        }}
                      />

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          AUTHOR
                        </Typography>
                      </div>

                      <TextField
                        style={{ marginBottom: 12 }}
                        variant="outlined"
                        placeholder="Enter author's name"
                        //  error={}
                        //     helperText="Incorrect entry."
                        className={styles.secondInputFields}
                        name="_author"
                        value={values._author}
                        //  onChange={(e) => inputCheck(e, "password")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors._author && touched._author ? true : false}
                        helperText={
                          errors._author && touched._author ? errors._author : null
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                            color: "rgba(40,48,120,1)",
                          },
                        }}
                      />

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          DESCRIPTION
                        </Typography>
                      </div>

                      <TextField
                        style={{ marginBottom: 12 }}
                        variant="outlined"
                        placeholder="Enter a description for the course."
                        //  error={}
                        //     helperText="Incorrect entry."
                        className={styles.secondInputFields}
                        name="_description"
                        value={values._description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors._description && touched._description ? true : false
                        }
                        helperText={
                          errors._description && touched._description
                            ? errors._description
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        multiline={true}
                        rowsMax={Infinity}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                            color: "rgba(40,48,120,1)",
                          },
                        }}
                      />

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          Lesson Number
                        </Typography>
                      </div>

                      <TextField
                        type={"number"}
                        variant="outlined"
                        placeholder="Enter lesson Number"
                        //  error={}
                        //     helperText="Incorrect entry."
                        className={styles.secondInputFields}
                        name="_lessonNumber"
                        value={values._lessonNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors._lessonNumber && touched._lessonNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors._lessonNumber && touched._lessonNumber
                            ? errors._lessonNumber
                            : null
                        }
                        InputLabelProps={{ shrink: true }}
                        multiline={true}
                        rowsMax={Infinity}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "18px",
                            color: "rgba(40,48,120,1)",
                          },
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignSelf: "center",
                          minHeight: 70,
                        }}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                          style={{ alignSelf: "center" }}
                        >
                          Quizzes Available
                        </Typography>
                        <ToggleButtonGroup
                          orientation="horizontal"
                          value={_isQuizAvailable}
                          exclusive
                          onChange={handleQuizAvailability}
                          style={{ marginLeft: 20, alignSelf: "center" }}
                        >
                          <ToggleButton
                            value={true}
                            aria-label="list"
                            style={{ height: "80%" }}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="lg"
                              color={"rgba(100,185,100,1)"}
                            />
                          </ToggleButton>
                          <ToggleButton
                            value={false}
                            aria-label="module"
                            style={{ height: "80%" }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="lg"
                              color={"rgba(185,100,100,1)"}
                            />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item className={styles.fieldItems}>
                    <Paper style={{ paddingBottom: 8 }}>
                      <Typography
                        variant="h6"
                        display="block"
                        className={styles.secondLabels}
                        gutterBottom
                        style={{ alignSelf: "center" }}
                      >
                        Related Tutor / Mentor Topics
                      </Typography>
                      <FormControl className={styles.dropdownContainer}>
                        <Select
                          variant="outlined"
                          value={values.selectedTopic ?? ""}
                          //  onChange={(e) => setAdminLevel(e.target.value)}
                          name="selectedTopic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          displayEmpty
                          disableUnderline
                          className={styles.dropdownStyle}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>Select a related Topic</em>
                          </MenuItem>
                          {allCourses.length > 0
                            ? allCourses.map((course) => (
                              <MenuItem key={generateUUID()} value={course.id}>
                                {course.label}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                        <FormHelperText
                          error={
                            errors.selectedTopic && touched.selectedTopic
                              ? true
                              : false
                          }
                        >
                          {errors.selectedTopic && touched.selectedTopic
                            ? errors.selectedTopic
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <FieldArray
                    name="_takeAways"
                    validateOnChange
                  >{(arrayHelpers) => {
                    const ErrorMessage = ({ name }) => (
                      <Field name="_takeAways[0]">
                        {({ field, form, meta }) => {
                          const error = getIn(form.errors, name);
                          const touch = getIn(form.touched, name);
                          return touch && error ? (
                            <Typography
                              style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                paddingLeft: 25,
                                color: "red",
                              }}
                            >
                              {error}
                            </Typography>
                          ) : (
                            ""
                          );
                        }}
                      </Field>
                    );
                    return (
                      <Grid item className={styles.fieldItems} style={{}}>
                        <Paper style={{}}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 8,
                            }}
                          >
                            <Typography
                              variant="h6"
                              display="block"
                              className={styles.secondLabels}
                              gutterBottom
                            >
                              What they can learn about the course
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignSelf: "flex-end",
                                alignItems: "flex-end",
                                alignContent: "flex-end",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.insert(
                                    values._takeAways.length,
                                    ""
                                  )
                                } // insert an empty string at a position
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "green",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>

                          {values._takeAways && values._takeAways.length > 0
                            ? values._takeAways.map((takeAway, index) => (
                              <Grid
                                container
                                direction="row"
                                key={`takeAway-${index}`}
                              >
                                <Grid item style={{ flex: 1 }}>
                                  <TextField
                                    placeholder="They can learn..."
                                    //  error={}
                                    //     helperText="Incorrect entry."
                                    className={styles.secondInputFields}
                                    name={`_takeAways[${index}]`}
                                    //  onChange={(e) => inputCheck(e, "password")}
                                    value={takeAway}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        fontSize: "calc(12px + 1vmin)",
                                        color: "rgba(40,48,120,1)",
                                      },
                                    }}
                                  />
                                  <ErrorMessage name={`_takeAways[${index}]`} />
                                </Grid>

                                <Grid
                                  item
                                  style={{
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    alignSelf: "center",
                                    minHeight: "100%",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }} // remove a friend from the list
                                    style={{
                                      height: "calc(12px + 2vmin)",
                                      width: "calc(12px + 2vmin)",
                                      marginRight: 10,
                                      color: "red",
                                      fontSize: "calc(12px + 1vmin)",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    -
                                  </button>
                                </Grid>
                              </Grid>
                            ))
                            : null}

                          {values._takeAways.length > 0 &&
                            values._takeAways.length < 3 ? (
                            <Typography
                              style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                paddingLeft: 25,
                                color: "red",
                              }}
                            >
                              {`Please add ${3 - values._takeAways.length} more`}
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                paddingLeft: 25,
                                color: "red",
                              }}
                            >
                              {typeof errors._takeAways === "string"
                                ? errors._takeAways
                                : null}
                            </Typography>
                          )}
                        </Paper>
                      </Grid>
                    );
                  }}
                  </FieldArray>
                  {
                    //start of file contents selection
                  }
                  <Grid item className={styles.fieldItems} style={{}}>
                    <Paper style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 8,
                        }}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          Audio Lessons
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignSelf: "flex-end",
                            alignItems: "flex-end",
                            alignContent: "flex-end",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              openAudioSelector()
                            }
                            style={{
                              height: "calc(12px + 2vmin)",
                              width: "calc(12px + 2vmin)",
                              marginRight: 10,
                              color: "green",
                              fontSize: "calc(12px + 1vmin)",
                              fontWeight: "bolder",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {_audios && _audios.length > 0
                        ? _audios.map((audio, index) => (
                          <Grid
                            container
                            direction="row"
                            key={generateUUID()}
                          >
                            <Grid item style={{ flex: 1 }}>
                              <Typography
                                display="block"
                                className={styles.typographyFiles}
                                gutterBottom
                              >
                                {audio.name}
                              </Typography>

                            </Grid>

                            <Grid
                              item
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  setAudios(
                                    _audios.filter((x) => x.name !== audio.name)
                                  )
                                }
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "red",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          </Grid>
                        ))
                        : null}
                      {
                        audioData.errors.length ?

                          errorCheckerPicker(audioData.errors[0])
                          : null
                      }
                    </Paper>
                  </Grid>
                  {
                    //end of file contents selection
                  }
                  {
                    //start of file contents selection
                  }
                  <Grid item className={styles.fieldItems} style={{}}>
                    <Paper style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 8,
                        }}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          Video Lessons
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignSelf: "flex-end",
                            alignItems: "flex-end",
                            alignContent: "flex-end",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              openVidSelector()
                            }
                            style={{
                              height: "calc(12px + 2vmin)",
                              width: "calc(12px + 2vmin)",
                              marginRight: 10,
                              color: "green",
                              fontSize: "calc(12px + 1vmin)",
                              fontWeight: "bolder",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {_videos && _videos.length > 0
                        ? _videos.map((video, index) => (
                          <Grid
                            container
                            direction="row"
                            key={generateUUID()}
                          >
                            <Grid item style={{ flex: 1 }}>
                              <Typography
                                display="block"
                                className={styles.typographyFiles}
                                gutterBottom
                              >
                                {video.name}
                              </Typography>

                            </Grid>

                            <Grid
                              item
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  setVideos(
                                    _videos.filter((x) => x.name !== video.name)
                                  )
                                }
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "red",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          </Grid>
                        ))
                        : null}
                      {
                        vidsData.errors.length ?

                          errorCheckerPicker(vidsData.errors[0])
                          : null
                      }
                    </Paper>
                  </Grid>
                  {
                    //end of file contents selection
                  }
                  {
                    //start of file contents selection
                  }
                  <Grid item className={styles.fieldItems} style={{}}>
                    <Paper style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 8,
                        }}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          Document Lessons
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignSelf: "flex-end",
                            alignItems: "flex-end",
                            alignContent: "flex-end",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              openDocSelector()
                            }
                            style={{
                              height: "calc(12px + 2vmin)",
                              width: "calc(12px + 2vmin)",
                              marginRight: 10,
                              color: "green",
                              fontSize: "calc(12px + 1vmin)",
                              fontWeight: "bolder",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {_documents && _documents.length > 0
                        ? _documents.map((docu, index) => (
                          <Grid
                            container
                            direction="row"
                            key={generateUUID()}
                          >
                            <Grid item style={{ flex: 1 }}>
                              <Typography
                                display="block"
                                className={styles.typographyFiles}
                                gutterBottom
                              >
                                {docu.name}
                              </Typography>

                            </Grid>

                            <Grid
                              item
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  setDocuments(
                                    _documents.filter((x) => x.name !== docu.name)
                                  )
                                }
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "red",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          </Grid>
                        ))
                        : null}
                      {
                        docData.errors.length ?

                          errorCheckerPicker(docData.errors[0])
                          : null
                      }
                    </Paper>
                  </Grid>
                  {
                    //end of file contents selection
                  }
                  {
                    //start of file contents selection
                  }
                  <Grid item className={styles.fieldItems} style={{}}>
                    <Paper style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 8,
                        }}
                      >
                        <Typography
                          variant="h6"
                          display="block"
                          className={styles.secondLabels}
                          gutterBottom
                        >
                          Presentation Lessons
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignSelf: "flex-end",
                            alignItems: "flex-end",
                            alignContent: "flex-end",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              openPresentSelector()
                            }
                            style={{
                              height: "calc(12px + 2vmin)",
                              width: "calc(12px + 2vmin)",
                              marginRight: 10,
                              color: "green",
                              fontSize: "calc(12px + 1vmin)",
                              fontWeight: "bolder",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {_presentations && _presentations.length > 0
                        ? _presentations.map((presentation, index) => (
                          <Grid
                            container
                            direction="row"
                            key={generateUUID()}
                          >
                            <Grid item style={{ flex: 1 }}>
                              <Typography
                                display="block"
                                className={styles.typographyFiles}
                                gutterBottom
                              >
                                {presentation.name}
                              </Typography>

                            </Grid>

                            <Grid
                              item
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                                minHeight: "100%",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  setPresentations(
                                    _presentations.filter((x) => x.name !== presentation.name)
                                  )
                                }
                                style={{
                                  height: "calc(12px + 2vmin)",
                                  width: "calc(12px + 2vmin)",
                                  marginRight: 10,
                                  color: "red",
                                  fontSize: "calc(12px + 1vmin)",
                                  fontWeight: "bolder",
                                }}
                              >
                                -
                              </button>
                            </Grid>
                          </Grid>
                        ))
                        : null}
                      {
                        presentationData.errors.length ?

                          errorCheckerPicker(presentationData.errors[0])
                          : null
                      }
                    </Paper>
                  </Grid>
                  {
                    //end of file contents selection
                  }
                  <Grid
                    container
                    direction="row"
                    style={{
                      paddingTop: 16,
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid item sm={5}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          backgroundColor: "green",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={handleSubmit}
                        className={styles.addUserButtonTwo}
                      >
                        Update Course
                      </Button>
                    </Grid>
                    <Grid item sm={5}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={() => props.close(false)}
                        className={styles.addUserButtonTwo}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          }
        </Paper>
      )}
    </Formik>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "absolute",
      maxWidth: "80%",
      height: "100%",
      left: "15%",
      right: "10%",
      //   margin: "5%",
      overflow: "auto",
    },
    parentGrid: {
      flex: 1,
      padding: 20,

      // maxHeight: "100%",
      // maxWidth: "100%",
    },
    nameCard: {
      //  width: "500",
    },
    headerAddUser: {
      //    backgroundColor: "white",
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 25,
      paddingRight: 25,
    },
    inputName: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    secondInputFields: {
      width: "100%",
      paddingLeft: 25,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    typographyFiles: {
      fontSize: "calc(12px + 1vmin)",
      color: "rgba(40,48,120,1)",
      paddingLeft: 25,
    },
    secondAddUser: {
      marginTop: 20,
      textAlign: "center",
    },
    secondLabels: {
      color: "rgba(116,111,111,1)",
      fontWeight: "bold",
      paddingLeft: 25,
      alignSelf: "center"
    },
    addUserButton: {},
    addUserButtonTwo: {
      width: "40%",
      minHeight: 60,
      margin: 10,
    },

    dropdownStyle: {
      backgroundColor: "none",
      color: "rgba(40,48,120,1)",
      //      fontWeight: 600,
      fontSize: "calc(6px + 2vmin)",
    },
    lessonsContainer: {
      width: "100%",
    },
    fieldItems: {
      marginTop: 20,
      //    backgroundColor: "white",
    },
    gridItems: {
      textAlign: "center",
    },
    dropdownContainer: {
      width: "100%",
      paddingLeft: 25,
      paddingRight: 25,
      // [theme.breakpoints.down('sm')]: {
      //     width: '100%'
      // }
    },
    avatar: {
      margin: "15px",
      width: "100px",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        margin: "15px",
        width: "150px",
        height: "150px",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {},
    },
  })
);
export default LessonForm;
